import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {withTranslation} from "react-i18next";
import classnames from "classnames";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Addresses from "./Addresses/Addresses";
import MyPayments from "./MyPayments/MyPayments";
import {store} from "../../../Security";
import "../../../index.css";

class MyAccount extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      plainTabs: 1,
    };
    this.toggleNavs = this.toggleNavs.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  render() {

    const {t} = this.props;
    let userCredentials = store.get('userCredentials');
    let user = userCredentials.user;

    return (
      <Row className="justify-content-center">
        <Col xs="11">

          <div className="nav-wrapper">
            <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                  onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('personal_information')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                  onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('my_addresses')}</span>
                </NavLink>
              </NavItem>
              {
                user != null && user.role.toLowerCase() === "user" &&
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 3}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 3})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 3 ? "text-white font-weight-500" : "font-weight-500"}>{t('my_payments')}</span>
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </div>

          <Card className="shadow-sm mb-4">
            <CardBody>
              <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                <TabPane tabId="plainTabs1">
                  <PersonalInformation />
                </TabPane>
                <TabPane tabId="plainTabs2">
                  <Addresses />
                </TabPane>
                {
                  user != null && user.role.toLowerCase() === "user" &&
                  <TabPane tabId="plainTabs3">
                    <MyPayments />
                  </TabPane>
                }
              </TabContent>
            </CardBody>
          </Card>

        </Col>
      </Row>
    );

  }

}

export default withTranslation()(withRouter(MyAccount));
