import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Row, Col, Card, CardBody, Nav, NavItem, NavLink, Badge, TabContent, TabPane, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {loaderColor} from "../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from "axios";
import classnames from "classnames";
import CampaignPresentation from "./CampaignPresentation";
import CampaignActions2 from "./CampaignActions2";
import "../../../index.css";

class CampaignInfos extends Component{

  constructor(props) {
    super(props);

    this.state = {
      id:this.props.match.params.id,
      accordion: [false, false, false],
      loading:false,
      campaign:[],
      plainTabs: 1,
      recapData:[],
      source:axios.CancelToken.source()
    };

    this.toggleNavs = this.toggleNavs.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.loading = this.loading.bind(this);
    this.getAccordion = this.getAccordion.bind(this);
    this.getCampaign = this.getCampaign.bind(this);
    this.getCampaign2 = this.getCampaign2.bind(this);
    this.getRecapData = this.getRecapData.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  toggleAccordion(tab) {
    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      accordion: state,
    });
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  getAccordion(campaign){
    let accordion =  [false, false, false];
    switch (campaign.step) {
      case 0:
        accordion[0] = true;
        break;
      case 1:
        accordion[1] = true;
        break;
      case 2:
        accordion[1] = true;
        break;
      case 3:
        accordion[2] = true;
        break;
      case 4:
        accordion[2] = true;
        break;
      default:
        break;
    }
    return accordion;
  }

  getRecapData(campaign){
    let files = campaign.files;
    let recapData = [];
    if(files != null){
      for(let file of files){
        let buffer = file.recap_data;
        let element = null;
        try{
          let json = JSON.parse(new Buffer.from(buffer).toString());
          let data = [];
          let fifo_stock_valuation = 0;
          let cump_stock_valuation = 0;
          let fifo_capital_gain = 0;
          let cump_capital_gain = 0;
          for(let el in json) data.push([el,json[el]]);
          for(let el of data){
            if(el[1].fifo_svdr != null) fifo_stock_valuation = fifo_stock_valuation + el[1].fifo_svdr;
            if(el[1].cump_svdr != null) cump_stock_valuation = cump_stock_valuation + el[1].cump_svdr;
            if(el[1].fifo_spv != null) fifo_capital_gain = fifo_capital_gain + el[1].fifo_spv;
            if(el[1].cump_spv != null) cump_capital_gain = cump_capital_gain + el[1].cump_spv;
          }
          element = {
            ledger: file.ledger,
            data:data,
            fifo_stock_valuation:fifo_stock_valuation,
            cump_stock_valuation:cump_stock_valuation,
            fifo_capital_gain:fifo_capital_gain,
            cump_capital_gain:cump_capital_gain
          }
        }catch(e){}
        recapData.push(element);
      }
    }
    return recapData;
  }

  async getCampaign(){

    let cancelToken = this.state.source.token;
    this.loading();

    let response = await RemoteCall(
      true,
      "campaigns/"+this.state.id,
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        let campaign = response.campaign;
        let recapData = this.getRecapData(campaign);
        this.setState({
          campaign:campaign,
          accordion:this.getAccordion(campaign),
          recapData:recapData
        })
      }
    }

  }

  getCampaign2(campaign){
    this.setState({
      campaign:campaign,
      accordion:this.getAccordion(campaign),
      recapData:this.getRecapData(campaign)
    })
  }

  componentDidMount() {
    this.getCampaign();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let campaign = this.state.campaign;

    return (
      <>

        <Row className="justify-content-center">
          <Col xs="11">

            <div className="position-relative" style={{top:'10px'}}>
              <Link to="/accounting">
                <Button className="btn-outline-dark" size="sm">
                  <i className="fa fa-caret-left mr-1 fa-lg"/> {t('back')}
                </Button>
              </Link>
            </div>

            {/* Titre de la page */}

            <div className="text-center" style={{whiteSpace:"normal"}}>
              <h5>
                <Badge color="kamixColorGreen">{t('manage_campaign')}</Badge>
              </h5>
            </div>

            <div className="nav-wrapper small">
              <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('information')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('actions')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <Card className="shadow-sm mb-4">
              <CardBody>
                {
                  this.state.loading ? (
                    <Row className="justify-content-center my-5">
                      <PuffLoader
                        sizeUnit={"px"}
                        size={50}
                        color={loaderColor}
                        loading={this.state.loading }
                      />
                    </Row>
                  ) : (
                    <TabContent activeTab={"plainTabs" + this.state.plainTabs}>

                      <TabPane tabId="plainTabs1">
                        <CampaignPresentation data={campaign} noListGroup={true} />
                      </TabPane>

                      <TabPane tabId="plainTabs2">
                        <CampaignActions2
                          campaign={campaign} getCampaign={this.getCampaign2} plainTabs={this.state.plainTabs}
                          accordion={this.state.accordion} toggleAccordion={this.toggleAccordion} recapData={this.state.recapData}
                        />
                      </TabPane>

                    </TabContent>
                  )
                }
              </CardBody>
            </Card>

          </Col>
        </Row>

      </>
    );

  }

}

export default withTranslation()(withRouter(CampaignInfos));
