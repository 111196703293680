import React, {Suspense} from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import {Row, Col} from "reactstrap";
import {PropagateLoader} from "react-spinners";
import {loaderColor} from "./MyFunctions";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss"
import "./assets/vendor/font-awesome/css/font-awesome.min.css";

const loading = () =>
  <Row>
    <Col className="d-flex justify-content-center align-items-center mt-5">
      <PropagateLoader
        sizeUnit={"px"}
        size={15}
        color={loaderColor}
      />
    </Col>
  </Row>;

ReactDOM.render(
  <Suspense fallback={loading()}>
    <App />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
