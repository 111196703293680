import React, { Component } from 'react';
import {Col, Row, Container, Button, ListGroup, ListGroupItem, Collapse} from "reactstrap";
import {withRouter, Link} from "react-router-dom";
import RemoteCall, {getDateToDisplay, loaderColor} from "../../MyFunctions";
import axios from 'axios';
import {PuffLoader} from "react-spinners";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {withTranslation} from "react-i18next";
import Graph from "../Gadgets/MyGraph";
import "../../index.css";

class Transaction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      txId:this.props.match.params.txId,
      toggle:false,
      toggleCollapse:false,
      loadingTransactionInfos:false,
      loadingTransactionDetails:false,
      transactionInfos:null,
      transactionDetails:null,
      data:null,
      crypto: this.props.location.pathname.substring(1,4),
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.loadingTransactionInfos = this.loadingTransactionInfos.bind(this);
    this.loadingTransactionDetails = this.loadingTransactionDetails.bind(this);
    this.getTransactionInfos = this.getTransactionInfos.bind(this);
    this.getTransactionDetails = this.getTransactionDetails.bind(this);
  }

  toggle(){
    this.setState((state)=>({
      toggle:!state.toggle
    }))
  }

  toggleCollapse(){
    this.setState((state)=>({
      toggleCollapse:!state.toggleCollapse
    }))
  }

  loadingTransactionInfos(){
    this.setState((state)=>({
      loadingTransactionInfos:!state.loadingTransactionInfos
    }))
  }

  loadingTransactionDetails(){
    this.setState((state)=>({
      loadingTransactionDetails:!state.loadingTransactionDetails
    }))
  }

  async getTransactionInfos(){

    let txId = this.state.txId;
    let cancelToken = this.state.source.token;

    this.loadingTransactionInfos();

    let response = await RemoteCall(
      false,
      "getrawtransaction/"+txId,
      "get",
      null,
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingTransactionInfos();

    if(response != null){
      this.setState({
        transactionInfos:response
      })
    }

  }

  async getTransactionDetails(){

    let txId = this.state.txId;
    let cancelToken = this.state.source.token;

    this.loadingTransactionDetails();

    let response = await RemoteCall(
      false,
      "transaction",
      "get",
      {
        search:txId
      },
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingTransactionDetails();

    if(response != null){

      let nodes = [];
      let links = [];

      nodes.push(
        {
          id: "root",
          name: txId.substring(0,4) +"..."+  txId.substring(txId.length-4,txId.length),
          color: "black",
          val:"3"
        }
      );

      for(let element of response.ins){
        nodes.push({
          id: element._id,
          address:element.address,
          coinbase:element.coinbase,
          name: element.coinbase ? "coinbase" : element.address.substring(0,3) +"..."+  element.address.substring(element.address.length-3,element.address.length),
          color: "#5e923d"
        });
        links.push({
          source: "root",
          target: element._id,
          display:element.value.toFixed(8) + " BTC",
          color:"#1d1d1d"
        });
      }

      for(let element of response.outs){
        nodes.push({
          id: element._id,
          address:element.address,
          coinbase:element.coinbase,
          name: element.coinbase ? "coinbase" : element.address.substring(0,3) +"..."+  element.address.substring(element.address.length-3,element.address.length),
          color: "#5e72e4"
        });
        links.push({
          source: element._id,
          target: "root",
          display:element.value.toFixed(8) + " BTC",
          color:"#1d1d1d"
        });
      }

      this.setState({
        transactionDetails:response,
        data:{
          nodes:nodes,
          links:links
        }
      })

    }

  }

  componentDidMount() {
    this.getTransactionInfos();
    this.getTransactionDetails();
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let txId = this.state.txId;
    if(txId !== nextProps.id && nextProps.id !== "" && nextProps.id != null){
      await this.setState({
        txId:nextProps.id,
        toggle:false,
        toggleCollapse:false,
        loadingTransactionInfos:false,
        loadingTransactionDetails:false,
        transactionInfos:null,
        transactionDetails:null,
        data:null,
        crypto: this.props.location.pathname.substring(1,4),
      });
      this.getTransactionInfos();
      this.getTransactionDetails();
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;
    let transactionInfos = this.state.transactionInfos;
    let transactionDetails = this.state.transactionDetails;

    return(
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" sm="11">
            {
              this.state.loadingTransactionInfos ? (
                <div className="d-flex justify-content-center mt-3">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loadingTransactionInfos}
                  />
                </div>
              ) : (
                <div>
                  {
                    transactionInfos != null ? (
                      <> 
                        <div className="display-4 mb-4">
                          <h2 className="mb-0">{t('transaction')}</h2>
                          <div className="d-flex align-items-center">
                            <div className="smallText2 mr-2">{transactionInfos.txid}</div>
                            <CopyToClipboard text={transactionInfos.txid} onCopy={() => {/*notification("info",t('tx_id_copied'))*/}}>
                              <Button color="primary" size="sm" title={t('click_to_copy')}><i className="fa fa-copy"/></Button>
                            </CopyToClipboard>
                          </div>
                        </div>

                        {
                          this.state.data != null &&
                          <Graph data={this.state.data} />
                        }

                        <div className="mb-2">
                          <ListGroup flush>
                            {
                              transactionInfos.blocktime != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('block_time')}</Col>
                                  <Col xs="8" className="text-right">{getDateToDisplay(transactionInfos.blocktime).fullDate}</Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionInfos.blockhash != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('include_in_block')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>
                                      {transactionInfos.blockhash.substring(0, 1)}...{transactionInfos.blockhash.substring(transactionInfos.blockhash.length - 15, transactionInfos.blockhash.length)}
                                    </div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionInfos.blockhash}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionDetails != null && transactionDetails.height != null &&
                              <ListGroupItem className="p-2 border-bottom">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('block_height')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>{transactionDetails.height.height}</div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionDetails.height.height}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }

                            {
                              transactionInfos.confirmations != null &&
                              <ListGroupItem className="p-2 border-bottom">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('status')}</Col>
                                  <Col xs="8" className="text-right">{transactionInfos.confirmations} {t('confirmations')}</Col>
                                </Row>
                              </ListGroupItem>
                            }

                            <Collapse isOpen={this.state.toggleCollapse}>
                              {
                                transactionInfos.size != null &&
                                <ListGroupItem className="border-top p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('size')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.size} B</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.vsize != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('v_size')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.vsize} vB</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.weight != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('weight_units')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.weight} WU</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.version != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('version')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.version}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.locktime != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('lock_time')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.locktime}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                            </Collapse>

                            <div className="d-flex justify-content-end my-3">
                              <Button color="dark" size="sm" onClick={this.toggleCollapse}>
                                {t(this.state.toggleCollapse ? 'see_less' : 'see_more')}
                              </Button>
                            </div>

                            {
                              transactionDetails != null &&
                              <ListGroupItem>
                                <Row className="justify-content-center mt-3 mb-4 feesTxPresentation">
                                  <Col xs="12" lg="5">
                                    {
                                      transactionDetails.fee != null &&
                                      <Row className="fee">
                                        <Col xs="5" className="font-weight-bold">
                                          <div>{t('fees')}</div>
                                        </Col>
                                        <Col xs="7" className="text-right">
                                          {transactionDetails.fee.toFixed(8)} <span className="small">{t('btc')}</span>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                  <div className="text-primary mx-3 d-none d-lg-block invisible"><i className="fa fa-chevron-right fa-2x"/></div>
                                  <Col xs="12" lg="5">
                                    {
                                      transactionDetails.received != null &&
                                      <Row className="received">
                                        <Col xs="5" className="font-weight-bold">
                                          <div>{t('received')}</div>
                                        </Col>
                                        <Col xs="7" className="text-right">
                                          {transactionDetails.received.toFixed(8)} <span className="small">{t('btc')}</span>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                </Row>

                                <Row className="justify-content-end my-3">
                                  {/*<Button color="primary" size="sm" onClick={this.toggle}>{t('details')}</Button>*/}
                                </Row>

                                <Row className="justify-content-center">
                                  <Col xs="12" lg="5">
                                    {
                                      transactionDetails.outs != null && transactionDetails.outs.map((element,index)=>(
                                        <div key={index} className="mb-2">
                                          <Row className="txDetailEltPresentation">
                                            <Col xs="7" className="font-weight-bold text-primary button-copy">
                                              {
                                                element.coinbase ? (
                                                  <>
                                                    <div className="coinbase-label">{t('coinbase')}</div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Link to={"/"+this.state.crypto+"/address/"+element.address}>
                                                      <div className="link">{element.address.substring(0,6)}...{element.address.substring(element.address.length -6, element.address.length)}</div>
                                                    </Link>
                                                    <CopyToClipboard text={element.address} onCopy={() => {/*notification("info",t('add_id_copied'))*/}}>
                                                      <i className="fa fa-copy" title={t('click_to_copy')}/>
                                                    </CopyToClipboard>
                                                  </>
                                                )
                                              }
                                            </Col>
                                            <Col xs="5" className="text-right">{element.value} <span className="small">{t('btc')}</span></Col>
                                          </Row>
                                          <Collapse isOpen={this.state.toggle}>
                                            <Row className="myCollapse">
                                              <Col>
                                                {
                                                  element.coinbase &&
                                                  <Row className="mb-1">
                                                    <Col xs="4">{t('script')}</Col>
                                                    <Col xs="8">{element.script}</Col>
                                                  </Row>
                                                }
                                                <Row>
                                                  <Col xs="4">{t('blockhash')}</Col>
                                                  <Col xs="8">{element.blockhash}</Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </Collapse>
                                        </div>
                                      ))
                                    }
                                  </Col>
                                  <div className="text-primary mx-3 d-none d-lg-block align-self-center"><i className="fa fa-chevron-right fa-2x"/></div>
                                  <div className="text-primary my-2 d-lg-none"><i className="fa fa-chevron-down fa-2x"/></div>
                                  <Col xs="12" lg="5">
                                    {
                                      transactionDetails.ins != null && transactionDetails.ins.map((element,index)=>(
                                        <div key={index} className="mb-2">
                                          <Row className="txDetailEltPresentation">
                                            <Col xs="7" className="font-weight-bold text-primary button-copy">
                                              <Link to={"/"+this.state.crypto+"/address/"+element.address}>
                                                <div className="link">{element.address.substring(0,6)}...{element.address.substring(element.address.length -6, element.address.length)}</div>
                                              </Link>
                                              <CopyToClipboard text={element.address} onCopy={() => {/*notification("info",t('add_id_copied'))*/}}>
                                                <i className="fa fa-copy " title={t('click_to_copy')}/>
                                              </CopyToClipboard>
                                            </Col>
                                            <Col xs="5" className="text-right">{element.value} <span className="small">{t('btc')}</span></Col>
                                          </Row>
                                          <Collapse isOpen={this.state.toggle}>
                                            <Row className="myCollapse">
                                              <Col>
                                                <Row>
                                                  <Col xs="4">{t('blockhash')}</Col>
                                                  <Col xs="8">{element.blockhash}</Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </Collapse>
                                        </div>
                                      ))
                                    }
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }
                          </ListGroup>
                        </div>
                      </>
                    ) : (
                      <h6 className="text-center display-4 mt-4">
                        {t('no_result_found')}
                      </h6>
                    )
                  }
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    );

  }

}

export default withTranslation()(withRouter(Transaction));