import React, { Component } from 'react';
import {Row, Col, ListGroupItem, FormText, Badge} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getAmountToDisplay, getDateToDisplay, getName} from "../../../../MyFunctions";
import "../../../../index.css";

class PaymentPresentation extends Component {

  render() {

    const {t} = this.props;
    let data = this.props.data;

    return (
      <div>
        <Row className="justify-content-center my-3">
          <Col xs="11">

            <Row className="justify-content-center">

              <Col xs="12" lg="6" className="mb-3 mb-lg-0">
                <ListGroupItem>
                  {
                    data.user != null && this.props.user == null &&
                    <>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('user_id')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.user.user_id}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('username')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.user.user_name}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('complete_name')}</small>
                        <FormText color="default" className="overflowWrap">
                          {getName(data.user)}
                        </FormText>
                      </div>
                    </>
                  }
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('formulae')}</small>
                    <h5>
                      <Badge color="primary">
                        {data.formulae}
                      </Badge>
                    </h5>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('amount')}</small>
                    <h5>
                      <Badge color="primary">
                        {getAmountToDisplay(data.amount)} <small className="ml-1">EUR</small>
                      </Badge>
                    </h5>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('amount')}</small>
                    <FormText color="default" className={"overflowWrap text-"+data.status.toLowerCase()}>
                      {t(data.status)}
                    </FormText>
                  </div>
                </ListGroupItem>
              </Col>

              <Col xs="12" lg="6">
                <ListGroupItem>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('initialization_date')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.initialization_date != null ? getDateToDisplay(data.initialization_date).fullDate : "---"}
                    </FormText>
                  </div>
                  {
                    data.completion_date != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('completion_date')}</small>
                      <FormText color="default" className="overflowWrap">
                        {getDateToDisplay(data.completion_date).fullDate}
                      </FormText>
                    </div>
                  }
                  {
                    data.stripe_product_id && this.props.user == null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('stripe_product_id')}</small>
                      <FormText color="default" className="overflowWrap">
                        {data.stripe_product_id}
                      </FormText>
                    </div>
                  }
                  {
                    data.stripe_session_id && this.props.user == null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('stripe_product_id')}</small>
                      <FormText color="default" className="overflowWrap">
                        {data.stripe_session_id}
                      </FormText>
                    </div>
                  }
                  {
                    data.message != null && data.message !== "" && this.props.user == null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('message')}</small>
                      <FormText color="warning" className="overflowWrap font-italic">
                        {data.message}
                      </FormText>
                    </div>
                  }
                </ListGroupItem>
              </Col>

            </Row>

          </Col>
        </Row>
      </div>
    )

  }

}

export default withTranslation()(PaymentPresentation);
