import React, { Component } from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import { Container,} from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

class register extends Component{
  render(){
    return(
      <div className="app">
        <Container fluid>
          <Switch>
            <Route exact path="/register/step1" name="Step one" render={props => <Step1 {...props}/>} />
            <Route exact path="/register/step2" name="Step two" render={props => <Step2 {...props}/>} />
            <Route exact path="/register/step3" name="Step three" render={props => <Step3 {...props}/>} />
            <Route exact path="/register/step4" name="Step four" render={props => <Step4 {...props}/>} />
            <Redirect from="/register" to="/register/step1" />
          </Switch>
        </Container>
      </div>
    );
  }
}

export default withRouter(register);