import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {withTranslation} from "react-i18next";
import classnames from "classnames";
import Users from "./Users/Users";
import Payments from "./UsersPayments/Payments";
import "../../../index.css";

class UsersManagement extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      plainTabs: 1,
    };
    this.toggleNavs = this.toggleNavs.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  render() {

    const {t} = this.props;

    return (
      <Row className="justify-content-center">
        <Col xs="11">

          <div className="nav-wrapper">
            <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                  onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('users')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                  onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('payments')}</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <Card className="shadow-sm mb-4">
            <CardBody>
              <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                <TabPane tabId="plainTabs1">
                  <Users />
                </TabPane>
                <TabPane tabId="plainTabs2">
                  <Payments />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

        </Col>
      </Row>
    );

  }

}

export default withTranslation()(withRouter(UsersManagement));
