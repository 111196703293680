import React, { Component } from 'react';
import {Col, Row, NavItem, NavLink, Nav, TabContent, TabPane} from "reactstrap";
import {withRouter} from "react-router-dom";
import axios from 'axios';
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import BitcoinInformation from "./BitcoinInformation";
import EthereumInformation from "./EthereumInformation";
import "../../index.css";

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      plainTabs: 1,
      source:axios.CancelToken.source()
    };

    this.toggleNavs = this.toggleNavs.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  render(){

    const {t} = this.props;

    return(
      <>
        <Row className="justify-content-center mt-2 mb-4">
          <Col xs="11" className="px-0">

            <div className="nav-wrapper">
              <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('bitcoin')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('ethereum')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
              <TabPane tabId="plainTabs1">
                <BitcoinInformation />
              </TabPane>
              <TabPane tabId="plainTabs2">
                <EthereumInformation />
              </TabPane>
            </TabContent>

          </Col>
        </Row>
      </>
    );

  }

}

export default withTranslation()(withRouter(Dashboard));