import React, { Component } from 'react';
import {Row, Container, Col, Card, CardBody, FormText, Button, Progress} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
import ReactCodeInput from 'react-code-input';
import RemoteCall, {loaderColor, notification} from "../../../MyFunctions";
import {withTranslation} from "react-i18next";
import {ScaleLoader} from "react-spinners";
import {store} from "../../../Security";
import "../../../index.css";

class Step2 extends Component{

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      validationNumber:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.onChangeValidationNumber = this.onChangeValidationNumber.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
    this.skip = this.skip.bind(this);
    this.stepTwo = this.stepTwo.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  onChangeValidationNumber(e){
    this.setState({
      validationNumber:e
    })
  }

  async resendMessage(){

    const {t} = this.props;
    let registerInformation = store.get('registerInformation');
    let user = registerInformation.user;

    let formData = {};
    formData.user_id = user.user_id;
    formData.otp_type = registerInformation.otp_type;

    let cancelToken = this.state.source.token;

    let response = await RemoteCall(
      false,
      "users/resend-otp",
      "get",
      formData,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        //-------------------------------------------------------------------------------------------
        let data={};
        data.user = response.user;
        data.otp = response.otp;
        data.otp_type = response.otp_type;
        data.otp_reference = response.otp_reference;
        data.skip_email_verified = false;
        await store.remove('registerInformation');
        await store.set('registerInformation',data);
        //-------------------------------------------------------------------------------------------
        this.setState({validationNumber:""});
        notification('info',t('otp_send'));
        //-------------------------------------------------------------------------------------------
        if((response.otp_type != null && response.otp_type.toLowerCase()=== "email") && (response.otp != null && response.otp)){
          this.props.history.push('/register/step2');
        }else{
          this.props.history.push('/register/step3');
        }
        //-------------------------------------------------------------------------------------------
      }
    }

  }

  async skip(){
    //-------------------------------------------------------------------------------------------
    let data = store.get('registerInformation');
    data.skip_email_verified = true;
    data.step2 = "ok";
    //-------------------------------------------------------------------------------------------
    await store.remove('registerInformation');
    await store.set('registerInformation',data);
    //-------------------------------------------------------------------------------------------
    this.props.history.push('/register/step3');
    //-------------------------------------------------------------------------------------------
  }

  async stepTwo(){

    const { t } = this.props;
    let validationNumber = this.state.validationNumber;
    let registerInformation = store.get('registerInformation');
    let user = registerInformation.user;
    let cancelToken = this.state.source.token;

    if(validationNumber === ""){
      notification("warning",t('fill_fields_form_label'));
    }else{

      let formData = {};
      formData.user_id = user.user_id;
      formData.otp_code = validationNumber;
      formData.otp_type = registerInformation.otp_type;
      formData.otp_reference = registerInformation.otp_reference;

      this.loading();

      let response = await RemoteCall(
        false,
        "users/register/step2",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          //-------------------------------------------------------------------------------------------
          let data={};
          data.user = response.user;
          data.otp = response.otp;
          data.otp_type = response.otp_type;
          data.otp_reference = response.otp_reference;
          data.step2 = "ok";
          await store.remove('registerInformation');
          await store.set('registerInformation',data);
          //-------------------------------------------------------------------------------------------
          this.props.history.push('/register/step3');
          //-------------------------------------------------------------------------------------------
        }
      }

    }

  }

  componentDidMount() {
    let registerInformation = store.get('registerInformation');
    if(registerInformation == null || registerInformation === "") this.props.history.push('/login');
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const { t } = this.props;

    return(
      <>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="7">

              <Card className="cardLogin">
                <CardBody>

                  <Row className="justify-content-center mb-1">
                    <Col xs="10" md="8">
                      <h5 className="font-weight-500 text-center mb-1">{t('step_label')} 2 / 4</h5>
                      <Progress animated color="kamixColorGreen" value="50" />
                    </Col>
                  </Row>

                  <Row className="justify-content-center mb-2">
                    <Col xs="11" md="10">

                      <h6 className="text-center font-weight-700 mb-3">
                        {t('email_verification')}
                      </h6>

                      <div>
                        <FormText className="text-center mb-1">{t('empty_verification_code')}</FormText>
                        <div className="d-flex justify-content-center">
                          <ReactCodeInput
                            name="reactCodeInput"
                            inputMode="numeric"
                            type='text'
                            fields={6}
                            value={this.state.validationNumber}
                            onChange={this.onChangeValidationNumber}
                           />
                        </div>
                      </div>
                      <div className="text-center my-3">
                        <Button size="sm" className="btn-outline-default" onClick={this.resendMessage}>
                          <small>{t('login_resend_otp')}</small>
                        </Button>
                      </div>

                      <div className="d-flex justify-content-center" style={{marginTop:"30px"}}>
                        {
                          this.state.loading ? (
                            <ScaleLoader
                              sizeUnit={"px"}
                              height={25}
                              width={4}
                              radius={2}
                              margin={2}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          ) : (
                            <div className="d-flex flex-column">
                              <div className="d-flex mb-3">
                                <Link to={`/register/step1`}>
                                  <Button size="sm" color="dark" className="btn-outline-dark mr-2">{t('cancel')}</Button>
                                </Link>
                                <Button color="kamixColorGreen" size="sm" onClick={this.stepTwo}>{t('check')}</Button>
                              </div>
                              <Button size="sm" className="btn-outline-default" onClick={this.skip}>{t('skip_this_step')}</Button>
                            </div>
                          )
                        }
                      </div>

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </>
    );

  }

}

export default withTranslation()(withRouter(Step2));