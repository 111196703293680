import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Button, Card, CardBody, FormText, Modal,} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, loaderColor, notification} from "../../../../MyFunctions";
import {PuffLoader, ScaleLoader} from "react-spinners";
import axios from "axios";
import DataTable from "react-data-table-component";
import PaymentPresentation from "../../ManageUsers/UsersPayments/PaymentPresentation";
import Select from "react-select";
import ManagePage from "../../../Gadgets/ManagePage";
import "../../../../index.css";

class MyPayments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingNavigation:false,
      loadingPayment:false,
      payments:[],
      totalResult:"",
      page:1,
      toggle:false,
      formulae:null,
      step:1,
      url:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingNavigation = this.loadingNavigation.bind(this);
    this.loadingPayment = this.loadingPayment.bind(this);
    this.getPage = this.getPage.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChangeFormulae = this.onChangeFormulae.bind(this);
    this.getPayments = this.getPayments.bind(this);
    this.refresh = this.refresh.bind(this);
    this.initializePayment = this.initializePayment.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading,
      loadingNavigation:false
    }))
  }

  loadingNavigation(){
    this.setState((state)=>({
      loadingNavigation:!state.loadingNavigation
    }))
  }

  loadingPayment(){
    this.setState((state)=>({
      loadingPayment:!state.loadingPayment
    }))
  }

  getPage(page){
    this.setState({
      page:page
    })
  }

  toggle(){
    let toggle = this.state.toggle;
    if(toggle){
      this.setState({toggle:!toggle});
    }else{
      this.setState({
        toggle:!toggle,
        formulae:null,
        url:"",
        step:1
      })
    }
  }

  onChangeFormulae(formulae){
    this.setState({
      formulae:formulae
    })
  }

  async getPayments(page){

    let cancelToken = this.state.source.token;
    this.loadingNavigation();

    let response = await RemoteCall(
      true,
      "users/payments",
      "get",
      {page:page},
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingNavigation();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          payments:response.payments,
          totalResult:response.count,
          page:page
        })
      }
    }

  }

  async refresh(){
    await this.setState({page:1});
    await this.getPayments(1);
  }

  async initializePayment(){

    const {t} = this.props;
    let formulae = this.state.formulae;
    let cancelToken = this.state.source.token;

    if(formulae == null){
      notification('warning',t('choose_a_formulae'));
    }else{

      this.loadingPayment();

      let response = await RemoteCall(
        true,
        "payments/checkout",
        "post",
        null,
        {formulae:formulae.value},
        cancelToken,
        this.props,
        'acc'
      );

      this.loadingPayment();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          this.setState({
            url:response.url,
            step:2
          })
        }
      }

    }

  }

  async componentDidMount() {
    this.loading();
    await this.getPayments(1);
    this.loading();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let payments = this.state.payments;

    let formulaeOptions = [
      { value: 1, label: t('formulae_1') },
      { value: 2, label: t('formulae_2') },
      { value: 3, label: t('formulae_3') },
    ];

    const columns = [
      {
        name: t('payment_id'),
        selector: 'payment_id',
        fontWeight: "bold"
      },
      {
        name: t('amount'),
        selector: 'amount',
        cell: row => <div className="font-weight-600">{getAmountToDisplay(row.amount)} <small className="ml-1">EUR</small></div>,
        maxWidth:"100px",
        hide:"lg"
      },
      {
        name: t('status'),
        selector: 'status',
        cell: row => <div className={"text-"+row.status.toLowerCase()}>{t(row.status)}</div>,
        maxWidth:"100px"
      },
      {
        name: t('initialization_date'),
        selector: 'initialization_date',
        cell: row => <div>{row.initialization_date != null ? getDateToDisplay(row.initialization_date).fullDate : "---"}</div>,
        width:"145px",
        hide:"md"
      }
    ];

    return (
      <>

        <div className={this.state.loading ? 'delete2' : 'd-flex justify-content-center'}>
          <div className={this.state.loadingNavigation ? 'myLoader' : 'delete2'}>
            Loading ...
          </div>
        </div>

        <Row>
          <Col>
            {
              this.state.loading ? (
                <Row className="justify-content-center my-5">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loading }
                  />
                </Row>
              ) : (
                <>
                  {
                    payments != null &&
                    <>
                      {
                        payments.length === 0 ? (
                          <div className="d-flex flex-column justify-content-center align-items-center my-5">
                            {/*<h6 className="text-dark font-weight-500">{t('no_payment_to_date')}</h6>*/}
                            <Button color="kamixColorGreen" size="sm" onClick={this.toggle}>
                              {t('make_a_payment')}
                            </Button>
                          </div>
                        ) : (
                          <Row className="justify-content-center">
                            <Col xs="11">

                              <div className="d-flex justify-content-end mb-3">
                                <Button color="primary" size="sm" onClick={this.refresh} className="mr-2">
                                  <i className="fa fa-refresh fa-lg" />
                                </Button>
                                <Button color="kamixColorGreen" size="sm" onClick={this.toggle}>
                                  {t('make_a_payment')}
                                </Button>
                              </div>

                              <ManagePage
                                getDatas={this.getPayments}
                                total={this.state.totalResult}
                                page={this.state.page}
                                getPage={this.getPage}
                                size={50}
                              />

                              <DataTable
                                className="my-3"
                                noHeader={true}
                                columns={columns}
                                data={payments}
                                pagination
                                striped
                                responsive
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={<PaymentPresentation user={true} />}
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50]}
                              />

                            </Col>
                          </Row>
                        )
                      }
                    </>
                  }
                </>
              )
            }
          </Col>
        </Row>

        <Modal className="modal-dialog" isOpen={this.state.toggle} toggle={this.toggle}>
          <Card className="bg-secondary">
            <CardBody>

              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('make_a_payment')}</h6>
              </div>

              {
                this.state.step === 1 &&
                <>

                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText className="text-default mb-1">{t('choose_a_formulae')}</FormText>
                      <Select
                        options={formulaeOptions}
                        value={this.state.formulae}
                        onChange={this.onChangeFormulae}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-center mt-4">
                    {
                      this.state.loadingPayment ? (
                        <ScaleLoader
                          sizeUnit={"px"}
                          height={20}
                          width={4}
                          radius={2}
                          margin={2}
                          color={loaderColor}
                          loading={this.state.loadingPayment}
                        />
                      ) : (
                        <>
                          <Button className="btn-outline-dark mr-2" size="sm" onClick={this.toggle}>{t('cancel')}</Button>
                          <Button color="kamixColorGreen" size="sm"  onClick={this.initializePayment}>{t('continue')}</Button>
                        </>
                      )
                    }
                  </div>

                </>
              }

              {
                this.state.step === 2 &&
                <Row className="justify-content-center">
                  <Col xs="12" md="10">

                    <div className="d-flex flex-column align-items-center smallText text-center mb-3">
                      <div className="mb-1">-- {t('end_of_operation_text')} --</div>
                      <a href={this.state.url} className="urlPresentation" target="_blank" rel="noopener noreferrer">
                        <Button color="primary" size="sm">
                          {t('click_here')}
                        </Button>
                      </a>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button size="sm" color="dark" className="btn-outline-dark mr-2" onClick={this.toggle}><i className="fa fa-close" /></Button>
                    </div>

                  </Col>
                </Row>
              }

            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(MyPayments));
