import React, { Component } from 'react';
import {Row, Col, ListGroupItem, FormText} from "reactstrap";
import {withTranslation} from "react-i18next";
import "../../../../index.css";

class AddPresentation2 extends Component {

  render() {
    const {t} = this.props;
    let data = this.props.data;
    return (
      <div>
        <Row className="justify-content-center my-3">
          <Col xs="11">
            <Row className="justify-content-center">
              <Col xs="11">
                <ListGroupItem>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('name')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.address}
                    </FormText>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('type')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.blockchain}
                    </FormText>
                  </div>
                </ListGroupItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(AddPresentation2);
