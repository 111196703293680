import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
  Row, Col, Button, Modal, InputGroup, InputGroupAddon,
  InputGroupText, Input, FormText, Card, CardBody, Badge
} from "reactstrap";
import {ScaleLoader} from "react-spinners";
import RemoteCall, {loaderColor, notification} from "../../../../MyFunctions";
import axios from "axios";
import countryList from 'react-select-country-list';
import Select from 'react-select';
import FlagIcon from "../../../../FlagIcon";
import PhoneInput from "react-phone-input-2";
import EmailValidator from "email-validator";
import {store} from "../../../../Security";
import ReactCodeInput from "react-code-input";
import {formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import "../../../../index.css";

class AccountElement extends Component{

  constructor(props) {
    super(props);
    this.options = countryList().getData();

    this.state={
      options: this.options,
      loading:false,
      loadingVerification:false,
      toggle:false,
      value:"",
      country:null,
      phoneNumber:"",
      password:"",

      otp:false,
      otp_type:"",
      otp_reference:"",
      step:1,

      loadingValidationNumber:false,
      validationNumber:"",

      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.loading = this.loading.bind(this);
    this.loadingVerification = this.loadingVerification.bind(this);
    this.loadingValidationNumber = this.loadingValidationNumber.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeValidationNumber = this.onChangeValidationNumber.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
    this.save = this.save.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.verify = this.verify.bind(this);
  }

  toggle(){
    //----------------------------------------------
    let value = "";
    let type = this.props.type;
    let text = this.props.text;
    let country = this.state.country;
    let phoneNumber = this.state.phoneNumber;
    //----------------------------------------------
    if(type === "text"){
      if(text != null && text !== "") value = text;
    }
    //----------------------------------------------
    if(type === "country" && text != null){
      let formData = {};
      if(text.length === 2){
        formData.value = text;
        formData.label = countryList().getLabel(text);
      }else{
        formData.value = countryList().getValue(text);
        formData.label = text;
      }
      country = formData;
    }
    //----------------------------------------------
    if(type === "text" || type === "phone_number"){
      if(text != null && text !== "") phoneNumber = text;
    }
    //----------------------------------------------
    this.setState((state)=>({
      toggle:!state.toggle,
      value:value,
      country:country,
      phoneNumber:phoneNumber,
      password:"",
      validationNumber:"",
      step:1,
      otp:false,
      otp_type:"",
      otp_reference:"",
    }))
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingVerification(){
    this.setState((state)=>({
      loadingVerification:!state.loadingVerification
    }))
  }

  loadingValidationNumber(){
    this.setState((state)=>({
      loadingValidationNumber:!state.loadingValidationNumber
    }))
  }

  onChangeValue(e){
    const value = e.target.value;
    const name = e.target.name;
    if(name !== "siren" || value.length <= 9){
      this.setState({
        value:value
      })
    }
  }

  onChangePhoneNumber(value, data, event, formattedValue){
    let resultat = formattedValue.replace(/[\s()/-]/gi, '');
    this.setState({
      phoneNumber:resultat
    })
  }

  onChangeCountry(country){
    this.setState({
      country:country
    })
  }

  onChangePassword(e){
    this.setState({
      password:e.target.value
    })
  }

  onChangeValidationNumber(e){
    this.setState({
      validationNumber:e
    })
  }

  async resendMessage(){

    let otp_type = this.state.otp_type;
    let user = this.props.user;

    let formData = {};
    formData.user_id = user.user_id;
    formData.otp_type  = otp_type;

    let cancelToken = this.state.source.token;

    let response = await RemoteCall(
      false,
      "users/resend-otp",
      "get",
      formData,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          toggle:true,
          otp:response.otp,
          otp_type:response.otp_type,
          otp_reference:response.otp_reference,
          step:(response.otp != null && response.otp) ? 2 : 1,
          validationNumber:""
        })
      }
    }

  }

  async save(){

    const {t} = this.props;
    let cancelToken = this.state.source.token;

    let type = this.props.type;
    let field = this.props.field;
    let sensitive = this.props.sensitive;

    let value = this.state.value;
    let country = this.state.country;
    let phoneNumber = this.state.phoneNumber;
    let password = this.state.password;

    if(type === "country") value = country.value;
    if(type === "phone_number") value = phoneNumber;

    let route = sensitive ? "users/update-user" : "users/update-account";

    if((type === "text" && value === "") || (type === "country" && country == null) || (type === "phone_number" && phoneNumber === "")){
      notification("warning",t('fill_fields_form_label'));
    }else if(type === "phone_number" && !isValidPhoneNumber(phoneNumber)){
      notification("warning",t('invalid_phone_number'));
    }else if(type === "text" && field === "email" && !EmailValidator.validate(value)){
      notification("warning",t('invalid_email'));
    }else if(type === "text" && field === "siren" && value.length !== 9){
      notification("warning",t('siren_invalid'));
    }else if(sensitive && password === ""){
      notification("warning",t('enter_password'));
    }else{

      let formData = {};
      formData.field = field;
      formData.value = value;
      if(sensitive) formData.password = password;

      this.loading();

      let response = await RemoteCall(
        true,
        route,
        "patch",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          //----------------------------------------------------------
          let userCredentials = store.get('userCredentials');
          let data = {};
          data.token = userCredentials.token;
          data.refreshLimit = userCredentials.refreshLimit;
          data.user = response.user;
          //----------------------------------------------------------
          await store.remove('userCredentials');
          store.set('userCredentials', data);
          //----------------------------------------------------------
          this.setState({
            otp:response.otp,
            otp_type:response.otp_type,
            otp_reference:response.otp_reference,
            step:(response.otp != null && response.otp) ? 2 : 1
          });
          //----------------------------------------------------------
          if(response.otp == null || !response.otp){
            notification("info",t('user_infos_modified'));
            this.toggle();
          }
          this.props.getUser(response.user);
          //----------------------------------------------------------
        }
      }

    }

  }

  async verifyOTP(){

    const {t} = this.props;
    let otp_type = this.state.otp_type;
    let otp_reference = this.state.otp_reference;
    let validationNumber = this.state.validationNumber;
    let user = this.props.user;

    let formData = {};
    formData.user_id = user.user_id;
    formData.otp_code  = validationNumber;
    formData.otp_type  = otp_type;
    formData.otp_reference  = otp_reference;

    let cancelToken = this.state.source.token;

    this.loadingValidationNumber();

    let response = await RemoteCall(
      false,
      "users/verify-otp",
      "post",
      null,
      formData,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingValidationNumber();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        //----------------------------------------------------------
        let userCredentials = store.get('userCredentials');
        let data = {};
        data.token = userCredentials.token;
        data.refreshLimit = userCredentials.refreshLimit;
        data.user = response.user;
        //----------------------------------------------------------
        await store.remove('userCredentials');
        store.set('userCredentials', data);
        //----------------------------------------------------------
        notification("info",t('user_infos_modified'));
        this.toggle();
        this.props.getUser(response.user);
        //----------------------------------------------------------
      }
    }

  }

  async verify(field){
    await this.setState({otp_type:field});
    this.loadingVerification();
    await this.resendMessage();
    this.loadingVerification();
  }

  render(){

    const {t} = this.props;
    let user = this.props.user;
    let field = this.props.field;
    let type = this.props.type;
    let text = this.props.text;
    let sensitive = this.props.sensitive;
    let displayText = text;
    if(type === "phone_number" && text != null) displayText = formatPhoneNumberIntl(""+text);
    if(type === "country" && text != null && text.length === 2) displayText = countryList().getLabel(text);

    return(
      <>

        <Row className="mb-2">
          <div className="d-flex align-items-center ml-3 mr-2">
            <Button className="btn-outline-dark" size="sm" onClick={this.toggle}>
              <i className="fa fa-pencil" />
            </Button>
          </div>
          <Col>
            <small>{this.props.title}</small>
            <h6 className="font-weight-bold overflowWrap mb-0">
              {
                text != null && text !== "" ? (
                  <>
                    {type === "country" && text.length === 2 && <FlagIcon code={text.toLowerCase()} className="mr-2" />}
                    {displayText}
                  </>
                ) : (
                  <span className="pointer" onClick={this.toggle}>{t('click_to_modify')}</span>
                )
              }
            </h6>
            {
              field === "email" &&
              <>
                {
                  user.email_verified ? (
                    <small className="text-kamixColorGreen font-italic font-weight-600">{t('verified')}</small>
                  ) : (
                    <>
                      {
                        this.state.loadingVerification ? (
                          <ScaleLoader
                            sizeUnit={"px"}
                            height={15}
                            width={4}
                            radius={2}
                            margin={2}
                            color={loaderColor}
                            loading={this.state.loadingVerification}
                          />
                        ) : (
                          <h6 className="pointer">
                            <Badge color="warning" onClick={(e)=>this.verify(field,e)}>{t('verify')}</Badge>
                          </h6>
                        )
                      }
                    </>
                  )
                }
              </>
            }
          </Col>
        </Row>

        <Modal className="modal-dialog" isOpen={this.state.toggle} toggle={this.toggle}>
          <Card className="bg-secondary">
            <CardBody>

              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('modify_personal_information')}</h6>
              </div>

              {
                this.state.step === 1 &&
                <>

                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText className="text-default mb-1">{this.props.title}</FormText>
                      {
                        type === "text" &&
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="fa fa-hashtag" /></InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name={field} type={field === "siren" ? "number" : "text"}
                            id="text" className="text-dark" value={this.state.value} onChange={this.onChangeValue}
                          />
                        </InputGroup>
                      }
                      {
                        type === "country" &&
                        <Select
                          options={this.state.options}
                          value={this.state.country}
                          onChange={this.onChangeCountry}
                        />
                      }
                      {
                        type === "phone_number" &&
                        <div>
                          <PhoneInput
                            country={'fr'}
                            preferredCountries={['fr']}
                            value={this.state.phoneNumber}
                            onChange={(value, data, event, formattedValue) => this.onChangePhoneNumber(value, data, event, formattedValue)}
                            enableSearch={true}
                          />
                        </div>
                      }
                    </Col>
                  </Row>

                  {
                    sensitive &&
                    <Row className="justify-content-center">
                      <Col xs="12" sm="10">
                        <FormText className="text-default mb-1">{t('password')}</FormText>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" value={this.state.password} onChange={this.onChangePassword}/>
                        </InputGroup>
                      </Col>
                    </Row>
                  }

                  <div className="d-flex justify-content-center mt-4">
                    {
                      this.state.loading ? (
                        <ScaleLoader
                          sizeUnit={"px"}
                          height={20}
                          width={4}
                          radius={2}
                          margin={2}
                          color={loaderColor}
                          loading={this.state.loading}
                        />
                      ) : (
                        <>
                          <Button className="btn-outline-dark mr-2" size="sm" onClick={this.toggle}>{t('cancel')}</Button>
                          <Button color="kamixColorGreen" size="sm"  onClick={this.save}>{t('save')}</Button>
                        </>
                      )
                    }
                  </div>

                </>
              }

              {
                this.state.step === 2 &&
                <Row className="justify-content-center">
                  <Col xs="12" md="10">

                    <div>
                      <FormText className="text-center mb-1">{t('empty_verification_code')}</FormText>
                      <div className="d-flex justify-content-center">
                        <ReactCodeInput
                          name="reactCodeInput"
                          inputMode="numeric"
                          type='text'
                          fields={6}
                          value={this.state.validationNumber}
                          onChange={this.onChangeValidationNumber}
                        />
                      </div>
                    </div>
                    <div className="text-center my-3">
                      <Button size="sm" className="btn-outline-default" onClick={this.resendMessage}>
                        <small>{t('login_resend_otp')}</small>
                      </Button>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop:"30px"}}>
                      {
                        this.state.loadingValidationNumber ? (
                          <ScaleLoader
                            sizeUnit={"px"}
                            height={25}
                            width={4}
                            radius={2}
                            margin={2}
                            color={loaderColor}
                            loading={this.state.loadingValidationNumber}
                          />
                        ) : (
                          <div className="d-flex">
                            <Button size="sm" color="dark" className="btn-outline-dark mr-2" onClick={this.toggle}>{t('cancel')}</Button>
                            <Button color="kamixColorGreen" size="sm" onClick={this.verifyOTP}>{t('check')}</Button>
                          </div>
                        )
                      }
                    </div>

                  </Col>
                </Row>
              }

            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(AccountElement));
