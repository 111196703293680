import React, { Component } from 'react';
import {Button, Modal, ModalBody, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {withTranslation} from "react-i18next";
import RemoteCall, {notification} from "../../../../MyFunctions";

class AddActions extends Component{

  constructor(props){
    super(props);

    this.state = {
      dangerDelete:false,
      loading:false,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.toggleDangerDelete = this.toggleDangerDelete.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  toggleDangerDelete(){
    this.setState({
      dangerDelete: !this.state.dangerDelete,
    });
  };

  async deleteAddress(){

    const {t} = this.props;
    let cancelToken = this.state.source.token;

    this.loading();

    let response = await RemoteCall(
      true,
      "users/remove-address/"+this.props.row.address,
      "delete",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("info",t('address_deleted'));
        this.toggleDangerDelete();
        this.props.getUser(response.user);
      }
    }

  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <Button color="danger" size="sm" onClick={this.toggleDangerDelete}>
          <i className="fa fa-trash"/>
        </Button>
        <Modal isOpen={this.state.dangerDelete} toggle={this.toggleDangerDelete}>
          <ModalBody className="bg-secondary">
            <h6 className="text-center mb-2">{t('warning_delete_address')}</h6>
            <div className="d-flex justify-content-center">
              {
                this.state.loading ? (
                  <PuffLoader
                    sizeUnit={"px"}
                    size={35}
                    color={'#f5365c'}
                    loading={this.state.loading}
                  />
                ) : (
                  <Row>
                    <Button color="default" size="sm" onClick={this.toggleDangerDelete}>{t('cancel')}</Button>
                    <Button size="sm" className="btn-outline-danger" onClick={this.deleteAddress} >{t('delete')}</Button>
                  </Row>
                )
              }
            </div>
          </ModalBody>
        </Modal>
      </div>
    );

  }

}

export default withTranslation()(withRouter(AddActions));
