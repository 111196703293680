import React from "react";
import {withRouter} from "react-router-dom";
import {
  Row, Col, Button, Modal, Card, CardBody,
  Form, FormText, Input, InputGroupAddon, InputGroupText, InputGroup, Badge
} from "reactstrap";
import {withTranslation} from "react-i18next";
import DefaultUser from "../../../../assets/img/profile_example.png";
import RemoteCall, {loaderColor, strongPassword, notification} from "../../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from "axios";
import AccountElement from "./AccountElement";
import "../../../../index.css";

class PersonalInformation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingPassword:false,
      togglePassword:false,
      user:null,
      oldPassword:"",
      newPassword:"",
      confirmPassword:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingPassword = this.loadingPassword.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUserInformation = this.getUserInformation.bind(this);
    this.savePassword = this.savePassword.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingPassword(){
    this.setState((state)=>({
      loadingPassword:!state.loadingPassword
    }))
  }

  togglePassword(){
    this.setState((state)=>({
      togglePassword:!state.togglePassword,
      oldPassword:"",
      newPassword:"",
      confirmPassword:""
    }))
  }

  onChangeOldPassword(e){
    this.setState({
      oldPassword:e.target.value
    })
  }

  onChangeNewPassword(e){
    this.setState({
      newPassword:e.target.value
    })
  }

  onChangeConfirmPassword(e){
    this.setState({
      confirmPassword:e.target.value
    })
  }

  getUser(user){
    this.setState({
      user:user
    })
  }

  async getUserInformation(){

    let cancelToken = this.state.source.token;
    this.loading();

    let response = await RemoteCall(
      true,
      "users/account-info",
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          user:response.user
        })
      }
    }

  }

  async savePassword(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;

    let oldPassword = this.state.oldPassword;
    let newPassword = this.state.newPassword;
    let confirmPassword = this.state.confirmPassword;

    if(oldPassword === "" || newPassword === "" || confirmPassword === ""){
      notification("warning",t('fill_fields_form_label'));
    }else if(!strongPassword.test(newPassword)){
      notification("warning",t('password_strength_indications'));
    }else if(newPassword.localeCompare(confirmPassword) !== 0){
      notification("warning",t('password_fields_do_not_match_label'));
    }else{

      let formData = {};
      formData.old_password = oldPassword;
      formData.new_password = newPassword;

      this.loadingPassword();

      let response = await RemoteCall(
        true,
        "users/change-password",
        "patch",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loadingPassword();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          notification("info",t('password_modified'));
          this.togglePassword();
        }
      }

    }

  }

  componentDidMount() {
    this.getUserInformation();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let user = this.state.user;
    let accountStatusColor = "primary";
    switch (user != null && user.account_status) {
      case "INCOMPLETE_PROFILE":
        accountStatusColor = "danger";
        break;
      case "COMPLETE_PROFILE":
        accountStatusColor = "kamixColorGreen";
        break;
      default:
        break;
    }

    return (
      <>
        {
          this.state.loading ? (
            <div className="d-flex justify-content-center my-5">
              <PuffLoader
                sizeUnit={"px"}
                size={50}
                color={loaderColor}
                loading={this.state.loading}
              />
            </div>
          ) : (
            <div>
              <Row className="text-right mb-2">
                <Col>
                  <Button className="btn-outline-default" size="sm" onClick={this.togglePassword}>{t('change_password')}</Button>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="12" md="3" className="d-none d-md-flex justify-content-center">
                  <img
                    alt="user pic"
                    className="img-fluid rounded-circle"
                    src={DefaultUser}
                    style={{ maxWidth: "100%", maxHeight:"100px" }}
                  />
                </Col>
                {
                  user != null &&
                  <Col xs="12" md="9">

                    {/* General information */}

                    <Row className="mb-2">
                      <div className="d-flex align-items-center ml-3 mr-2 invisible">
                        <Button className="btn-outline-dark" size="sm">
                          <i className="fa fa-pencil" />
                        </Button>
                      </div>
                      <Col>
                        <h6 className="informationTitle">{t('general')}</h6>
                      </Col>
                    </Row>

                    <AccountElement
                      title={t('username')}
                      text={user.user_name}
                      type="text"
                      field={'user_name'}
                      getUser={this.getUser}
                      sensitive={true}
                      user={user}
                    />

                    {
                      user.role != null && user.role.toLowerCase() === "admin" &&
                      <>

                        <AccountElement
                          title={t('first_name')}
                          text={user.first_name}
                          type="text"
                          field={'first_name'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('last_name')}
                          text={user.last_name}
                          type="text"
                          field={'last_name'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                      </>
                    }

                    {
                      user.role != null && user.role.toLowerCase() === "user" &&
                      <>

                        <AccountElement
                          title={t('email')}
                          text={user.email}
                          type="text"
                          field={'email'}
                          getUser={this.getUser}
                          sensitive={true}
                          user={user}
                        />

                      </>
                    }

                    <Row className="mb-1">
                      <div className="d-flex align-items-center ml-3 mr-2 invisible">
                        <Button className="btn-outline-dark" size="sm">
                          <i className="fa fa-pencil" />
                        </Button>
                      </div>
                      <Col>
                        <small>{t('role')}</small>
                        <h6 className="font-weight-bold">{user.role}</h6>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <div className="d-flex align-items-center ml-3 mr-2 invisible">
                        <Button className="btn-outline-dark" size="sm">
                          <i className="fa fa-pencil" />
                        </Button>
                      </div>
                      <Col>
                        <small>{t('features_allowed')}</small>
                        <h5>
                          <Badge color={(user.features_allowed != null && user.features_allowed) ? "kamixColorGreen" : "danger"}>
                            {(user.features_allowed != null && user.features_allowed) ? t('yes') : t('no')}
                          </Badge>
                        </h5>
                      </Col>
                    </Row>

                    {
                      user.account_status != null && user.role != null && user.role.toLowerCase() === "user" &&
                      <Row className="mb-1">
                        <div className="d-flex align-items-center ml-3 mr-2 invisible">
                          <Button className="btn-outline-dark" size="sm">
                            <i className="fa fa-pencil" />
                          </Button>
                        </div>
                        <Col>
                          <small>{t('account_status')}</small>
                          <h5>
                            <Badge color={accountStatusColor}>
                              {t(user.account_status)}
                            </Badge>
                          </h5>
                        </Col>
                      </Row>
                    }


                    {/* Others information */}
                    {
                      user.role != null && user.role.toLowerCase() === "user" &&
                      <>

                        <Row className="mt-4 mb-2">
                          <div className="d-flex align-items-center ml-3 mr-2 invisible">
                            <Button className="btn-outline-dark" size="sm">
                              <i className="fa fa-pencil" />
                            </Button>
                          </div>
                          <Col>
                            <h6 className="informationTitle">{t('others')}</h6>
                          </Col>
                        </Row>

                        <AccountElement
                          title={t('first_name')}
                          text={user.first_name}
                          type="text"
                          field={'first_name'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('last_name')}
                          text={user.last_name}
                          type="text"
                          field={'last_name'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('company_name')}
                          text={user.company_name}
                          type="text"
                          field={'company_name'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('siren')}
                          text={user.siren}
                          type="text"
                          field={'siren'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('tva')}
                          text={user.tva}
                          type="text"
                          field={'tva'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('address')}
                          text={user.address}
                          type="text"
                          field={'address'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('zip_code')}
                          text={user.zip_code}
                          type="text"
                          field={'zip_code'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('city')}
                          text={user.city}
                          type="text"
                          field={'city'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('phone_number')}
                          text={user.phone}
                          type="phone_number"
                          field={'phone'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                        <AccountElement
                          title={t('country')}
                          text={user.country}
                          type="country"
                          field={'country'}
                          getUser={this.getUser}
                          sensitive={false}
                          user={user}
                        />

                      </>
                    }

                  </Col>
                }
              </Row>
            </div>
          )
        }

        {/* Gestion modification mot de passe */}

        <Modal size="md" isOpen={this.state.togglePassword} toggle={this.togglePassword}>
          <Card className="bg-secondary">
            <CardBody>
              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">
                  {t('change_password')}
                </h6>
              </div>
              <div>
                <Form>
                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('old_password')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" id="oldPassword" name="oldPassword"
                               value={this.state.oldPassword} onChange={this.onChangeOldPassword}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  {
                    !strongPassword.test(this.state.newPassword) &&
                    <div className="myWarningAlert2">
                      {t('password_strength_indications')}
                    </div>
                  }
                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('new_password')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" id="newPassword" name="newPassword"
                               value={this.state.newPassword} onChange={this.onChangeNewPassword}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-4">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('confirm_password')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" id="confirmPassword" name="confirmPassword"
                               value={this.state.confirmPassword} onChange={this.onChangeConfirmPassword}/>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="d-flex justify-content-center">
                {
                  this.state.loadingPassword ? (
                    <PuffLoader
                      sizeUnit={"px"}
                      size={35}
                      color={loaderColor}
                      loading={this.state.loadingPassword}
                    />
                  ) : (
                    <Row>
                      <Button color="danger" size="sm" className="mr-2" onClick={this.togglePassword}>{t('cancel')}</Button>
                      <Button color="kamixColorGreen" size="sm" onClick={this.savePassword}>{t('save')}</Button>
                    </Row>
                  )
                }
              </div>
            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(PersonalInformation));
