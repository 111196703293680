import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import {withTranslation} from "react-i18next";

class Page404 extends Component {
  render() {
    const {t} = this.props;
    return (
      <Row className="mt-2">
        <Col>
          <div className="text-center">
            <h1 style={{fontSize:"5em"}} className="mb-0">404</h1>
            <h6 className="display-4">{t('oops_you_are_lost_label')}</h6>
            <h6>{t('page_not_found_info')} !</h6>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(Page404);
