import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {Row, Col, Container, Card, CardBody} from "reactstrap";
import {withTranslation} from "react-i18next";
import "../../index.css";

class PageSuccess extends Component{
  render(){
    const { t } = this.props;
    return(
      <div className="app">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="7">
              <Card className="cardLogin">
                <CardBody>
                  <div className="text-center">
                    <h1 className="checkMark mx-auto mb-4">✓</h1>
                    <h6 className="display-4 text-kamixColorGreen">{t('success')}</h6>
                    <h6>{t('success_message')}</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PageSuccess));