import React, { Component } from 'react';
import {Row, Col, ListGroupItem, FormText, Badge} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getDateToDisplay} from "../../../MyFunctions";
import "../../../index.css";

class CampaignPresentation extends Component {

  render() {

    const {t} = this.props;
    let data = this.props.data;
    let noListGroup = this.props.noListGroup;

    return (
      <div>
        <Row className={noListGroup != null ? "justify-content-center" : "justify-content-center my-3"}>
          <Col xs="11">
            <Row className="justify-content-center">
              <Col xs="12" lg="6" className="mb-3 mb-lg-0">
                <ListGroupItem className={noListGroup != null ? "border-0" : ""}>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('ID')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.campaign_id}
                    </FormText>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('name')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.name}
                    </FormText>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('description')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.description}
                    </FormText>
                  </div>
                  {
                    data.status != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('status')}</small>
                      <FormText color="default" className={"overflowWrap text-" + data.status.toLowerCase()}>
                        {data.status}
                      </FormText>
                    </div>
                  }
                  <div>
                    <small className="font-weight-bold">{t('step')}</small>
                    <h5>
                      <Badge color="primary">
                        {data.step}
                      </Badge>
                    </h5>
                  </div>
                </ListGroupItem>
              </Col>
              <Col xs="12" lg="6">
                <ListGroupItem className={noListGroup != null ? "border-0" : ""}>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('creation_date')}</small>
                    <h5>
                      <Badge color="primary" className="text-dark">
                        {getDateToDisplay(data.creation_date).fullDate}
                      </Badge>
                    </h5>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('update_date')}</small>
                    <h5>
                      <Badge color="primary" className="text-dark">
                        {getDateToDisplay(data.update_date).fullDate}
                      </Badge>
                    </h5>
                  </div>
                  {
                    data.files != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('files')}</small>
                      <h5>
                        <Badge color="primary">
                          {data.files.length}
                        </Badge>
                      </h5>
                    </div>
                  }
                  {
                    data.error_message != null && data.error_message !== "" &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('error_message')}</small>
                      <FormText color="warning" className="overflowWrap font-italic">
                        {data.error_message}
                      </FormText>
                    </div>
                  }
                </ListGroupItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )

  }

}

export default withTranslation()(CampaignPresentation);
