import React, { Component } from 'react';
import {Row, Col, ListGroupItem, Badge, FormText} from "reactstrap";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "../../index.css";

class AddressPresentation extends Component {

  render() {

    const {t} = this.props;
    let data = this.props.data;

    return (
      <div>
        <Row className="justify-content-center my-3">
          <Col xs="11">

            <Row className="justify-content-center">
              <Col xs="12" sm="6">
                <ListGroupItem className="mb-3">
                  <div className="mb-2">
                    <small>{t('tx_id')}</small>
                    <Link to={"/"+this.props.crypto+"/tx/"+data.txid}>
                      <FormText color="primary" className="overflowWrap smallText link font-weight-bold ml-2">
                        {data.txid}
                      </FormText>
                    </Link>
                  </div>
                  <div className="mb-2">
                    <small>{t(data.sens === "+" ? "source_add" : "dest_add")}</small>
                    <Link to={"/"+this.props.crypto+"/address/"+data.address}>
                      <FormText color="primary" className="overflowWrap smallText link font-weight-bold ml-2">
                        {data.address}
                      </FormText>
                    </Link>
                  </div>
                  <div>
                    <small>{t('date')}</small>
                    <h5>
                      <Badge color="secondary" className="p-2">
                        {data.date}
                      </Badge>
                    </h5>
                  </div>
                  <div>
                    <small>{t('sens')}</small>
                    <h5 className={data.sens === "+" ? "positive-sens" : "negative-sens"}>
                      <div className="ml-2">{data.sens}</div>
                    </h5>
                  </div>
                </ListGroupItem>
              </Col>
              <Col xs="12" sm="6">
                <ListGroupItem>
                  <div>
                    <small>{t('value_btc')}</small>
                    <h5>
                      <Badge color="secondary" className="p-2">
                        {data.value}
                      </Badge>
                    </h5>
                  </div>
                  <div>
                    <small>{t('cotation_btc_eur')}</small>
                    <h5>
                      <Badge color="secondary" className="p-2">
                        {data.cotation}
                      </Badge>
                    </h5>
                  </div>
                  <div>
                    <small>{t('value_btc')} (EUR)</small>
                    <h5>
                      <Badge color="secondary" className="p-2">
                        {data.value_euro}
                      </Badge>
                    </h5>
                  </div>
                </ListGroupItem>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    )

  }

}

export default withTranslation()(AddressPresentation);
