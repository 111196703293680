import React, { Component } from 'react';
import {Col, Row, Container, Button, ListGroup, ListGroupItem, Collapse} from "reactstrap";
import {withRouter} from "react-router-dom";
import RemoteCall, {getDateToDisplay, loaderColor} from "../../MyFunctions";
import axios from 'axios';
import {PuffLoader} from "react-spinners";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {withTranslation} from "react-i18next";
import Graph from "../Gadgets/MyGraph";
import "../../index.css";

class TransactionEth extends Component {

  constructor(props) {
    super(props);

    this.state = {
      txId:this.props.match.params.txId,
      toggle:false,
      toggleCollapse:false,
      loadingTransactionInfos:false,
      loadingTransactionDetails:false,
      transactionInfos:null,
      percent:null,
      transactionDetails:null,
      data:null,
      //crypto: this.props.location.pathname.substring(1,4),
      crypto:'eth',
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.loadingTransactionInfos = this.loadingTransactionInfos.bind(this);
    this.loadingTransactionDetails = this.loadingTransactionDetails.bind(this);
    this.getTransactionInfos = this.getTransactionInfos.bind(this);
    this.getTransactionDetails = this.getTransactionDetails.bind(this);
  }

  toggle(){
    this.setState((state)=>({
      toggle:!state.toggle
    }))
  }

  toggleCollapse(){
    this.setState((state)=>({
      toggleCollapse:!state.toggleCollapse
    }))
  }

  loadingTransactionInfos(){
    this.setState((state)=>({
      loadingTransactionInfos:!state.loadingTransactionInfos
    }))
  }

  loadingTransactionDetails(){
    this.setState((state)=>({
      loadingTransactionDetails:!state.loadingTransactionDetails
    }))
  }

  async getTransactionInfos(){

    let txId = this.state.txId;
    let cancelToken = this.state.source.token;

    this.loadingTransactionInfos();

    let response = await RemoteCall(
      false,
      "transaction/"+txId,
      "get",
      {
        search:txId
      },
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingTransactionInfos();

    if(response != null){
      response.gasPrice = response.gasPrice.toFixed(8);
      this.setState({
        transactionInfos:response,
        percent :(response.gasUsed*100/response.gasLimit).toFixed(2)
      })
    }

  }

  async getTransactionDetails(){

    let txId = this.state.txId;
    let cancelToken = this.state.source.token;

    this.loadingTransactionDetails();

    let response = await RemoteCall(
      false,
      "transaction/"+txId,
      "get",
      {
        search:txId
      },
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingTransactionDetails();

    if(response != null){



    }

  }

  componentDidMount() {
    this.getTransactionInfos();
    this.getTransactionDetails();
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let txId = this.state.txId;
    if(txId !== nextProps.id && nextProps.id !== "" && nextProps.id != null){
      await this.setState({
        txId:nextProps.id,
        toggle:false,
        toggleCollapse:false,
        loadingTransactionInfos:false,
        loadingTransactionDetails:false,
        transactionInfos:null,
        transactionDetails:null,
        percent: null,
        data:null,
        crypto: this.props.location.pathname.substring(1,4),
      });
      this.getTransactionInfos();
      this.getTransactionDetails();
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;
    let transactionInfos = this.state.transactionInfos;
    let percent = this.state.percent;
   // let transactionDetails = this.state.transactionDetails;

    return(
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" sm="11">
            {
              this.state.loadingTransactionInfos ? (
                <div className="d-flex justify-content-center mt-3">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loadingTransactionInfos}
                  />
                </div>
              ) : (
                <div>
                  {
                    transactionInfos != null ? (
                      <> 
                        <div className="display-4 mb-4">
                          <h2 className="mb-0">{t('transaction')}</h2>
                          <div className="d-flex align-items-center">
                            <div className="smallText2 mr-2">{transactionInfos.transaction.txid}</div>
                            <CopyToClipboard text={transactionInfos.txid} onCopy={() => {/*notification("info",t('tx_id_copied'))*/}}>
                              <Button color="primary" size="sm" title={t('click_to_copy')}><i className="fa fa-copy"/></Button>
                            </CopyToClipboard>
                          </div>
                        </div>

                        {
                          this.state.data != null &&
                          <Graph data={this.state.data} />
                        }

                        <div className="mb-2">
                          <ListGroup flush>
                            {
                              transactionInfos.transaction.timestamp != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('received_time')}</Col>
                                  <Col xs="8" className="text-right">{getDateToDisplay(transactionInfos.transaction.timestamp).fullDate}</Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionInfos.transaction.blockhash != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('include_in_block')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>
                                      {transactionInfos.transaction.blockhash.substring(0, 1)}...{transactionInfos.transaction.blockhash.substring(transactionInfos.transaction.blockhash.length - 15, transactionInfos.transaction.blockhash.length)}
                                    </div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionInfos.transaction.blockhash}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionInfos != null && transactionInfos.transaction.blockNumber != null &&
                              <ListGroupItem className="p-2 border-bottom">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('block_height')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>{transactionInfos.transaction.blockNumber}</div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionInfos.transaction.blockNumber}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionInfos.transaction != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('from')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>
                                      {transactionInfos.transaction.from.substring(0, 1)}...{transactionInfos.transaction.from.substring(transactionInfos.transaction.from.length - 15, transactionInfos.transaction.from.length)}
                                    </div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionInfos.transaction.from}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              transactionInfos.transaction != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold"> {t('to')}</Col>
                                  <Col xs="8" className="d-flex justify-content-end">
                                    <div>
                                      {transactionInfos.transaction.to.substring(0, 1)}...{transactionInfos.transaction.to.substring(transactionInfos.transaction.to.length - 15, transactionInfos.transaction.to.length)}
                                    </div>
                                    <div className="ml-3">
                                      <CopyToClipboard text={transactionInfos.transaction.to}>
                                        <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
                                      </CopyToClipboard>
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            }

                            {/*
                              transactionInfos.confirmations != null &&
                              <ListGroupItem className="p-2 border-bottom">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('confirmations')}</Col>
                                  <Col xs="8" className="text-right">{transactionInfos.transaction.confirmations} {t('confirmations')}</Col>
                                </Row>
                              </ListGroupItem>
                            */}

                            <Collapse isOpen={this.state.toggleCollapse}>
                              {
                                transactionInfos.gasPrice!= null &&
                                <ListGroupItem className="border-top p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('gas_price')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.gasPrice} ETH</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.gasLimit != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('gas_limit')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.gasLimit} </Col>
                                  </Row>
                                </ListGroupItem>
                              }

                              {
                                transactionInfos.gasUsed != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('gas_used')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.gasUsed} ({percent}%)</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                transactionInfos.transaction.nonce != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('nonce')}</Col>
                                    <Col xs="8" className="text-right">{transactionInfos.transaction.nonce}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                            </Collapse>

                            <div className="d-flex justify-content-end my-3">
                              <Button color="dark" size="sm" onClick={this.toggleCollapse}>
                                {t(this.state.toggleCollapse ? 'see_less' : 'see_more')}
                              </Button>
                            </div>

                            {
                              transactionInfos != null &&
                              <ListGroupItem>
                                <Row className="justify-content-center mt-3 mb-4 feesTxPresentation">
                                  <Col xs="12" lg="5">
                                    {
                                      transactionInfos.fee != null &&
                                      <Row className="fee">
                                        <Col xs="5" className="font-weight-bold">
                                          <div>{t('fees')}</div>
                                        </Col>
                                        <Col xs="7" className="text-right">
                                          {transactionInfos.fee.toFixed(8)} <span className="small">{t('eth')}</span>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                  <div className="text-primary mx-3 d-none d-lg-block invisible"><i className="fa fa-chevron-right fa-2x"/></div>
                                  <Col xs="12" lg="5">
                                    {
                                      transactionInfos.transaction != null &&
                                      <Row className="received">
                                        <Col xs="5" className="font-weight-bold">
                                          <div>{t('received')}</div>
                                        </Col>
                                        <Col xs="7" className="text-right">
                                          {transactionInfos.transaction.value.toFixed(8)} <span className="small">{t('eth')}</span>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                </Row>

                                <Row className="justify-content-end my-3">
                                  {/*<Button color="primary" size="sm" onClick={this.toggle}>{t('details')}</Button>*/}
                                </Row>
                              </ListGroupItem>
                            }
                          </ListGroup>
                        </div>
                      </>
                    ) : (
                      <h6 className="text-center display-4 mt-4">
                        {t('no_result_found')}
                      </h6>
                    )
                  }
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    );

  }

}

export default withTranslation()(withRouter(TransactionEth));