
import Bitcoin from "./views/Bitcoin/Bitcoin";
import Transaction from "./views/Bitcoin/Transaction";
import Address from "./views/Bitcoin/Address";
import Block from "./views/Bitcoin/Block";

import Ethereum from "./views/Ethereum/Ethereum";
import TransactionEth from "./views/Ethereum/Transaction";
import AddressEth from "./views/Ethereum/Address";
import BlockEth from "./views/Ethereum/Block";

import MyAccount from "./views/Actions/MyAccount/MyAccount";
import UsersManagement from "./views/Actions/ManageUsers/UsersManagement";
import Campaigns from "./views/Actions/Campaign/Campaigns";
import CampaignInfos from "./views/Actions/Campaign/CampaignInfos";
import Dashboard from "./views/Dashboard/Dashboard";

import Page404 from "./views/Pages/Page404";
import Page500 from "./views/Pages/Page500";

const routes = [
  { path: '/', exact: true, name: 'Home' },

  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/my_account', exact: true, name: 'My account', component: MyAccount },
  { path: '/users', exact: true, name: 'Users', component: UsersManagement },
  { path: '/accounting', exact: true, name: 'Accounting', component: Campaigns },
  { path: '/accounting/campaigns', exact: true, name: 'Accounting', component: Campaigns },
  { path: '/accounting/campaigns/:id', exact: true, name: 'Campaign details', component: CampaignInfos },

  { path: '/btc', exact:true, name: 'Btc page', component: Bitcoin },
  { path: '/btc/:anchor', exact:true, name: 'Btc page', component: Bitcoin },
  { path: '/btc/tx/:txId', exact: true, name: 'Btc Tx presentation', component: Transaction },
  { path: '/btc/address/:address', exact: true, name: 'Btc Address presentation', component: Address },
  { path: '/btc/block/:type/:heightOrHash', exact: true, name: 'BTC Block presentation', component: Block },

  { path: '/eth', exact:true, name: 'Eth page', component: Ethereum },
  { path: '/eth/:anchor', exact:true, name: 'Eth page', component: Ethereum },
  { path: '/eth/tx/:txId', exact: true, name: 'Eth Tx presentation', component: TransactionEth },
  { path: '/eth/address/:address', exact: true, name: 'Eth Address presentation', component: AddressEth },
  { path: '/eth/block/:type/:heightOrHash', exact: true, name: 'Eth Block presentation', component: BlockEth },

  { path: '/500', exact: true, name: 'Page 500', component: Page500 },
  { path: '/404', exact: true, name: 'Page 404', component: Page404 },
];

export default routes;
