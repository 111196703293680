import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import {withTranslation} from "react-i18next";
import {
  Row, Col, Container, Nav, NavbarBrand,  Navbar, NavItem, Button,
  UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FlagIcon from "../FlagIcon";
import Ban from "../assets/img/ban_trans.png";
import Logo from "../assets/img/logo_short_form.png";
import {store} from "../Security";
import "../index.css";

class DefaultHeader extends Component {

  changeLanguage(language){
    const { i18n } = this.props;
    i18n.changeLanguage(language);
    localStorage.removeItem("lang");
    localStorage.setItem("lang",language);
  }

  async componentDidMount(){
    const { i18n } = this.props;
    let lang = localStorage.getItem('lang');
    if(lang === null || (lang !== "en" && lang !== "fr")){
      localStorage.setItem("lang","fr");
      lang = "fr";
    }
    i18n.changeLanguage(lang);
  }

  render() {

    const { t } = this.props;
    let user = store.get('userCredentials').user;
    let isConnected = store.get('isConnected');
    isConnected = (isConnected === "" || isConnected == null) ? false : isConnected;

    return (
      <>
        <header className="header-global fixed-top">
          <Navbar className="navbar navbar-main navbar-dark " expand="lg" id="navbar-main">

            <Container>

              <NavbarBrand to="/" tag={Link} className="mr-5">
                <img
                  alt="Kamix Logo"
                  src={Ban}
                  style={{width:"70px",height:"100%"}}
                />
              </NavbarBrand>

              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>

              <UncontrolledCollapse navbar toggler="#navbar_global">

                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          className="img-fluid"
                          src={Logo}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="navbar-nav-hover my-lg-0 d-flex align-items-lg-center" navbar>
                  <NavItem>
                    <NavLink to="/btc">
                      <Row className="myNavLink">
                        <Col xs="1" className="pr-lg-1"><i className="fa fa-bitcoin" /></Col>
                        <Col>{t('bitcoin')}</Col>
                      </Row>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/eth">
                      <Row className="myNavLink">
                        <Col xs="1" className="pr-lg-1"><FontAwesomeIcon icon={['fab', 'ethereum']} /></Col>
                        <Col>{t('ethereum')}</Col>
                      </Row>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/dashboard">
                      <Row className="myNavLink">
                        <Col xs="1" className="pr-lg-2"><i className="fa fa-table" /></Col>
                        <Col>{t('dashboard')}</Col>
                      </Row>
                    </NavLink>
                  </NavItem>
                  {
                    isConnected &&
                    <div className="delete">
                      <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                          <i className="fa fa-ellipsis-h fa-lg"/>
                        </DropdownToggle>
                        <DropdownMenu style={{minWidth:"220px"}}>
                          <DropdownItem>
                            <NavLink to="/my_account">
                              <Row className="text-darker">
                                <Col xs="1"><i className="fa fa-user-circle-o" /></Col>
                                <Col><span>{t('my_account')}</span></Col>
                              </Row>
                            </NavLink>
                          </DropdownItem>
                          {
                            user != null && user.role === "ADMIN" &&
                            <DropdownItem>
                              <NavLink to="/users">
                                <Row className="text-darker">
                                  <Col xs="1"><i className="fa fa-users" /></Col>
                                  <Col><span>{t('manage_users')}</span></Col>
                                </Row>
                              </NavLink>
                            </DropdownItem>
                          }
                          <DropdownItem>
                            <NavLink to="/accounting">
                              <Row className="text-darker">
                                <Col xs="1"><i className="fa fa-calculator" /></Col>
                                <Col><span>{t('accounting')}</span></Col>
                              </Row>
                            </NavLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  }
                </Nav>

                <Nav className="navbar-nav-hover ml-md-auto" navbar>
                  <div className="delete">
                    <UncontrolledDropdown nav id="languageSelector1">
                      <DropdownToggle nav>
                        <i className="fa fa-language fa-lg mr-2"/>
                        <span>{t('language_label')}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e)=> this.changeLanguage("en",e)}>
                          <FlagIcon code={"gb"} size={"lg"} /> <span className="ml-2">{t('english_label')}</span>
                        </DropdownItem>
                        <DropdownItem onClick={(e)=> this.changeLanguage("fr",e)}>
                          <FlagIcon code={"fr"} size={"lg"} /> <span className="ml-2">{t('french_label')}</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="delete">
                      {
                        isConnected ? (
                          <NavItem>
                            <NavLink to="#" onClick={(e)=>this.props.logOut(e)}>
                              <Row className="myNavLink">
                                <Col xs="1"><i className="fa fa-window-close fa-lg" /></Col>
                                <Col><span className="bobo">{t('log_out')}</span></Col>
                              </Row>
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavItem>
                            <NavLink to="/login">
                              <div className="myNavLink">
                                <Button color="white" size="sm" className="text-kamixColorGreen">{t('sign_in')}</Button>
                              </div>
                            </NavLink>
                          </NavItem>
                        )
                      }
                    </div>
                  </div>
                </Nav>

                <div id="language" className="mt-3">
                  {
                    isConnected &&
                    <div>
                      <Nav className="navbar-nav-hover " navbar>
                        <NavItem>
                          <NavLink to="/my_account">
                            <Row className="myNavLink">
                              <Col xs="1"><i className="fa fa-user-circle-o" /></Col>
                              <Col><span>{t('my_account')}</span></Col>
                            </Row>
                          </NavLink>
                        </NavItem>
                        {
                          user != null && user.role === "ADMIN" &&
                          <NavItem>
                            <NavLink to="/users">
                              <Row className="myNavLink">
                                <Col xs="1"><i className="fa fa-users" /></Col>
                                <Col><span>{t('manage_users')}</span></Col>
                              </Row>
                            </NavLink>
                          </NavItem>
                        }
                        <NavItem>
                          <NavLink to="/accounting">
                            <Row className="myNavLink">
                              <Col xs="1"><i className="fa fa-calculator" /></Col>
                              <Col><span>{t('accounting')}</span></Col>
                            </Row>
                          </NavLink>
                        </NavItem>
                        <NavItem className="my-3">
                          <NavLink to="#">
                            <Row className="myNavLink" onClick={(e)=>this.props.logOut(e)}>
                              <Col xs="1"><i className="fa fa-window-close" /></Col>
                              <Col><span>{t('log_out')}</span></Col>
                            </Row>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  }
                  <div className="text-default font-weight-bold mb-1">{t('language_label')}</div>
                  <div>
                    <Button color="kamixColorGreen" size="sm" onClick={(e)=> this.changeLanguage("en",e)}>
                      <FlagIcon code={"gb"} size={"lg"} /> <span className="ml-2">{t('english_label')}</span>
                    </Button>
                    <Button color="kamixColorGreen" size="sm" onClick={(e)=> this.changeLanguage("fr",e)}>
                      <FlagIcon code={"fr"} size={"lg"} /> <span className="ml-2">{t('french_label')}</span>
                    </Button>
                  </div>
                  {
                    !isConnected &&
                    <Link to="/login">
                      <Button color="dark" size="sm" className="mt-4">{t('sign_in')}</Button>
                    </Link>
                  }
                </div>

              </UncontrolledCollapse>

            </Container>

          </Navbar>
        </header>
      </>
    );

  }

}

export default withTranslation()(withRouter(DefaultHeader));
