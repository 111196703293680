import React, { Component } from 'react';
import {withRouter} from "react-router";
import {Row, Col, Card, CardHeader, Collapse, CardBody, Button, Modal, Badge} from "reactstrap";
import {withTranslation} from "react-i18next";
import {PuffLoader} from "react-spinners";
import RemoteCall, {getError, loaderColor, notification} from "../../../../MyFunctions";
import FilesSelectorByLedger from "../FilesSelectorByLedger";
import axios from "axios";
import jszip from "jszip";
import {store} from "../../../../Security";
import DataTable from "react-data-table-component";
import CampaignFilePresentation from "../Files/CampaignFilePresentation";
import CampaignFileActions from "../Files/CampaignFileActions";
import "../../../../index.css";

class Step1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle:false,
      loading:false,
      loadingNextStep:false,
      platform:null,
      year:new Date().getFullYear(),
      file:null,
      files:[],
      data:[],
      option:"1",
      addresses:[],
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.loading = this.loading.bind(this);
    this.loadingNextStep = this.loadingNextStep.bind(this);
    this.getPlatform = this.getPlatform.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getFile = this.getFile.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getData = this.getData.bind(this);
    this.getEtherscanOption = this.getEtherscanOption.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.isDataValid = this.isDataValid.bind(this);
    this.containingFile = this.containingFile.bind(this);
    this.saveFile = this.saveFile.bind(this);
    this.launchNextStep = this.launchNextStep.bind(this);
  }

  toggle(){
    this.setState((state)=>({
      toggle:!state.toggle
    }))
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingNextStep(){
    this.setState((state)=>({
      loadingNextStep:!state.loadingNextStep
    }))
  }

  getPlatform(platform){
    this.setState({
      platform:platform,
      file:null,
      files:[],
      data:[],
      errorMessage:null,
    })
  }

  getYear(year){
    this.setState({
      year:year
    })
  }

  getFile(file){
    this.setState({
      file:file
    })
  }

  getFiles(files){
    this.setState({
      files:files
    })
  }

  getData(data){
    this.setState({
      data:data
    })
  }

  getEtherscanOption(option){
    this.setState({
      option:option
    })
  }

  getAddresses(addresses){
    this.setState({
      addresses:addresses
    })
  }

  isDataValid(){
    let response = true;
    let data = this.state.data;
    for(let element of data){
      if(!element.isValid){
        response = false;
        break;
      }
    }
    return response;
  }

  containingFile(name){
    let response = false;
    let files = this.state.files;
    for(let file of Array.from(files)){
      if(file.name.includes(name)){
        response = true;
        break;
      }
    }
    return response;
  }

  async saveFile(){

    const {t} = this.props;

    let campaign = this.props.campaign;
    let fileToSend = "";
    let file = this.state.file;
    let files = this.state.files;
    let data = this.state.data;
    let addresses = this.state.addresses;
    let platform = this.state.platform;
    let year = this.state.year;
    let option = this.state.option;
    let formData = new FormData();

    if(platform.type === "csv"){

      fileToSend = file;
      formData.append('campaign',fileToSend,fileToSend.name);

    }else{

      let zip = new jszip();
      //--------------------------------------

      if(platform.value === "etherscan"){

        if(option === "1"){
          for(let element of data){
            let folder = zip.folder("data_" + element.id);
            for(let file of element.content){
              folder.file(file.name,file);
            }
          }
          await zip.generateAsync({type: "blob"}).then(content => {
            fileToSend = content;
          });
          formData.append('campaign',fileToSend, "data.zip");
        }else{
          formData = {addresses:addresses}
        }

      }

      //--------------------------------------

      if(platform.value === "zebitex"){
        for(let file of files){
          zip.file(file.name,file);
        }
        await zip.generateAsync({type: "blob"}).then(content => {
          fileToSend = content;
        });
        formData.append('campaign',fileToSend, "data.zip");
      }

      //--------------------------------------

    }

    this.loading();

    //--------------------------------------------------------------------
    let headers = {};
    headers['x-access-token'] = store.get('userCredentials').token;
    headers['Content-Type'] = (platform.value === "etherscan" && option === "2") ? 'application/json' : 'multipart/form-data;';
    let config = {
      url: (platform.value === "etherscan" && option === "2") ?
        process.env.REACT_APP_URL_ACC + "campaigns/source/"+campaign.campaign_id :
        process.env.REACT_APP_URL_ACC + "campaigns/files/"+campaign.campaign_id,
      method:"post",
      headers:headers,
      cancelToken:this.state.source.token
    };
    config.data = formData;
    config.params = {
      year:year,
      ledger:platform.value
    };
    //--------------------------------------------------------------------
    return axios.request(config)
      .then(response =>{
        this.loading();
        let data = response.data;
        getError(data,this.props);
        let error = data.error;
        if(error != null && !error){
          this.toggle();
          notification('success',t('successful_add_on'));
          this.props.getCampaign(data.campaign);
        }
      }).catch(err =>{
        this.loading();
        if(axios.isCancel(err)) {
        }else{
          let data = err.response.data;
          notification("warning",data);
        }
      });
    //--------------------------------------------------------------------

  }

  async launchNextStep(){

    const { t } = this.props;
    let campaign = this.props.campaign;
    let cancelToken = this.state.source.token;

    this.loadingNextStep();

    let response = await RemoteCall(
      true,
      "campaigns/step1/"+campaign.campaign_id,
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingNextStep();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("success",t('operation_done'));
        this.props.getCampaign(response.campaign);
      }
    }

  }

  render() {

    const {t} = this.props;
    let campaign = this.props.campaign;

    const columns = [
      {
        name: t('ledger'),
        selector: 'ledger',
        cell: row => <div>{t(row.ledger)}</div>,
      },
      {
        name: t('source_type'),
        selector: 'source_type',
        cell: row => <div className="font-weight-700">{t(row.source_type)}</div>,
        maxWidth:"150px",
        hide:"lg"
      },
      {
        name: t('size'),
        selector: 'source_metadata.file_size',
        cell: row =>
          <div className="font-weight-700">
            {row.source_metadata != null ? row.source_metadata.file_size.toFixed(4)+ " Mo" : "--"}
          </div>,
        maxWidth:"150px",
        hide:"lg"
      },
      /*{
        name: t('nb_lines'),
        selector: 'source_metadata.nb_lines',
        cell: row => <div className="font-weight-700">{row.source_metadata != null ? row.source_metadata.nb_lines : ""}</div>,
        maxWidth:"100px",
        hide:"lg"
      },*/
      {
        name:t('actions'),
        cell: (row) => <CampaignFileActions campaign_id={campaign.campaign_id} row={row} getCampaign={this.props.getCampaign} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        omit: campaign.step !== 0
      },
    ];

    return (
      <>

        <Card className="mb-1">
          <CardHeader id="headingOne" className="pointer" onClick={() => this.props.toggleAccordion(0)}>
            <div className="d-flex align-items-center font-weight-bold text-darker">
              <div className="mr-auto">{t('import')}</div>
              <div onClick={() => this.props.toggleAccordion(0)} aria-expanded={this.props.accordion} aria-controls="collapseOne">
                {this.props.accordion ? <i className="fa fa-chevron-down" /> : <i className="fa fa-chevron-up" />}
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={this.props.accordion} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
            <CardBody>

              <div className="d-flex mb-2">
                <div className="mr-3">
                  {
                    campaign.step === 0 ?
                      <i className="fa fa-ellipsis-h text-warning" />:
                      <i className="fa fa-check-circle text-kamixColorGreen" />
                  }
                </div>
                <div>{t(campaign.step === 0 ? 'upload_campaign_files' : 'files_uploaded')}</div>
              </div>

              {
                campaign.files != null && campaign.files.length > 0 &&
                <DataTable
                  className="mt-3"
                  noHeader={true}
                  columns={columns}
                  data={campaign.files}
                  pagination
                  striped
                  responsive
                  expandableRows
                  expandOnRowClicked
                  expandableRowsComponent={<CampaignFilePresentation step={1} />}
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5,10]}
                />
              }

              {
                this.state.loadingNextStep ? (
                  <div className="mb-4">
                    <PuffLoader
                      sizeUnit={"px"}
                      size={30}
                      color={loaderColor}
                      loading={this.state.loadingNextStep}
                    />
                  </div>
                ) : (
                  <div className="d-flex mt-3">
                    {
                      campaign.step === 0 &&
                      <Button color="default" size="sm" onClick={this.toggle} className="mr-3">
                        <i className="fa fa-upload" />
                      </Button>
                    }
                    {
                      campaign.step === 0 && campaign.files != null && campaign.files.length >0 &&
                      <Button color="kamixColorGreen" size="sm" onClick={this.launchNextStep}>
                        {t('next_step')}
                      </Button>
                    }
                  </div>
                )
              }

            </CardBody>
          </Collapse>
        </Card>

        <Modal size="lg" isOpen={this.state.toggle} toggle={this.toggle}>
          <Card>
            <CardBody>

              <Row className="justify-content-center">
                <Col xs="12" md="11">

                  <h5 className="text-center mb-3">
                    <Badge color="kamixColorGreen" className="px-3">
                      {t('upload_file')}
                    </Badge>
                  </h5>

                  <FilesSelectorByLedger
                    getPlatform={this.getPlatform}
                    getYear={this.getYear}
                    getFile={this.getFile}
                    getFiles={this.getFiles}
                    getData={this.getData}
                    getEtherscanOption={this.getEtherscanOption}
                    getAddresses={this.getAddresses}
                  />

                  {
                    (
                      (this.state.file != null && this.state.platform != null && this.state.platform.type === "csv") ||
                      (this.state.files.length === 2 && this.state.platform != null && this.state.platform.value === "zebitex" && this.containingFile("trades") && this.containingFile("wd")) ||
                      (this.state.data.length !== 0 && this.state.platform != null && this.state.platform.value === "etherscan" && this.isDataValid() && this.state.option === "1") ||
                      (this.state.addresses.length !== 0 && this.state.platform != null && this.state.platform.value === "etherscan" && this.state.option === "2")
                    ) &&
                    <div className="d-flex mt-3">
                      {
                        this.state.loading ? (
                          <div className="mb-4">
                            <PuffLoader
                              sizeUnit={"px"}
                              size={30}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          </div>
                        ) : (
                          <Button color="kamixColorGreen" size="sm" onClick={this.saveFile}>
                            {t('add_to_campaign')}
                          </Button>
                        )
                      }
                    </div>
                  }

                </Col>
              </Row>

            </CardBody>
          </Card>
        </Modal>

      </>
    )

  }

}

export default withTranslation()(withRouter(Step1));
