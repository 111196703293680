import React, { Component } from 'react';
import {Button} from "reactstrap";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {withTranslation} from "react-i18next";
import {loaderColor} from "../../../MyFunctions";
import {store} from "../../../Security";
import fileDownload from "js-file-download";

class DownloadFile extends Component{

  constructor(props){
    super(props);
    this.state = {
      loadingDownload:false,
      source:axios.CancelToken.source()
    };
    this.loadingDownload = this.loadingDownload.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  loadingDownload(){
    this.setState((state)=>({
      loadingDownload:!state.loadingDownload
    }))
  }

  async downloadFile(externalFileLink){
    let table = externalFileLink.split('/');
    let fileName = table[table.length-1];
    this.loadingDownload();
    //--------------------------------------------------------------------
    let headers = {};
    headers['x-access-token'] = store.get('userCredentials').token;
    headers['Content-Type'] = 'multipart/form-data;';
    let config = {
      url: externalFileLink,
      method:"get",
      headers:headers,
      cancelToken:this.state.source.token
    };
    config.responseType = "blob";
    //--------------------------------------------------------------------
    return axios.request(config)
      .then(response =>{
        this.loadingDownload();
        let blob = response.data;
        fileDownload(blob,fileName);
      }).catch(err =>{
        this.loadingDownload();
        if(axios.isCancel(err)) {
        }else{
        }
      });
    //--------------------------------------------------------------------
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const { t } = this.props;
    let url = this.props.url;

    return (
      <div>
        {
          url != null ? (
            <>
              {
                this.state.loadingDownload ? (
                  <div style={{height:"27px"}}>
                    <PuffLoader
                      sizeUnit={"px"}
                      size={25}
                      color={loaderColor}
                      loading={this.state.loadingDownload}
                    />
                  </div>
                ) : (
                  <Button color="kamixColorGreen" size="sm" title={t('download')} onClick={(e)=>this.downloadFile(url,e)}>
                    <i className="fa fa-download" />
                  </Button>
                )
              }
            </>
          ) : (
            <><i className="fa fa-ellipsis-h text-warning" /> </>
          )
        }
      </div>
    );

  }

}

export default withTranslation()(withRouter(DownloadFile));
