import React, { Component } from 'react';
import {Col, Row, Card, CardBody} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, loaderColor} from "../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from 'axios';
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {CopyToClipboard} from "react-copy-to-clipboard";
import "../../index.css";

class BitcoinInformation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingBitcoinInfos:false,
      loadingRecentBlocks:false,
      loadingRecentTransactions:false,
      bitcoinInfos:null,
      recentBlocks:null,
      recentTransactions:null,
      source:axios.CancelToken.source()
    };

    this.loadingBitcoinInfos = this.loadingBitcoinInfos.bind(this);
    this.loadingRecentBlocks = this.loadingRecentBlocks.bind(this);
    this.loadingRecentTransactions = this.loadingRecentTransactions.bind(this);
    this.getBitcoinInfos = this.getBitcoinInfos.bind(this);
    this.getRecentBlocks = this.getRecentBlocks.bind(this);
    this.getRecentTransactions = this.getRecentTransactions.bind(this);
  }

  loadingBitcoinInfos(){
    this.setState((state)=>({
      loadingBitcoinInfos:!state.loadingBitcoinInfos
    }))
  }

  loadingRecentBlocks(){
    this.setState((state)=>({
      loadingRecentBlocks:!state.loadingRecentBlocks
    }))
  }

  loadingRecentTransactions(){
    this.setState((state)=>({
      loadingRecentTransactions:!state.loadingRecentTransactions
    }))
  }

  async getBitcoinInfos(){

    let cancelToken = this.state.source.token;

    this.loadingBitcoinInfos();

    let response = await RemoteCall(
      false,
      "getbitcoininfos",
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'btc'
    );

    this.loadingBitcoinInfos();

    if(response != null){
      this.setState({
        bitcoinInfos:response
      })
    }

  }

  async getRecentBlocks(){

    let cancelToken = this.state.source.token;

    this.loadingRecentBlocks();

    let response = await RemoteCall(
      false,
      "getRecentBlocks",
      "get",
      {
        page:1,
        limit:5
      },
      null,
      cancelToken,
      this.props,
      'btc'
    );

    this.loadingRecentBlocks();
    if(response != null){

      this.setState({
        recentBlocks:response
      })
    }

  }

  async getRecentTransactions(){

    let cancelToken = this.state.source.token;

    this.loadingRecentTransactions();

    let response = await RemoteCall(
      false,
      "hometransactions",
      "get",
      {
        page:1,
        limit:5
      },
      null,
      cancelToken,
      this.props,
      'btc'
    );

    this.loadingRecentTransactions();

    if(response != null){
      let transactions = response;
      for(let transaction of transactions){
        transaction.valueIn = transaction.valueIn.toFixed(8);
        transaction.fee = transaction.fee.toFixed(8);
        transaction.date = getDateToDisplay(transaction.date).fullDate;
      }
      this.setState({
        recentTransactions:transactions
      })
    }

  }

  componentDidMount() {
    this.getBitcoinInfos();
    this.getRecentBlocks();
    this.getRecentTransactions();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;

    const columns = [
      {
        name:`${t('name')}`,
        selector: 'name'
      },
      {
        name: `${t('Value')}`,
        cell: (row) => <div className="font-weight-500 text-center" >{row.value}</div>,
        width:"145px",
        center:true
      }
    ];

    let bitcoinInfos = this.state.bitcoinInfos != null ? [
      {name:t('number_of_blocs'), value:this.state.bitcoinInfos.total},
      {name:t('last_height'), value:this.state.bitcoinInfos.total - 1},
      {name:t('processed_block'), value:this.state.bitcoinInfos.processed},
      {name:t('percentage'), value:this.state.bitcoinInfos.percentage + " %"},
    ] : null;

    const transactionsColumns = [
      {
        name:'Tx ID',
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.txid}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/btc/tx/"+row.txid}>
              <div className="link font-weight-bold d-none d-sm-block">
                {row.txid.substring(0, 2)}...{row.txid.substring(row.txid.length - 2, row.txid.length)}
              </div>
              <div className="link font-weight-bold d-sm-none">
                {row.txid.substring(0, 3)}...
              </div>
            </Link>
          </>,
      },
      {
        name: `${t('value')}`,
        cell: (row) => <>{row.valueIn} <span className="small font-weight-500 ml-1">BTC</span></>,
        minWidth:"150px"
      },
      {
        name: `${t('date')}`,
        selector: 'date',
        width:"145px"
      }
    ];

    const blocksColumns = [
      {
        name:`${t('height')}`,
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.blockhash}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/btc/block/h/"+row.height}>
              <div className={classnames("dot", "bg-"+row.status.toLowerCase())} title={t(row.status)}><i className="fa fa-check invisible"/></div>
              <div className="link font-weight-bold">{row.height}</div>
            </Link>
          </>
      },
      {
        name:`${t('nb_tx')}`,
        cell: (row) => <div>{getAmountToDisplay(row.tx_count)}</div>,
        width:"80px"
      },
      {
        name:`${t('date')}`,
        cell: (row) => <div>{getDateToDisplay(row.time,true).fullDate}</div>,
        width:"145px"
      }
    ];

    return(
      <div>

        <Card className="shadow-sm mb-4">
          <CardBody>
            {
              this.state.loadingBitcoinInfos ? (
                <div className="d-flex justify-content-center my-4">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loadingBitcoinInfos}
                  />
                </div>
              ) : (
                <Row className="justify-content-center">
                  <Col xs="12" sm="11">
                    <h5 className="font-weight-500">{t('treatment_information')}</h5>
                    {
                      bitcoinInfos != null &&
                      <DataTable
                        className="my-2"
                        noHeader={true}
                        noTableHead={true}
                        columns={columns}
                        data={bitcoinInfos}
                        dense
                        striped
                        responsive
                      />
                    }
                  </Col>
                </Row>
              )
            }
          </CardBody>
        </Card>

        <Card className="shadow-sm">
          <CardBody>
            <Row className="justify-content-center">
              <Col xs="12" lg="6" className="mb-5 mb-lg-0">
                {
                  this.state.loadingRecentBlocks ? (
                    <div className="d-flex justify-content-center my-4">
                      <PuffLoader
                        sizeUnit={"px"}
                        size={50}
                        color={loaderColor}
                        loading={this.state.loadingRecentBlocks}
                      />
                    </div>
                  ) : (
                    <Row>
                      <Col xs="12">
                        <h5 className="font-weight-500">{t('latest_blocks')}</h5>
                        {
                          this.state.recentBlocks != null &&
                          <DataTable
                            className="my-2"
                            noHeader={true}
                            columns={blocksColumns}
                            data={this.state.recentBlocks.block}
                            dense
                            striped
                            responsive
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5]}
                          />
                        }
                        <Link to="/btc/b">
                          <span className="viewAllButton">
                            {t('view_all_blocks')}
                            <i className="fa fa-arrow-right ml-2"/>
                          </span>
                        </Link>
                      </Col>
                    </Row>
                  )
                }
              </Col>
              <Col xs="12" lg="6">
                {
                  this.state.loadingRecentTransactions ? (
                    <div className="d-flex justify-content-center my-4">
                      <PuffLoader
                        sizeUnit={"px"}
                        size={50}
                        color={loaderColor}
                        loading={this.state.loadingRecentTransactions}
                      />
                    </div>
                  ) : (
                    <Row className="justify-content-center">
                      <Col xs="12">
                        <h5 className="font-weight-500">{t('latest_transactions')}</h5>
                        {
                          this.state.recentTransactions != null &&
                          <DataTable
                            className="my-2"
                            noHeader={true}
                            columns={transactionsColumns}
                            data={this.state.recentTransactions}
                            dense
                            striped
                            responsive
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5]}
                          />
                        }
                        <Link to="/btc/tx">
                          <span className="viewAllButton">
                            {t('view_all_tx')}
                            <i className="fa fa-arrow-right ml-2"/>
                          </span>
                        </Link>
                      </Col>
                    </Row>
                  )
                }
              </Col>
            </Row>
          </CardBody>
        </Card>

      </div>
    );

  }

}

export default withTranslation()(withRouter(BitcoinInformation));