import React, { Component } from 'react';
import {withRouter} from "react-router";
import {Row, Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import "../../../index.css";

class CampaignActions2 extends Component {

  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom(){
    this.messagesEndRef.current != null && this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let campaign = this.props.campaign;
    if(campaign.error_message != null && campaign.error_message !== "" && this.props.plainTabs === 2){
      this.scrollToBottom();
    }
  }

  render() {

    const {t} = this.props;
    let campaign =this.props.campaign;

    return (
      <Row className="justify-content-center">
        <Col xs="12" lg="11">

          <div id="accordion">

            {
              campaign.step >= 0 &&
              <Step1
                campaign={campaign} getCampaign={this.props.getCampaign}
                toggleAccordion={this.props.toggleAccordion} accordion={this.props.accordion[0]}
              />
            }

            {
              campaign.step >= 1 &&
              <Step2
                campaign={campaign} getCampaign={this.props.getCampaign}
                toggleAccordion={this.props.toggleAccordion} accordion={this.props.accordion[1]}
              />
            }

            {
              campaign.step === 4 &&
              <Step3
                campaign={campaign} getCampaign={this.props.getCampaign}
                toggleAccordion={this.props.toggleAccordion} accordion={this.props.accordion[2]} recapData={this.props.recapData}
              />
            }

            <div>
              {
                campaign.error_message &&
                <Row className="mt-4">
                  <Col>
                    <small className="font-weight-bold text-warning">{t('error_message')}</small>
                    <div className="myWarningAlert">
                      {campaign.error_message}
                    </div>
                  </Col>
                </Row>
              }
              <div ref={this.messagesEndRef} />
            </div>

          </div>

        </Col>
      </Row>
    )

  }

}

export default withTranslation()(withRouter(CampaignActions2));
