import React, { Component } from 'react';
import {Button} from "reactstrap"
import {withRouter} from "react-router-dom";
import '../../index.css';

class ManagePage extends Component{

  constructor(props){
    super(props);
    this.state = {
      start:"",
      end:""
    };
    this.go = this.go.bind(this);
    this.back = this.back.bind(this);
    this.getStartAndEnd = this.getStartAndEnd.bind(this);
    this.initializePagination = this.initializePagination.bind(this);
  }

  getStartAndEnd(size,page){
    this.setState({
      start:size*(page-1)+1,
      end:size*page
    })
  }

  go(){
    let page = this.props.page;
    let endPage = this.initializePagination(this.props.total);
    if(page < endPage){
      page = page + 1;
    }else{
      page = 0;
    }
    this.getStartAndEnd(this.props.size,page+1);
    this.props.getPage(page);
    this.props.getDatas(page);
  }

  back(){
    let page = this.props.page;
    let endPage = this.initializePagination(this.props.total);
    if(page > 0){
      page = page - 1;
    }else{
      page = endPage;
    }
    this.getStartAndEnd(this.props.size,page+1);
    this.props.getPage(page);
    this.props.getDatas(page);
  }

  initializePagination(total) {
    let endPage=0;
    if(total <=this.props.size){
      endPage=1;
    }else{
      endPage = Math.trunc(total/this.props.size);
      let m = total % this.props.size;
      if(m!==0){
        endPage= endPage +1;
      }
    }
    return endPage-1;
  }

  async componentDidMount() {
    await this.getStartAndEnd(this.props.size,1);
  }

  render(){

    let start = this.state.start;
    let end = this.state.end;
    if(this.props.page === 0){
      start = 1;
      end = this.props.size;
    }

    return(
      <div className={this.props.total === 0 ? "invisible" : ""}>
        <div className="d-flex flex-row justify-content-end align-items-center mt-1 mb-1">
        <span className="mr-2">
          {start} - {end > this.props.total ? this.props.total : end} sur {this.props.total}
        </span>
          <Button className="btn-outline-dark mr-2 ml-2" size="sm" onClick={this.back} disabled={this.props.total <= this.props.size}>
            <i className="fa fa-chevron-left"/>
          </Button>
          <Button className="btn-outline-dark mr-3 ml-2" size="sm" onClick={this.go} disabled={this.props.total <= this.props.size}>
            <i className="fa fa-chevron-right"/>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ManagePage);
