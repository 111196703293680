import React, { Component } from 'react';
import {withRouter} from "react-router";
import {Row, Container, Col, Card, CardBody, Input, FormText, Button, InputGroupAddon, InputGroupText, InputGroup, Progress} from "reactstrap";
import axios from "axios";
import RemoteCall, {loaderColor, strongPassword, notification} from "../../../MyFunctions";
import {withTranslation} from "react-i18next";
import {ScaleLoader} from "react-spinners";
import {store} from "../../../Security";
import "../../../index.css";

class Step3 extends Component{

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      hidden:true,
      userName:"",
      password:"",
      confirmPassword:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.stepThree = this.stepThree.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  hidePassword(){
    this.setState({
      hidden: !this.state.hidden
    })
  }

  onChangeUserName(e){
    this.setState({
      userName:e.target.value
    })
  }

  onChangePassword(e){
    this.setState({
      password:e.target.value
    })
  }

  onChangeConfirmPassword(e){
    this.setState({
      confirmPassword:e.target.value
    })
  }

  async stepThree(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;
    let registerInformation = store.get('registerInformation');
    let user = registerInformation.user;

    let userName = this.state.userName;
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;

    if(userName === "" || password === "" || confirmPassword === ""){
      notification("warning",t('fill_fields_form_label'));
    }else if(!strongPassword.test(password)){
      notification("warning",t('password_strength_indications'));
    }else if(password.localeCompare(confirmPassword) !== 0){
      notification("warning",t('password_fields_do_not_match_label'));
    }else{

      let formData = {};
      formData.user_id = user.user_id;
      formData.user_name = userName;
      formData.password = password;

      this.loading();

      let response = await RemoteCall(
        false,
        "users/register/step3",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          let data = {};
          let counter = 45 * 60000;
          data.user = response.user;
          data.token = response.token;
          data.refreshLimit = new Date().getTime() +counter;
          data.step3 = "ok";
          store.set('userCredentials', data);
          await store.remove('registerInformation');
          this.props.history.push('/register/step4');
        }
      }

    }

  }

  componentDidMount() {
    let registerInformation = store.get('registerInformation');
    if(registerInformation == null || registerInformation === "" || registerInformation.step2 == null){
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const { t } = this.props;

    return(
      <>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="7">

              <Card className="cardLogin">
                <CardBody>

                  <Row className="justify-content-center mb-2">
                    <Col xs="10" md="8">
                      <h5 className="font-weight-500 text-center mb-1">{t('step_label')} 3 / 4</h5>
                      <Progress animated color="kamixColorGreen" value="75" />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs="11" md="10">

                      <div className="mb-3">
                        <FormText className="mb-1">{t('user_name')}</FormText>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="fa fa-user" /></InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="text-dark font-weight-500"
                            value={this.state.userName}
                            onChange={this.onChangeUserName}
                            bsSize="sm"
                            required
                          />
                        </InputGroup>
                      </div>

                      {
                        !strongPassword.test(this.state.password) &&
                        <div className="myWarningAlert2">
                          {t('password_strength_indications')}
                        </div>
                      }

                      <div className="mb-3">
                        <FormText className="mb-1">{t('password')}</FormText>
                        <div className="d-flex">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={this.state.hidden ? "password" : "text"}
                              className="text-dark font-weight-500"
                              value={this.state.password}
                              onChange={this.onChangePassword}
                              bsSize="sm"
                              required
                            />
                          </InputGroup>
                          <Button color="white" size="sm" onClick={this.hidePassword} className="ml-1">
                            {this.state.hidden ? (<i className="fa fa-eye-slash fa-lg"/>) : (<i className="fa fa-eye fa-lg"/>)}
                          </Button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <FormText className="mb-1">{t('confirm_password_2')}</FormText>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type={this.state.hidden ? "password" : "text"}
                            className="text-dark font-weight-500"
                            value={this.state.confirmPassword}
                            onChange={this.onChangeConfirmPassword}
                            bsSize="sm"
                            required
                          />
                        </InputGroup>
                      </div>

                      <div className="d-flex justify-content-center" style={{marginTop:"35px"}}>
                        {
                          this.state.loading ? (
                            <ScaleLoader
                              sizeUnit={"px"}
                              height={25}
                              width={4}
                              radius={2}
                              margin={2}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          ) : (
                            <Button color="kamixColorGreen" size="sm" onClick={this.stepThree}>{t('continue')}</Button>
                          )
                        }
                      </div>

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </>
    );

  }

}

export default withTranslation()(withRouter(Step3));