import React, { Component } from 'react';
import {withRouter} from "react-router";
import {Card, CardHeader, Collapse, CardBody} from "reactstrap";
import {withTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import CampaignFilePresentation from "../Files/CampaignFilePresentation";
import DownloadFile from "../DownloadFile";
import "../../../../index.css";

class Step3 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle:false,
      loading:false
    };

    this.toggle = this.toggle.bind(this);
    this.loading = this.loading.bind(this);
    Step3.isCalculationsCompleted = Step3.isCalculationsCompleted.bind(this);
  }

  toggle(ledger){
    this.setState((state)=>({
      toggle:!state.toggle,
      ledgerSelected: ledger
    }))
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  static isCalculationsCompleted(campaign){
    let result = true;
    if(campaign.files != null){
      for (let file of campaign.files){
        if(file.result_dl_url == null){
          result = false;
          break;
        }
      }
    }
    return result;
  }

  render() {

    const {t} = this.props;
    let campaign = this.props.campaign;

    const columns = [
      {
        name: t('ledger'),
        selector: 'ledger',
        cell: row => <div>{t(row.ledger)}</div>,
      },
      {
        name: t('results'),
        selector: 'result_dl_url',
        cell: row => <DownloadFile url={row.result_dl_url} /> ,
        maxWidth:"100px"
      }
    ];

    return (
      <>

        <Card className="mb-1">
          <CardHeader id="headingThree" className="pointer" onClick={() => this.props.toggleAccordion(2)}>
            <div className="d-flex align-items-center font-weight-bold text-darker">
              <div className="mr-auto">{t('results')}</div>
              <div onClick={() => this.props.toggleAccordion(2)} aria-expanded={this.props.accordion} aria-controls="collapseThree">
                {this.props.accordion ? <i className="fa fa-chevron-down" /> : <i className="fa fa-chevron-up" />}
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={this.props.accordion} data-parent="#accordion" id="collapseThree" aria-labelledby="headingThree">
            <CardBody>

              {
                Step3.isCalculationsCompleted(campaign) ? (
                  <div className="d-flex mb-2">
                    <div className="mr-3">
                      <i className={"fa fa-check-circle " + (campaign.status === "DONE" ? "text-kamixColorGreen" : "text-danger" )} />
                    </div>
                    <div>{t('calculations_completed')}</div>
                  </div>
                ) : (
                  <div className="d-flex mb-2">
                    <div className="mr-3">
                      <i className="fa fa-ellipsis-h text-warning" />
                    </div>
                    <div>{t('calculations_in_progress')}</div>
                  </div>
                )
              }

              {
                campaign.files != null && campaign.files.length > 0 &&
                <DataTable
                  className="mb-2"
                  noHeader={true}
                  columns={columns}
                  data={campaign.files}
                  pagination
                  striped
                  responsive
                  expandableRows
                  expandOnRowClicked
                  expandableRowsComponent={<CampaignFilePresentation step={3} recapData={this.props.recapData} />}
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5,10]}
                />
              }

            </CardBody>
          </Collapse>
        </Card>

      </>
    )

  }

}

export default withTranslation()(withRouter(Step3));
