import axios from 'axios';
import {toast} from "react-toastify";
import React from "react";
import i18n from "i18next";
import {store} from "./Security";

export const loaderColor = "#5e923d";
export const strongPassword = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@*?#_\-=+]).{8,20}$/);

export function notification(type,message){
  return (
    toast(
      <div>
        <span className="text-center">{message}</span> <br/>
      </div>,
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type:type
      })
  )
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function nameCapitalized(name){
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function getName(user){
  let name = "";
  if(user != null){
    name = user.last_name != null ? user.last_name.toUpperCase() : "";
    name = user.first_name != null ? nameCapitalized(user.first_name) +" "+ name : "";
    name = (name === "" && user.full_name != null && user.full_name !== "") ? user.full_name : name;
    name = (name === "") ? "--" : name;
  }
  return name;
}

export function getAmountToDisplay(amount){
  return amount != null ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";
}

export function getDateToDisplay(time,unix){

  let date = new Date(unix != null && unix ? time * 1000 : time);
  let result = {};
  let day = date.getDate();
  let month = date.getMonth()+1;
  let year = date.getFullYear();
  let hour = date.toTimeString().split(" ")[0];
  hour = hour.split(":");
  hour = hour[0]+":"+hour[1];

  if(day.toString().length === 1) day = "0" + day;
  if(month.toString().length === 1) month = "0" + month;

  result.day = day;
  result.month = month;
  result.year = year;
  result.hour = hour;
  result.minDate = day+"/"+month+"/"+year;
  result.fullDate = day+"/"+month+"/"+year+", "+hour;
  result.fullDate2 = day+"/"+month+"/"+year+", "+ date.toTimeString().split(" ")[0] + " " + date.toTimeString().split(" ")[1];

  return result;
}

export function getError(data,props){
  let error_code = data.error_code;
  let error = data.error;
  if(error != null && error){
    switch (error_code){
      case 900:
        notification("warning",i18n.t('something_went_wrong_2'));
        break;
      case 901:
        notification("warning",i18n.t('log_back_in'));
        store.remove('userCredentials');
        props.history.push('/login');
        break;
      case 902:
        store.remove('userCredentials');
        props.history.push('/login');
        break;
      case 903:
        notification("warning",i18n.t('error_message_'+error_code));
        store.remove('userCredentials');
        props.history.push('/login');
        break;
      case 904:
        notification("warning",i18n.t('error_message_'+error_code));
        store.remove('userCredentials');
        props.history.push('/login');
        break;
      case 905:
        notification("warning",i18n.t('error_message_'+error_code));
        store.remove('userCredentials');
        props.history.push('/login');
        break;
      case 910:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 911:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 920:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 921:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 922:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 923:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 924:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 925:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 926:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 927:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 928:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 929:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 930:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 931:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 932:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 933:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 934:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 935:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 936:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 937:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 938:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 939:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 940:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 950:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 951:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 952:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 953:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 954:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 955:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 956:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 957:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 958:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 959:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 960:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 961:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 962:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 963:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 964:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 965:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 966:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 967:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      case 968:
        notification("warning",i18n.t('error_message_'+error_code));
        break;
      default:
        break;
    }
  }
}

export default function remoteCallFunction (auth,route,met,queryParam,bodyParam,cancelTok,props,crypto,blob,contentType){
  //--------------------------------------------------------------------
  let default_url = "";
  if(crypto === "btc") default_url = process.env.REACT_APP_URL_BTC;
  if(crypto === "eth") default_url = process.env.REACT_APP_URL_ETH;
  if(crypto === "acc") default_url = process.env.REACT_APP_URL_ACC;
  //--------------------------------------------------------------------
  let headers = {};
  if(auth) headers['x-access-token'] = store.get('userCredentials').token;
  if(contentType != null && contentType) headers['Content-Type'] = 'multipart/form-data; boundary=' + (bodyParam != null && bodyParam._boundary);
  let config = {
    url: default_url + route,
    method:met,
    headers:headers,
    cancelToken:cancelTok
  };
  if(blob != null && blob) config.responseType = "blob";
  if(queryParam != null) config.params = queryParam;
  if(bodyParam != null) config.data = bodyParam;
  //--------------------------------------------------------------------
  return axios.request(config)
    .then(response =>{
      getError(response.data,props);
      return response.data;
  }).catch(err =>{
    if(axios.isCancel(err)) {
    }else{
      if(err.response && err.response.status === 401){
        if(route.includes('login')) notification('warning',i18n.t('incorrect_credentials'));
        store.remove('userCredentials');
        store.set('isConnected', false);
        props.history.push('/login');
      }
      if(err.response && err.response.status === 404) props.history.push('/404');
      if(err.response && err.response.status === 500) props.history.push('/500');
    }
  });
  //--------------------------------------------------------------------
}