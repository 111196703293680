import React, { Component } from 'react';
import {Col, Row, Container, Button, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, loaderColor} from "../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from 'axios';
import {withTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import {CopyToClipboard} from "react-copy-to-clipboard";
import "../../index.css";

class Ethereum extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingTransactions:false,
      loadingMoreTransactions:false,
      transactions:[],
      txSize:10,
      txPage:1,

      loadingBlocks:false,
      loadingMoreBlocks:false,
      blocks:[],
      bSize:10,
      bPage:1,
      
      plainTabs: this.props.match.params.anchor == null || this.props.match.params.anchor === "tx" ? 1 : 2,
      source:axios.CancelToken.source()
    };

    this.toggleNavs = this.toggleNavs.bind(this);
    this.loadingTransactions = this.loadingTransactions.bind(this);
    this.loadingMoreTransactions = this.loadingMoreTransactions.bind(this);
    this.loadingBlocks = this.loadingBlocks.bind(this);
    this.loadingMoreBlocks = this.loadingMoreBlocks.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.getBlocks = this.getBlocks.bind(this);
    this.viewMoreTx = this.viewMoreTx.bind(this);
    this.viewMoreBlocks = this.viewMoreBlocks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  loadingTransactions(){
    this.setState((state)=>({
      loadingTransactions:!state.loadingTransactions
    }))
  }

  loadingMoreTransactions(){
    this.setState((state)=>({
      loadingMoreTransactions:!state.loadingMoreTransactions
    }))
  }

  loadingBlocks(){
    this.setState((state)=>({
      loadingBlocks:!state.loadingBlocks
    }))
  }

  loadingMoreBlocks(){
    this.setState((state)=>({
      loadingMoreBlocks:!state.loadingMoreBlocks
    }))
  }

  async viewMoreTx(){

    await this.setState((state)=>({txPage:state.txPage +1}));
    let oldTransactions = this.state.transactions;
    let cancelToken = this.state.source.token;

    this.loadingMoreTransactions();

    let response = await RemoteCall(
      false,
      "homeTransactions",
      "get",
      {
        page:this.state.txPage,
        limit:this.state.txSize
      },
      null,
      cancelToken,
      this.props,
      'eth'
    );
    this.loadingMoreTransactions();

    if(response != null){
      let transactions = response;
      for(let transaction of transactions){
        transaction.value = transaction.value.toFixed(8);
        transaction.fee = transaction.fee.toFixed(8);
        transaction.date = getDateToDisplay(transaction.date).fullDate;
      }
      this.setState({
        transactions:oldTransactions.concat(transactions)
      })
    }

  }

  async viewMoreBlocks(){

    await this.setState((state)=>({bPage:state.bPage +1}));
    let oldBlocks = this.state.blocks;
    let cancelToken = this.state.source.token;

    this.loadingMoreBlocks();

    let response = await RemoteCall(
      false,
      "homeBlock",
      "get",
      {
        page:this.state.bPage,
        limit:this.state.bSize
      },
      null,
      cancelToken,
      this.props,
      'eth'
    );

    this.loadingMoreBlocks();

    if(response != null){
      if(response.block != null) oldBlocks.block = oldBlocks.block.concat(response.block);
      this.setState({
        blocks:oldBlocks
      })
    }

  }

  async getTransactions(){

    let cancelToken = this.state.source.token;

    this.loadingTransactions();

    let response = await RemoteCall(
      false,
      "homeTransactions",
      "get",
      {
        page:this.state.txPage,
        limit:this.state.txSize
      },
      null,
      cancelToken,
      this.props,
      'eth'
    );

    this.loadingTransactions();

    if(response != null){
      let transactions = response;
      for(let transaction of transactions){
        transaction.value = transaction.value.toFixed(6);
        transaction.fee = transaction.fee.toFixed(6);
        transaction.date = getDateToDisplay(transaction.date).fullDate;
      }
      this.setState({
        transactions:transactions
      })
    }

  }

  async getBlocks(){

    let cancelToken = this.state.source.token;

    this.loadingBlocks();

    let response = await RemoteCall(
      false,
      "homeBlock",
      "get",
      {
        page:this.state.bPage,
        limit:this.state.bSize
      },
      null,
      cancelToken,
      this.props,
      'eth'
    );

    this.loadingBlocks();

    if(response != null){
      this.setState({
        blocks:response
      })
    }

  }

  goTo(txId){
    this.props.history.push('/eth/tx/'+txId);
  }

  componentDidMount() {
    this.getTransactions();
    this.getBlocks();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;

    const txColumns = [
      {
        name:`${t('block_height')}`,
        cell: (row) =>
          <>
            <Link to={"/eth/block/h/"+row.blockNumber}>
              {<div className={classnames("dot2", "bg-"+row.status.toLowerCase())} title={t(row.status)}><i className="fa fa-check invisible"/></div>}
              <div className="link font-weight-bold">{row.blockNumber}</div>
            </Link>
          </>,
        maxWidth:"100px"
      },
      {
        name:'Tx ID',
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.txid}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/eth/tx/"+row.txid}>
              <div className="link font-weight-bold d-md-block d-lg-none">
                {row.txid.substring(0, 2)}...{row.txid.substring(row.txid.length - 2, row.txid.length)}
              </div>
              <div className="link font-weight-bold d-none d-lg-block">
                {row.txid.substring(0, 10)}...{row.txid.substring(row.txid.length - 10, row.txid.length)}
              </div>
            </Link>
          </>,
        minWidth:"130px",
        hide:"sm"
      },
      {
        name: `${t('value')}`,
        cell: (row) => <>{row.value} <span className="small font-weight-500 ml-1">ETH</span></>,
        width:"150px"
      },
      {
        name: `${t('fees')}`,
        cell: (row) => <>{row.fee} <span className="small font-weight-500 ml-1">ETH</span></>,
        width:"150px"
      },
      {
        name: `${t('date')}`,
        selector: 'date',
        width:"145px",
        hide:"md"
      }
    ];

    const bColumns = [
      {
        name:`${t('height')}`,
        cell: (row) =>
          <>
            <Link to={"/eth/block/:"+row.height}>
              <div className={classnames("dot2", "bg-"+row.status.toLowerCase())} title={t(row.status)}><i className="fa fa-check invisible"/></div>
              <div className="link font-weight-bold">{row.height}</div>
            </Link>
          </>,
        maxWidth:"100px"
      },
      {
        name:`${t('blockhash')}`,
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.blockhash}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/eth/block/H/"+row.blockhash}>
              <div className="link font-weight-bold d-md-block d-lg-none">
                {row.blockhash.substring(0, 1)}...{row.blockhash.substring(row.blockhash.length - 7, row.blockhash.length)}
              </div>
              <div className="link font-weight-bold d-none d-lg-block">
                {row.blockhash.substring(0, 1)}...{row.blockhash.substring(25, row.blockhash.length)}
              </div>
            </Link>
          </>,
      },
      {
        name:`${t('nb_tx')}`,
        cell: (row) => <div>{getAmountToDisplay(row.transactionsCount)}</div>,
        width:"80px",
        hide:"md"
      },
      {
        name:`${t('size')}`,
        cell: (row) => <div>{getAmountToDisplay(row.size)} <span className="small font-weight-500 ml-1">{t('bytes')}</span> </div>,
        width:"135px",
      },
      {
        name:`${t('date')}`,
        cell: (row) => <div>{getDateToDisplay(row.timestamp,true).fullDate}</div>,
        hide:"lg",
        width:"145px",
      }
    ];

    return(
      <Container>
        <Row className="justify-content-center my-2">
          <Col>

            <Row className="justify-content-center">
              <Col xs="10" className="nav-wrapper">
                <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.plainTabs === 1}
                      className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                      onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                      href="#pablo"
                      role="tab"
                    >
                      <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('transactions')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.plainTabs === 2}
                      className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                      onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                      href="#pablo"
                      role="tab"
                    >
                      <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('blocks')}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

            <TabContent activeTab={"plainTabs" + this.state.plainTabs}>

              <TabPane tabId="plainTabs1">
                <Row className="justify-content-center">
                  <Col xs="12" sm="11">
                    {
                      this.state.loadingTransactions ? (
                        <div className="d-flex justify-content-center mt-3">
                          <PuffLoader
                            sizeUnit={"px"}
                            size={50}
                            color={loaderColor}
                            loading={this.state.loadingTransactions}
                          />
                        </div>
                      ) : (
                        <div>
                          {
                            this.state.transactions.length !== 0 ? (
                              <div>
                                <DataTable
                                  className="mb-4"
                                  keyField={'transactions_table'}
                                  noHeader={true}
                                  columns={txColumns}
                                  data={this.state.transactions}
                                  dense
                                  striped
                                  responsive
                                  paginationPerPage={this.state.transactions.length}
                                  paginationRowsPerPageOptions={[this.state.transactions.length]}
                                />
                                {
                                  this.state.loadingMoreTransactions ? (
                                    <div className="d-flex justify-content-center mt-3">
                                      <PuffLoader
                                        sizeUnit={"px"}
                                        size={50}
                                        color={loaderColor}
                                        loading={this.state.loadingMoreTransactions}
                                      />
                                    </div>
                                  ) : (
                                    <div className="d-flex justify-content-center mt-3">
                                      <Button color="kamixColorGreen" size="sm" onClick={this.viewMoreTx}>{t('view_more')}</Button>
                                    </div>
                                  )
                                }
                              </div>
                            ) : (
                              <h6 className="text-center">{t('no_transaction_to_display')}</h6>
                            )
                          }
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="plainTabs2">
                <Row className="justify-content-center">
                  <Col xs="12" sm="11">
                    {
                      this.state.loadingBlocks ? (
                        <div className="d-flex justify-content-center mt-3">
                          <PuffLoader
                            sizeUnit={"px"}
                            size={50}
                            color={loaderColor}
                            loading={this.state.loadingBlocks}
                          />
                        </div>
                      ) : (
                        <div>
                          {
                            this.state.blocks != null && this.state.blocks.block != null && this.state.blocks.block.length !== 0 ? (
                              <div>
                                <DataTable
                                  className="mb-4"
                                  keyField={'blocks_table'}
                                  noHeader={true}
                                  columns={bColumns}
                                  data={this.state.blocks.block}
                                  dense
                                  striped
                                  responsive
                                  paginationPerPage={this.state.blocks.block.length}
                                  paginationRowsPerPageOptions={[this.state.blocks.block.length]}
                                />
                                {
                                  this.state.loadingMoreBlocks ? (
                                    <div className="d-flex justify-content-center mt-3">
                                      <PuffLoader
                                        sizeUnit={"px"}
                                        size={50}
                                        color={loaderColor}
                                        loading={this.state.loadingMoreBlocks}
                                      />
                                    </div>
                                  ) : (
                                    <div className="d-flex justify-content-center mt-3">
                                      <Button color="kamixColorGreen" size="sm" onClick={this.viewMoreBlocks}>{t('view_more')}</Button>
                                    </div>
                                  )
                                }
                              </div>
                            ) : (
                              <h6 className="text-center">{t('no_block_to_display')}</h6>
                            )
                          }
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </TabPane>

            </TabContent>

          </Col>
        </Row>


      </Container>
    );

  }

}

export default withTranslation()(withRouter(Ethereum));