import React from "react";
import {Link, withRouter} from "react-router-dom";
import {PuffLoader} from "react-spinners";
import axios from 'axios';
import {Row, Col, Container, Button, Card, CardBody, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Form} from "reactstrap";
import RemoteCall, {loaderColor, notification} from "../../MyFunctions";
import {withTranslation} from "react-i18next";
import LogoKamix from "../../assets/img/kamix_2.png";
import {store} from "../../Security";
import "../../index.css";

class Login extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      loading:false,
      hidden:true,
      emailOrUserName:"",
      password:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.onChangeEmailOrUsername = this.onChangeEmailOrUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.back = this.back.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  hidePassword(){
    this.setState({
      hidden: !this.state.hidden
    })
  }

  onChangePassword(e){
    this.setState({
      password:e.target.value
    })
  }

  onChangeEmailOrUsername(e){
    this.setState({
      emailOrUserName:e.target.value
    })
  }

  back(){
    this.props.history.push('/');
  }

  async signIn(e){

    e.preventDefault();

    const { t } = this.props;
    let emailOrUserName = this.state.emailOrUserName;
    let password = this.state.password;
    let cancelToken = this.state.source.token;

    if(emailOrUserName === "" || password === ""){
      notification("info",'Please, fill in all the fields of the form !');
    }else{

      let params = {};
      params.login = emailOrUserName;
      params.password = password;

      this.loading();

      let response = await RemoteCall(
        false,
        "users/login",
        "post",
        null,
        params,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          let data = {};
          let welcome = t('welcome_back').replace("XXX",response.user != null ? response.user.user_name : "");
          let counter = 45 * 60000;
          data.user = response.user;
          data.token = response.token;
          data.refreshLimit = new Date().getTime() +counter;
          store.set('userCredentials', data);
          store.set('isConnected', true);
          this.props.history.push('/my_account');
          notification('success',welcome);

        }
      }

    }

  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const { t } = this.props;

    return (
      <div className="app">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="6">

              <Card className="cardLogin">
                <CardBody>

                  <Row className="justify-content-center">
                    <Col xs="11" md="10">

                      <div className="text-center mb-4">
                        <img className="logo_kamix" alt="logo kamix" src={LogoKamix}/>
                      </div>

                      <Form onSubmit={this.signIn}>
                        <FormGroup>
                          <Label htmlFor="emailOrUsername" className="mb-0">{t('emailOrUsername')}</Label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText><i className="fa fa-user" /></InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="emailOrUsername"
                              name="emailOrUsername"
                              className="text-dark font-weight-500"
                              type="text"
                              value={this.state.emailOrUserName}
                              onChange={this.onChangeEmailOrUsername}
                              bsSize="sm"
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="password" className="mb-0">{t('password')}</Label>
                          <div className="d-flex">
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="password"
                                name="password"
                                className="text-dark font-weight-500"
                                type={this.state.hidden ? "password" : "text"}
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                bsSize="sm"
                                required
                              />
                            </InputGroup>
                            <Button color="white" size="sm" onClick={this.hidePassword} className="ml-1">
                              {this.state.hidden ? (<i className="fa fa-eye-slash fa-lg"/>) : (<i className="fa fa-eye fa-lg"/>)}
                            </Button>
                          </div>
                        </FormGroup>
                        <FormGroup className="text-center" style={{marginTop:"35px"}}>
                          {
                            this.state.loading ? (
                              <div className="d-flex justify-content-center">
                                <PuffLoader
                                  sizeUnit={"px"}
                                  size={35}
                                  color={loaderColor}
                                  loading={this.state.loading}
                                />
                              </div>
                            ) : (
                              <div>
                                <Button size="sm" color="dark" className="btn-outline-dark mr-2" onClick={this.back}>{t('back')}</Button>
                                <Button size="sm" color="kamixColorGreen" type="submit">{t('log_in')}</Button>
                              </div>
                            )
                          }
                        </FormGroup>
                        <Link to="/register">
                          <div className="registerText">
                            {t('register')} ?
                          </div>
                        </Link>
                      </Form>

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    );

  }

}

export default withTranslation()(withRouter(Login));
