import React, { Component } from 'react';
import {Col, Row, Container, Button, ListGroup, ListGroupItem} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, loaderColor} from "../../MyFunctions";
import axios from 'axios';
import {PuffLoader, ScaleLoader} from "react-spinners";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {withTranslation} from "react-i18next";
import DataTable from 'react-data-table-component';
import AddressPresentation from "./AddressPresentation";
import fileDownload from "js-file-download";
import Graph from "../Gadgets/MyGraph";
import uniqid from "uniqid";
import "../../index.css";

class Address extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address:this.props.match.params.address,
      toggle:false,
      loadingAddressTransactions:false,
      loadingDownload:false,
      loadingViewMore:false,
      transactions:null,
      page: 1,
      size: 10,
      pages:null,
      ins: null,
      outs: null,
      solde: null,
      totale_tx: null,
      data:null,
      crypto: this.props.location.pathname.substring(1,4),
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.loadingAddressInfos = this.loadingAddressInfos.bind(this);
    this.loadingAddressTransactions = this.loadingAddressTransactions.bind(this);
    this.loadingDownload = this.loadingDownload.bind(this);
    this.loadingViewMore = this.loadingViewMore.bind(this);
    this.getAddressInfos = this.getAddressInfos.bind(this);
    this.getAddressTransactions = this.getAddressTransactions.bind(this);
    this.viewMore = this.viewMore.bind(this);
    this.downloadAllAddressTransactions = this.downloadAllAddressTransactions.bind(this);
  }

  toggle(){
    this.setState((state)=>({
      toggle:!state.toggle
    }))
  }

  loadingAddressInfos(){
    this.setState((state)=>({
      loadingAddressInfos:!state.loadingAddressInfos
    }))
  }

  loadingAddressTransactions(){
    this.setState((state)=>({
      loadingAddressTransactions:!state.loadingAddressTransactions
    }))
  }

  loadingDownload(){
    this.setState((state)=>({
      loadingDownload:!state.loadingDownload
    }))
  }

  loadingViewMore(){
    this.setState((state)=>({
      loadingViewMore:!state.loadingViewMore
    }))
  }

  async getAddressInfos(){

    let address = this.state.address;
    let cancelToken = this.state.source.token;

    this.loadingAddressInfos();

    let response = await RemoteCall(
      false,
      "addressinfos",
      "get",
      {search:address},
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingAddressInfos();

    if(response != null){
      this.setState({
        ins: response.ins != null ? response.ins.toFixed(8) +" "+ this.state.crypto.toUpperCase() : null,
        outs: response.outs != null ? response.outs.toFixed(8) +" "+ this.state.crypto.toUpperCase() : null,
        solde: response.solde != null ? response.solde.toFixed(8) +" "+ this.state.crypto.toUpperCase() : null,
        totale_tx: response.totale_tx
      })
    }

  }

  async getAddressTransactions(){

    let address = this.state.address;
    let cancelToken = this.state.source.token;

    this.loadingAddressTransactions();

    let response = await RemoteCall(
      false,
      "transacAddress",
      "get",
      {
        search:address,
        page: this.state.page,
        limit: this.state.size
      },
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingAddressTransactions();

    if(response != null){

      let address = this.state.address;
      let transactions = response.completed;
      let nodes = [];
      let links = [];

      if(transactions!= null){
        //--------------------------------------------------------------------------------------------------------------
        for(let transaction of transactions){
          transaction.date = getDateToDisplay(transaction.date).fullDate;
          transaction.sens = transaction.sens === "deposit" ? "+" : "-";
          transaction.cotation = transaction.cotation != null ? transaction.cotation.toFixed(4) : "";
          transaction.value = (transaction.debit != null && transaction.credit != null) ?
            (transaction.debit + transaction.credit).toFixed(8) +" "+ this.state.crypto.toUpperCase() : "";
          transaction.value_euro = (transaction.debit_euro != null && transaction.credit_euro != null) ?
            (transaction.debit_euro + transaction.credit_euro).toFixed(4) : "";
        }
        //--------------------------------------------------------------------------------------------------------------
        nodes.push(
          {
            id: "root",
            name: address.substring(0,4) +"..."+  address.substring(address.length-4,address.length),
            color: "black",
            val:"3"
          }
        );
        //--------------------------------------------------------------------------------------------------------------
        for(let transaction of transactions){
          let sourceOrDestAddresses = transaction.address;
          for(let el of sourceOrDestAddresses){
            let id = uniqid();
            nodes.push({
              id: id,
              address:el.address,
              coinbase:transaction.coinbase,
              name: transaction.coinbase ? "coinbase" : el.address.substring(0,3) +"..."+  el.address.substring(el.address.length-3,el.address.length),
              color: transaction.sens === "-" ? "#5e923d" : "#5e72e4"
            });
            links.push({
              source: transaction.sens === "-" ? "root" : id,
              target: transaction.sens === "-" ? id : "root",
              display:el.value,
              color:"#1d1d1d"
            });
          }
        }
        //--------------------------------------------------------------------------------------------------------------
      }

      this.setState({
        transactions:transactions,
        pages:response.pages,
        data:{
          nodes:nodes,
          links:links
        }
      })
    }

  }

  async viewMore(){

    await this.setState((state)=>({page:state.page +1}));
    let address = this.state.address;
    let transactions = this.state.transactions;
    let cancelToken = this.state.source.token;

    this.loadingViewMore();

    let response = await RemoteCall(
      false,
      "transacAddress",
      "get",
      {
        search:address,
        page: this.state.page,
        limit: this.state.size
      },
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingViewMore();

    if(response != null){

      let address = this.state.address;
      let addressInfos = response;
      let nodes = [];
      let links = [];

      if(addressInfos.completed != null){
        //--------------------------------------------------------------------------------------------------------------
        for(let transaction of addressInfos.completed){
          transaction.date = getDateToDisplay(transaction.date).fullDate;
          transaction.sens = transaction.sens === "deposit" ? "+" : "-";
          transaction.cotation = transaction.cotation != null ? transaction.cotation.toFixed(4) : "";
          transaction.value = (transaction.debit != null && transaction.credit != null) ?
            (transaction.debit + transaction.credit).toFixed(8) +" "+ this.state.crypto.toUpperCase() : "";
          transaction.value_euro = (transaction.debit_euro != null && transaction.credit_euro != null) ?
            (transaction.debit_euro + transaction.credit_euro).toFixed(4) : "";
        }
        //--------------------------------------------------------------------------------------------------------------
        nodes.push(
          {
            id: "root",
            name: address.substring(0,4) +"..."+  address.substring(address.length-4,address.length),
            color: "black",
            val:"3"
          }
        );
        //--------------------------------------------------------------------------------------------------------------
        transactions = transactions.concat(addressInfos.completed);
        //--------------------------------------------------------------------------------------------------------------
        for(let transaction of transactions){
          let sourceOrDestAddresses = transaction.address;
          for(let el of sourceOrDestAddresses){
            let id = uniqid();
            nodes.push({
              id: id,
              address:el.address,
              coinbase:transaction.coinbase,
              name: transaction.coinbase ? "coinbase" : el.address.substring(0,3) +"..."+  el.address.substring(el.address.length-3,el.address.length),
              color: transaction.sens === "-" ? "#5e923d" : "#5e72e4"
            });
            links.push({
              source: transaction.sens === "-" ? "root" : id,
              target: transaction.sens === "-" ? id : "root",
              display:el.value,
              color:"#1d1d1d"
            });
          }
        }
        //--------------------------------------------------------------------------------------------------------------
      }

      this.setState({
        transactions:transactions,
        data:{
          nodes:nodes,
          links:links
        }
      })
    }

  }

  async downloadAllAddressTransactions(){
    let address = this.state.address;
    let cancelToken = this.state.source.token;
    this.loadingDownload();
    let response = await RemoteCall(
      false,
      "transactionsByAddress",
      "get",
      {search:address},
      null,
      cancelToken,
      this.props,
      this.state.crypto,
      true,
      true
    );
    this.loadingDownload();
    if(response != null){
      let fileName = address+".csv";
      fileDownload(response,fileName);
    }
  }

  componentDidMount() {
    this.getAddressInfos();
    this.getAddressTransactions();
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let address = this.state.address;
    if(nextProps.match != null && nextProps.match.params != null && nextProps.match.params.address !== address){
      await this.setState({
        address:nextProps.match.params.address,
        toggle:false,
        loadingAddressTransactions:false,
        loadingDownload:false,
        loadingViewMore:false,
        transactions:null,
        page: 1,
        size: 10,
        pages:null,
        ins: null,
        outs: null,
        solde: null,
        totale_tx: null,
        data:null,
      });
      this.getAddressInfos();
      this.getAddressTransactions();
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;
    let address = this.state.address;
    let transactions = this.state.transactions;

    const columns = [
      {
        name:'Tx ID',
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.txid}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/"+this.state.crypto+"/tx/"+row.txid}>
              <div className="link font-weight-bold">
                <div className="link font-weight-bold d-md-block d-lg-none">
                  {row.txid.substring(0, 2)}...{row.txid.substring(row.txid.length - 2, row.txid.length)}
                </div>
                <div className="link font-weight-bold d-none d-lg-block">
                  {row.txid.substring(0, 8)}...{row.txid.substring(row.txid.length - 8, row.txid.length)}
                </div>
              </div>
            </Link>
          </>
      },
      {
        name: t('sens'),
        cell: (row) => <div className={row.sens === "+" ? "positive-sens" : "negative-sens"}>{row.sens}</div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        maxWidth:"80px",
        hide:"sm"
      },
      {
        name: t('value_btc'),
        selector: 'value',
        maxWidth:"150px"
      },
      {
        name: t('cotation_btc_eur'),
        selector: 'cotation',
        maxWidth:"150px"
      },
      {
        name: t('date'),
        selector: 'date',
        width:"145px",
        hide:"md"
      }
    ];

    return(
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" sm="11">
            {
              this.state.loadingAddressInfos ? (
                <div className="d-flex justify-content-center mt-3">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loadingAddressInfos}
                  />
                </div>
              ) : (
                <div>

                  <div className="display-4 mb-2">
                    <h2 className="mb-0">{t('address')}</h2>
                    <div className="d-flex align-items-center">
                      <div className="smallText2 mr-2">{address}</div>
                      <CopyToClipboard text={address} onCopy={() => {/*notification("info",t('add_id_copied'))*/}}>
                        <Button color="primary" size="sm" title={t('click_to_copy')}><i className="fa fa-copy"/></Button>
                      </CopyToClipboard>
                    </div>
                  </div>

                  {
                    transactions != null &&
                    <div className="mb-1">
                      {
                        this.state.loadingDownload ? (
                          <div style={{height:'30px'}}>
                            <ScaleLoader
                              sizeUnit={"px"}
                              height={20}
                              width={4}
                              radius={2}
                              margin={2}
                              color={loaderColor}
                              loading={this.state.loadingDownload}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button size="sm" className="btn-outline-kamixColorGreen" onClick={this.downloadAllAddressTransactions}>
                              <span className="text-capitalize">{t('download_txs')}</span> {" "} <i className="fa fa-download"/>
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  }

                  {
                    this.state.data != null &&
                    <Graph data={this.state.data} />
                  }

                  {
                    this.state.ins != null && this.state.outs != null && this.state.solde != null && this.state.totale_tx != null &&
                    <div className="my-5">
                      <ListGroup flush>
                        {
                          this.state.ins != null &&
                          <ListGroupItem className="p-2">
                            <Row>
                              <Col xs="4" className="font-weight-bold">{t('total_in')}</Col>
                              <Col xs="8" className="text-right">{this.state.ins}</Col>
                            </Row>
                          </ListGroupItem>
                        }
                        {
                          this.state.outs != null &&
                          <ListGroupItem className="p-2">
                            <Row>
                              <Col xs="4" className="font-weight-bold">{t('total_out')}</Col>
                              <Col xs="8" className="text-right">{this.state.outs}</Col>
                            </Row>
                          </ListGroupItem>
                        }
                        {
                          this.state.solde != null &&
                          <ListGroupItem className="p-2">
                            <Row>
                              <Col xs="4" className="font-weight-bold">{t('sold')}</Col>
                              <Col xs="8" className="text-right">{this.state.solde}</Col>
                            </Row>
                          </ListGroupItem>
                        }
                        {
                          this.state.totale_tx != null &&
                          <ListGroupItem className="p-2">
                            <Row>
                              <Col xs="4" className="font-weight-bold">{t('nb_tx')}</Col>
                              <Col xs="8" className="text-right">{getAmountToDisplay(this.state.totale_tx)}</Col>
                            </Row>
                          </ListGroupItem>
                        }
                      </ListGroup>
                    </div>
                  }

                  {
                    this.state.loadingAddressTransactions ? (
                      <div className="d-flex justify-content-center my-5">
                        <PuffLoader
                          sizeUnit={"px"}
                          size={30}
                          color={loaderColor}
                          loading={this.state.loadingAddressTransactions}
                        />
                      </div>
                    ) : (
                      <>

                        {
                          transactions != null &&
                          <>

                            <div className="mb-3">
                              <h6 className="font-weight-500 mb-3">{t('transactions')}</h6>
                              <DataTable
                                keyField={'id'}
                                noHeader={true}
                                columns={columns}
                                data={transactions}
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={<AddressPresentation crypto={this.state.crypto}/>}
                                dense
                                striped
                                responsive
                                paginationPerPage={transactions.length}
                                paginationRowsPerPageOptions={[transactions.length]}
                                noDataComponent={<h6 className="text-center my-4">{t('no_data_to_display')}</h6>}
                              />
                            </div>

                            {
                              transactions.length != null && transactions.length !== 0 &&
                              <div className="d-flex justify-content-center mb-3">
                                {
                                  this.state.loadingViewMore ? (
                                    <PuffLoader
                                      sizeUnit={"px"}
                                      size={30}
                                      color={loaderColor}
                                      loading={this.state.loadingViewMore}
                                    />
                                  ) : (
                                    <>
                                      {
                                        this.state.pages != null && this.state.page < this.state.pages &&
                                        <Button color="kamixColorGreen" size="sm" onClick={this.viewMore}>{t('view_more')}</Button>
                                      }
                                    </>
                                  )
                                }
                              </div>
                            }

                          </>
                        }

                        {
                          transactions == null &&
                          this.state.ins == null && this.state.outs == null &&
                          this.state.solde == null && this.state.totale_tx == null &&
                          <h6 className="text-center display-4 mt-4">
                            {t('no_result_found')}
                          </h6>
                        }

                      </>
                    )
                  }

                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    );

  }

}

export default withTranslation()(withRouter(Address));