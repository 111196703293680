import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Button, Modal, Card, CardBody, Form, FormText, Input, InputGroupAddon, InputGroupText, InputGroup} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {loaderColor, notification} from "../../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import Select from "react-select";
import axios from "axios";
import AddressActions from "./AddActions";
import DataTable from "react-data-table-component";
import AddressPresentation2 from "./AddPresentation2";
import {CopyToClipboard} from "react-copy-to-clipboard";
import "../../../../index.css";

class Addresses extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingCreationAddress:false,
      toggleCreateAddress:false,
      user:null,
      address:"",
      blockchain:null,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingCreationAddress = this.loadingCreationAddress.bind(this);
    this.toggleCreateAddress = this.toggleCreateAddress.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeBlockchain = this.onChangeBlockchain.bind(this);

    this.getUser = this.getUser.bind(this);
    this.getUserInformation = this.getUserInformation.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingCreationAddress(){
    this.setState((state)=>({
      loadingCreationAddress: !state.loadingCreationAddress
    }))
  }

  toggleCreateAddress(){
    this.setState((state)=>({
      toggleCreateAddress: !state.toggleCreateAddress,
      address:"",
      blockchain:null,
    }))
  }

  onChangeAddress(e){
    this.setState({
      address:e.target.value
    })
  }

  onChangeBlockchain(e){
    this.setState({
      blockchain:e
    })
  }

  getUser(user){
    this.setState({
      user:user
    })
  }

  async getUserInformation(){

    let cancelToken = this.state.source.token;
    this.loading();

    let response = await RemoteCall(
      true,
      "users/account-info",
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          user:response.user
        })
      }
    }

  }

  async saveAddress(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;

    let address = this.state.address;
    let blockchain = this.state.blockchain;

    if(address === "" || blockchain === null){
      notification("warning",t('fill_fields_form_label'));
    }else{

      let formData = {};
      formData.address = address;
      formData.blockchain = blockchain.value;

      this.loadingCreationAddress();

      let response = await RemoteCall(
        true,
        "users/add-address",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loadingCreationAddress();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          notification("info",t('address_created'));
          this.setState({user:response.user});
          this.setState({toggleCreateAddress:false});
        }
      }

    }

  }

  componentDidMount() {
    this.getUserInformation();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let user = this.state.user;

    const blockchainTypes = [
      { value: "BITCOIN", label: t('BITCOIN') },
      { value: "ETHEREUM", label: t('ETHEREUM') },
    ];

    const columns = [
      {
        name: t('address'),
        selector: 'address',
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.address}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <div>
              <div className="d-md-block d-lg-none">
                {row.address.substring(0, 3)}...{row.address.substring(row.address.length - 3, row.address.length)}
              </div>
              <div className="d-none d-lg-block">{row.address}</div>
            </div>
          </>,
      },
      {
        name: t('blockchain'),
        selector: 'blockchain',
        sortable: true,
        width:"120px",
        hide:"md"
      },
      {
        name:t('actions'),
        cell: (row) => <AddressActions row={row} getUser={this.getUser} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    return (
      <>

        <Row>
          <Col>
            {
              this.state.loading ? (
                <Row className="justify-content-center my-5">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loading }
                  />
                </Row>
              ) : (
                <>
                  {
                    user != null &&
                    <>
                      {
                        user.addresses.length === 0 ? (
                          <div className="d-flex justify-content-center my-5">
                            <Button color="kamixColorGreen" size="sm" onClick={this.toggleCreateAddress}>
                              {t('add_an_address')}
                            </Button>
                          </div>
                        ) : (
                          <Row className="justify-content-center">
                            <Col xs="11">

                              <div className="d-flex justify-content-end mb-2">
                                <Button color="kamixColorGreen" size="sm" onClick={this.getUserInformation} className="mr-2">
                                  <i className="fa fa-refresh fa-lg" />
                                </Button>
                                <Button color="kamixColorGreen" size="sm" className="rounded-circle" onClick={this.toggleCreateAddress}>
                                  <i className="fa fa-plus fa-lg" />
                                </Button>
                              </div>

                              <DataTable
                                className="mt-3"
                                noHeader={true}
                                columns={columns}
                                data={user.addresses}
                                pagination
                                striped
                                responsive
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={<AddressPresentation2 />}
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5,10]}
                              />

                            </Col>
                          </Row>
                        )
                      }
                    </>
                  }
                </>
              )
            }
          </Col>
        </Row>

        {/* Creeation et modification d'une addresse */}

        <Modal size="md" isOpen={this.state.toggleCreateAddress} toggle={this.toggleCreateAddress}>
          <Card className="bg-secondary">
            <CardBody>
              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('add_an_address')}</h6>
              </div>
              <div>
                <Form>
                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('address')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><span className="font-weight-bold">#</span></InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" value={this.state.address} onChange={this.onChangeAddress}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-4">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('blockchain')}</FormText>
                      <Select
                        options={blockchainTypes}
                        value={this.state.blockchain}
                        onChange={this.onChangeBlockchain}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="d-flex justify-content-center">
                {
                  this.state.loadingCreationAddress ? (
                    <PuffLoader
                      sizeUnit={"px"}
                      size={35}
                      color={loaderColor}
                      loading={this.state.loadingCreationAddress}
                    />
                  ) : (
                    <Row>
                      <Button color="danger" size="sm" className="mr-2" onClick={this.toggleCreateAddress}>{t('cancel')}</Button>
                      <Button color="kamixColorGreen" size="sm" onClick={this.saveAddress}>{t('save')}</Button>
                    </Row>
                  )
                }
              </div>
            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(Addresses));
