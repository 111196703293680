import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import DefaultLayout from "./containers/DefaultLayout";
import Login from "./views/Pages/Login";
import Register from "./views/Pages/Registration/Register";
import ScrollToTop from "react-scroll-to-top";
import PageSuccess from "./views/Pages/PageSuccess";
import PageCancellation from "./views/Pages/PageCancellation";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
library.add(fab, faEthereum);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop smooth color="#000" />
      <Switch>
        <Route exact path="/login" name="Login" render={props => <Login {...props}/>} />
        <Route path="/register" name="Register" render={props => <Register {...props}/>} />
        <Route exact path="/success" name="Success" render={props => <PageSuccess {...props}/>} />
        <Route exact path="/cancellation" name="Cancellation" render={props => <PageCancellation {...props}/>} />
        <Route path="/" name="Home" render={props => <DefaultLayout {...props}/> } />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
