import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter} from 'react-router-dom';
import { Container } from 'reactstrap';
import routes from '../routes';
import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";
import SearchBar from "./SearchBar";
import ErrorBoundary from "../views/ErrorBoundary";
import axios from "axios";
import RemoteCall, {notification} from "../MyFunctions";
import {store} from "../Security";
import {withTranslation} from "react-i18next";
import '../index.css';

class DefaultLayout extends Component{

  constructor(props) {
    super(props);

    this.state={
      id:"",
      loading:false,
      source:axios.CancelToken.source()
    };

    this.getId = this.getId.bind(this);
    this.logOut = this.logOut.bind(this);
    this.tick = this.tick.bind(this);
  }

  getId(id){
    this.setState({
      id:id
    })
  }

  async logOut(e){

    e.preventDefault();

    let userCredentials = store.get('userCredentials');
    let user = userCredentials.user;
    let cancelToken = this.state.source.token;

    let response = await RemoteCall(
      true,
      "users/logout",
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        const { t } = this.props;
        notification("success",t('good_bye').replace("XXX",user.user_name));
        store.remove('userCredentials');
        store.set('isConnected', false);
        this.props.history.push('/');
      }

    }

  }

  async tick(){

    let userCredentials = store.get('userCredentials');
    let refreshLimit = userCredentials.refreshLimit;
    let user = userCredentials.user;

    if(new Date().getTime() >= refreshLimit ){

      let response = await RemoteCall(
        true,
        "users/refresh-token",
        "get",
        null,
        null,
        null,
        this.props,
        'acc'
      );

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          let data = {};
          let counter = 45 * 60000;
          data.user = user;
          data.token = response.token;
          data.refreshLimit = new Date().getTime() +counter;
          store.remove('userCredentials');
          store.set('userCredentials', data);
        }
      }

    }

  }

  async componentDidMount(){
    await this.tick();
    this.timerID = setInterval(
      () => this.tick(),
      5*60000
    );
  }

  componentWillUnmount(){
    clearInterval(this.timerID);
  }

  render() {
    let pathname = this.props.location.pathname;
    let displaySearchBar = !(
      pathname.toString().includes("my_account") || pathname.toString().includes("users") ||
      pathname.toString().includes("accounting") || pathname.toString().includes("dashboard")
    );
    return (
      <div className="firstBackground">
        <DefaultHeader logOut={(e)=>this.logOut(e)} />
        <main>
          {displaySearchBar && <SearchBar getId={this.getId} />}
          <Container className={displaySearchBar ? "main" : "mainWithoutSearchBar"}>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <ErrorBoundary>
                        <route.component id={this.state.id} {...props} />
                      </ErrorBoundary>
                    )} />
                ) : (null);
              })}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Container>
        </main>
        <DefaultFooter />
      </div>
    );
  }

}

export default withTranslation()(withRouter(DefaultLayout));