import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, FormText, Label, Button, Input, Form} from "reactstrap";
import {withTranslation} from "react-i18next";
import {notification} from "../../../MyFunctions";
import uniqid from "uniqid";
import Select from "react-select";
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import "../../../index.css";

class FilesSelectorByLedger extends Component {

  constructor(props) {
    super(props);

    this.state = {
      platform:null,
      date:new Date(),
      year:new Date().getFullYear(),
      file:null,
      files:[],
      data:[],
      address:"",
      addresses:[],
      option:"1",
    };

    this.onChangeSingleFile = this.onChangeSingleFile.bind(this);
    this.onChangeMultipleFile = this.onChangeMultipleFile.bind(this);
    this.onChangeMultipleFile2 = this.onChangeMultipleFile2.bind(this);
    this.onChangePlatform = this.onChangePlatform.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.deleteSingleFile = this.deleteSingleFile.bind(this);
    this.deleteMultipleFile = this.deleteMultipleFile.bind(this);
    this.deleteMultipleFile2 = this.deleteMultipleFile2.bind(this);
    this.containingFile = this.containingFile.bind(this);
    this.newFolder = this.newFolder.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
  }

  static inclusion(tableau, value){
    let response = false;
    for( let i = 0; i < tableau.length; i++){
      if(tableau[i].name === value){
        response = true;
        break;
      }
    }
    return response;
  }

  onChangeSingleFile(e){
    let file = e.target.files[0];
    this.setState({file:file});
    this.props.getFile(file)
  }

  onChangeMultipleFile(e){
    let filesUploaded = e.target.files;
    let files = this.state.files;
    for(let file of Array.from(filesUploaded)){
      if(!FilesSelectorByLedger.inclusion(files,file.name)){
        files.push(file);
      }
    }
    this.setState({files:files});
    this.props.getFiles(files)
  }

  onChangeMultipleFile2(id,e){
    let filesUploaded = e.target.files;
    let data = this.state.data;
    for(let element of data){
      if(element.id === id){
        let files = element.content;
        for(let file of Array.from(filesUploaded)){
          if(!FilesSelectorByLedger.inclusion(files,file.name)){
            files.push(file);
          }
        }
        //--------------------------------------
        let textFileCount = 0;
        let csvFileCount = 0;
        for(let file of files){
          if(file.name.includes(".csv")) csvFileCount = csvFileCount + 1;
          if(file.name.includes(".txt")) textFileCount = textFileCount + 1;
        }
        element.isValid = textFileCount === 1 && (csvFileCount <= 5 && csvFileCount >= 1);
        //--------------------------------------
        break;
      }
    }
    this.setState({data:data});
    this.props.getData(data);
  }

  containingFile(name){
    let response = false;
    let files = this.state.files;
    for(let file of Array.from(files)){
      if(file.name.includes(name)){
        response = true;
        break;
      }
    }
    return response;
  }

  onChangePlatform(e){
    this.setState({
      platform:e,
      file:null,
      files:[],
      data:[]
    });
    this.props.getPlatform(e);
  }

  onChangeDate(date){
    this.setState({
      date:date,
      year:date.year()
    });
    this.props.getYear(date.year());
  }

  deleteSingleFile(){
    this.setState({file:null});
    this.props.getFile(null);
  }

  deleteMultipleFile(name){
    let files = this.state.files;
    for( let i = 0; i < files.length; i++){
      if (files[i].name === name) {
        files.splice(i, 1);
      }
    }
    this.setState({files:files});
    this.props.getFiles(files);
  }

  deleteMultipleFile2(name,id){
    let data = this.state.data;
    for(let element of data){
      if(element.id === id){
        let files = element.content;
        for(let i = 0; i < files.length; i++){
          if (files[i].name === name) {
            files.splice(i, 1);
          }
        }
        //--------------------------------------
        let textFileCount = 0;
        let csvFileCount = 0;
        for(let file of files){
          if(file.name.includes(".csv")) csvFileCount = csvFileCount + 1;
          if(file.name.includes(".txt")) textFileCount = textFileCount + 1;
        }
        element.isValid = textFileCount === 1 && (csvFileCount <= 5 && csvFileCount >= 1);
        //--------------------------------------
        break;
      }
    }
    this.setState({data:data});
    this.props.getData(data);
  }

  newFolder(){
    let data = this.state.data;
    data = [...data, {id:uniqid(),isValid:false,content:[]}];
    this.setState({data:data});
    this.props.getData(data);
  }

  deleteFolder(id){
    let data = this.state.data;
    for(let i=0; i<data.length; i++){
      if(data[i].id === id){
        data.splice(i, 1);
        break;
      }
    }
    this.setState({data:data});
    this.props.getData(data);
  }

  onChangeOption(e){
    this.setState({option:e.target.value});
    this.props.getEtherscanOption(e.target.value);
  }

  onChangeAddress(e){
    this.setState({
      address:e.target.value
    })
  }

  addAddress(e){
    e.preventDefault();
    const {t} = this.props;
    let address = this.state.address;
    let addresses = this.state.addresses;
    if(address === ""){
      notification("warning",t('address_cannot_be_null'));
    }else if(addresses.toString().includes(address)){
      notification("warning",t('address_already_present'));
    }else{
      addresses.push(address);
      this.setState({
        addresses: addresses,
        address:""
      });
      this.props.getAddresses(addresses);
    }
  }

  deleteAddress(element){
    let addresses = this.state.addresses;
    let index = -1;
    for(let i=0; i<addresses.length; i++){
      if(addresses[i] === element){
        index = i;
        break;
      }
    }
    if(index !== -1) addresses.splice(index,1);
    this.setState({addresses:addresses});
    this.props.getAddresses(addresses);
  }

  componentDidMount() {
    const {t} = this.props;
    const platformOptions = [
      { value: "kraken", label: t('kraken'), isDisabled: false, type: "csv" },
      { value: "coinbase", label: t('coinbase_2'), isDisabled: true, type: "csv" },
      { value: "multis", label: t('multis'), isDisabled: false, type: "csv" },
      { value: "kamix_explorer", label: t('kamix_explorer_btc'), isDisabled: false, type: "csv" },
      { value: "vault", label: t('vault'), isDisabled: false, type: "csv" },
      { value: "etherscan", label: t('etherscan'), isDisabled: false, type: "zip" },
      { value: "zebitex", label: t('zebitex'), isDisabled: false, type: "zip" },
      { value: "binance", label: t('binance'), isDisabled: true, type: "csv" },
      { value: "bitfinex", label: t('bitfinex'), isDisabled: true, type: "csv" },
      { value: "bitforex", label: t('bitforex'), isDisabled: true, type: "csv" },
      { value: "bittrex", label: t('bittrex'), isDisabled: true, type: "csv" },
      { value: "dovewallet", label: t('dovewallet'), isDisabled: true, type: "csv" },
      { value: "exmo", label: t('exmo'), isDisabled: true, type: "csv" },
      { value: "gate.io", label: t('gate.io'), isDisabled: true, type: "csv" },
      { value: "graviex", label: t('graviex'), isDisabled: true, type: "csv" },
      { value: "hitbtc", label: t('hitbtc'), isDisabled: true, type: "csv" },
      { value: "hotbit", label: t('hotbit'), isDisabled: true, type: "csv" },
      { value: "tradeogre", label: t('tradeogre'), isDisabled: true, type: "csv" },
      { value: "poloniex", label: t('poloniex'), isDisabled: true, type: "csv" },
      { value: "stex", label: t('stex'), isDisabled: true, type: "csv" },
      { value: "kamix_explorer_eth", label: t('kamix_explorer_eth'), isDisabled: true, type: "csv" },
    ];
    let platform = { value: "kraken", label: t('kraken'), isDisabled: false, type: "csv" };
    let ledgerSelected = this.props.ledgerSelected;
    if(ledgerSelected != null){
      for(let el of platformOptions){
        if(el.value === ledgerSelected){
          platform = el;
          break;
        }
      }
    }
    this.onChangePlatform(platform);
  }

  render() {

    const {t} = this.props;
    let ledgerSelected = this.props.ledgerSelected;
    const platformOptions = [
      { value: "kraken", label: t('kraken'), isDisabled: false, type: "csv" },
      { value: "coinbase", label: t('coinbase_2'), isDisabled: true, type: "csv" },
      { value: "multis", label: t('multis'), isDisabled: false, type: "csv" },
      { value: "kamix_explorer", label: t('kamix_explorer_btc'), isDisabled: false, type: "csv" },
      { value: "vault", label: t('vault'), isDisabled: false, type: "csv" },
      { value: "etherscan", label: t('etherscan'), isDisabled: false, type: "zip" },
      { value: "zebitex", label: t('zebitex'), isDisabled: false, type: "zip" },
      { value: "binance", label: t('binance'), isDisabled: true, type: "csv" },
      { value: "bitfinex", label: t('bitfinex'), isDisabled: true, type: "csv" },
      { value: "bitforex", label: t('bitforex'), isDisabled: true, type: "csv" },
      { value: "bittrex", label: t('bittrex'), isDisabled: true, type: "csv" },
      { value: "dovewallet", label: t('dovewallet'), isDisabled: true, type: "csv" },
      { value: "exmo", label: t('exmo'), isDisabled: true, type: "csv" },
      { value: "gate.io", label: t('gate.io'), isDisabled: true, type: "csv" },
      { value: "graviex", label: t('graviex'), isDisabled: true, type: "csv" },
      { value: "hitbtc", label: t('hitbtc'), isDisabled: true, type: "csv" },
      { value: "hotbit", label: t('hotbit'), isDisabled: true, type: "csv" },
      { value: "tradeogre", label: t('tradeogre'), isDisabled: true, type: "csv" },
      { value: "poloniex", label: t('poloniex'), isDisabled: true, type: "csv" },
      { value: "stex", label: t('stex'), isDisabled: true, type: "csv" },
      { value: "kamix_explorer_eth", label: t('kamix_explorer_eth'), isDisabled: true, type: "csv" },
    ];

    return (
      <>

        <Row>
          <Col>

            <Row className="mb-3">
              <Col xs="6" md="4" className="text-darker font-weight-bold">
                <div>
                  <h6 className="smallText font-weight-bold mb-1">{t('file_source_platform')}</h6>
                  <Select
                    value={this.state.platform}
                    onChange={this.onChangePlatform}
                    options={platformOptions}
                    className="font-weight-500 small"
                    isDisabled={this.state.loadingCalculation || ledgerSelected != null}
                  />
                </div>
              </Col>
              <Col xs="6" md="4" className="text-darker font-weight-bold">
                <div>
                  <h6 className="smallText font-weight-bold mb-1">{t('date')}</h6>
                  <Datetime
                    value={this.state.date}
                    timeFormat={false}
                    dateFormat={"YYYY"}
                    onChange={(date) => this.onChangeDate(date)}
                    renderInput={(props)=>{
                      return (
                        <input style={{height:"39px", color:"black"}} {...props} disabled={this.state.loadingCalculation || ledgerSelected != null} />
                      )
                    }}
                  />
                </div>
              </Col>
            </Row>

            {
              this.state.platform != null &&
              <Row>
                <Col className="text-darker font-weight-bold">
                  <div>
                    {
                      this.state.platform.type === "csv" &&
                      <>
                        <FormText className="text-darker font-weight-bold mb-1">
                          {t('upload_csv_file')}
                        </FormText>
                        {
                          this.state.file != null &&
                          <div>
                            <div className="d-flex mb-2">
                              <span className="myFile mr-2">{this.state.file.name}</span>
                              {
                                !this.state.loadingCalculation &&
                                <span className="myFile pointer px-2" onClick={this.deleteSingleFile}>
                                  <i className="fa fa-close" />
                                </span>
                              }
                            </div>
                          </div>
                        }
                        {
                          this.state.file == null &&
                          <div>
                            <input type="file" name="file1" id="file1" className="inputfile" onChange={this.onChangeSingleFile} accept=".csv" />
                            <Label htmlFor="file1">
                              <i className="fa fa-upload" />
                            </Label>
                          </div>
                        }
                      </>
                    }
                    {
                      this.state.platform.value === "zebitex" &&
                      <>
                        <FormText className="text-darker font-weight-bold mb-1">
                          <div>{t('upload_file_for_zebixtex')}</div>
                          <ul>
                            <li className="font-italic">trades.csv</li>
                            <li className="font-italic">wd.csv</li>
                          </ul>
                        </FormText>
                        {
                          this.state.files.map((file,index)=>(
                            <div key={index}>
                              <div className="d-flex mb-2">
                                <span className="myFile mr-2">{file.name}</span>
                                {
                                  !this.state.loadingCalculation &&
                                  <span className="myFile pointer px-2" onClick={(e)=>this.deleteMultipleFile(file.name,e)}>
                                    <i className="fa fa-close" />
                                  </span>
                                }
                              </div>
                            </div>
                          ))
                        }
                        {
                          (this.state.files.length !== 0 )&&
                          (this.state.files.length !== 2 || !this.containingFile("trades") || !this.containingFile("wd"))
                          &&
                          <div className="small text-danger font-italic">
                            <ul>
                              {this.state.files.length !== 2 && <li className="font-italic">{t('two_csv_files_only')}</li>}
                              {!this.containingFile("trades") && <li className="font-italic">{t('missing_file').replace("XXX","trades.csv")}</li>}
                              {!this.containingFile("wd") && <li className="font-italic">{t('missing_file').replace("XXX","wd.csv")}</li>}
                            </ul>
                          </div>
                        }
                        {
                          (!this.containingFile("trades") || !this.containingFile("wd")) &&
                          <div>
                            <input multiple type="file" name="file2" id="file2" className="inputfile" onChange={this.onChangeMultipleFile} accept=".csv" />
                            <Label htmlFor="file2">
                              <i className="fa fa-upload" />
                            </Label>
                          </div>
                        }
                      </>
                    }
                    {
                      this.state.platform.value === "etherscan" &&
                      <>

                        <div>
                          <h6 className="smallText font-weight-bold mb-1">
                            {t('etherscan_option_selector')}
                          </h6>
                          <div className="d-flex mb-3">
                            <div className="custom-control custom-radio mr-3">
                              <input
                                className="custom-control-input" id="customRadio1" name="custom-radio-1" type="radio"
                                value={"1"} checked={this.state.option === "1"} onChange={this.onChangeOption}
                              />
                              <label className="custom-control-label" htmlFor="customRadio1">
                                <span>{t('option_1')}</span>
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                className="custom-control-input" id="customRadio2" name="custom-radio-1" type="radio"
                                value={"2"} checked={this.state.option === "2"} onChange={this.onChangeOption}
                              />
                              <label className="custom-control-label" htmlFor="customRadio2">
                                <span>{t('option_2')}</span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {
                          this.state.option === "1" &&
                          <>
                            <FormText className="text-darker font-weight-bold mb-3">
                              <div>{t('upload_file_for_etherscan')}</div>
                              <ul>
                                <li className="font-italic">{t('upload_file_for_etherscan_1')}</li>
                                <li className="font-italic">{t('upload_file_for_etherscan_2')}</li>
                                <li className="font-italic">
                                  {t('upload_file_for_etherscan_3')}
                                  <ul>
                                    <li className="font-italic">{t('upload_file_for_etherscan_31')}</li>
                                    <li className="font-italic">{t('upload_file_for_etherscan_32')}</li>
                                  </ul>
                                </li>
                              </ul>
                            </FormText>
                            {
                              this.state.data.map((element)=>(
                                <Row key={element.id}>
                                  <Col xs="11" className="mb-3">
                                    <div className={(element.isValid ? "border-success" : "border-danger") + " list-group-item px-4 py-2"}>
                                      <div className="d-flex align-items-end">
                                        <div className="mr-2 mb-1">{t('folder')}</div>
                                        <div>
                                          <input multiple type="file" name={element.id} id={element.id} className="inputfile2" onChange={(e)=>this.onChangeMultipleFile2(element.id,e)} accept=".csv, text/plain" />
                                          <Label htmlFor={element.id}><i className="fa fa-upload" /></Label>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap">
                                        {
                                          element.content.map((file)=>(
                                            <div key={uniqid()} className="mr-3 mb-1">
                                              <div className="d-flex">
                                                <div className="myFile mr-1">{file.name}</div>
                                                <div className="myFile pointer px-2" onClick={(e)=>this.deleteMultipleFile2(file.name,element.id,e)}>
                                                  <i className="fa fa-close" />
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </Col>
                                  <div>
                                    <Button color="danger" size="sm" onClick={(e)=>this.deleteFolder(element.id,e)}>
                                      <i className="fa fa-close" />
                                    </Button>
                                  </div>
                                </Row>
                              ))
                            }
                            <div className="newFolder mt-1" onClick={this.newFolder}>
                              <span className="small mr-2">{t('new_folder')}</span>
                              <i className="fa fa-plus-circle" />
                            </div>
                          </>
                        }

                        {
                          this.state.option === "2" &&
                          <>
                            <FormText className="text-darker font-weight-bold mb-3">
                              <div>{t('provide_eth_addresses')}</div>
                            </FormText>
                            {
                              this.state.addresses.map((element)=>(
                                <div key={uniqid()} className="d-flex mb-1">
                                  <Col xs="10" md="6">
                                    <Input type="text" className="text-dark" value={element} bsSize="sm" readOnly />
                                  </Col>
                                  <Button color="danger" size="sm" onClick={(e) => this.deleteAddress(element,e)}>
                                    <i className="fa fa-close" />
                                  </Button>
                                </div>
                              ))
                            }
                            <Form onSubmit={this.addAddress}>
                              <div className="my-3">
                                <small>{t('address')}</small>
                                <Row className="align-items-center">
                                  <Col xs="10" md="6">
                                    <Input type="text" className="text-dark" value={this.state.address} onChange={this.onChangeAddress} bsSize="sm" />
                                  </Col>
                                  <Button color="primary" size="sm" type="submit">
                                    <i className="fa fa-plus-circle" />
                                  </Button>
                                </Row>
                              </div>
                            </Form>
                          </>
                        }

                      </>
                    }
                  </div>
                </Col>
              </Row>
            }

          </Col>
        </Row>

      </>
    );

  }

}

export default withTranslation()(withRouter(FilesSelectorByLedger));
