import React, { Component } from 'react';
import {withRouter} from "react-router";
import {
  Row, Container, Col, Card, CardBody, Input, FormText, Button,
  InputGroupAddon, InputGroupText, InputGroup, Progress
} from "reactstrap";
import axios from "axios";
import RemoteCall, {loaderColor, notification} from "../../../MyFunctions";
import {withTranslation} from "react-i18next";
import {ScaleLoader} from "react-spinners";
import {store} from "../../../Security";
import countryList from 'react-select-country-list';
import Select from 'react-select';
import PhoneInput  from 'react-phone-input-2';
import {isValidPhoneNumber} from "react-phone-number-input";
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import "../../../index.css";

class Step4 extends Component{

  constructor(props){
    super(props);
    this.options = countryList().getData();

    this.state = {
      options: this.options,
      loading:false,
      phoneNumber: "",
      textElements:[
        {name:"first_name", value:""},
        {name:"last_name", value:""},
        {name:"company_name", value:""},
        {name:"siren", value:""},
        {name:"tva", value:""},
        {name:"address", value:""},
        {name:"zip_code", value:""},
        {name:"city", value:""},
      ],
      country:null,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onchangeTextElement = this.onchangeTextElement.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.stepFour = this.stepFour.bind(this);
    this.skip = this.skip.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  onChangePhoneNumber(value, data, event, formattedValue){
    let resultat = formattedValue.replace(/[\s()/-]/gi, '');
    this.setState({
      phoneNumber:resultat
    })
  }

  onchangeTextElement(e) {
    const value = e.target.value;
    const name = e.target.name;
    let textElements = this.state.textElements;
    for(let elt of textElements){
      if(elt.name === name){
        if(name !== "siren" || value.length <= 9) elt.value = value;
        break;
      }
    }
    this.setState({
      textElements: textElements
    })
  }

  onChangeCountry(country){
    this.setState({
      country:country
    })
  }

  async stepFour(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;
    let userCredentials = store.get('userCredentials');

    let phoneNumber = this.state.phoneNumber;
    let textElements = this.state.textElements;
    let textElementsComplete = true;
    let sirenValid = true;
    let country = this.state.country;

    //-----------------------------------------
    for(let elt of textElements){
      if(elt.value === "" && elt.name !== "tva"){
        textElementsComplete = false;
        break;
      }
    }
    //-----------------------------------------
    for(let elt of textElements){
      if(elt.name === "siren" && (elt.value === "" || elt.value.length !== 9)){
        sirenValid = false;
        break;
      }
    }
    //-----------------------------------------

    if(!textElementsComplete || country === null || phoneNumber === ""){
      notification("warning",t('fill_fields_form_label'));
    }else if(!isValidPhoneNumber(phoneNumber)){
      notification("warning",t('invalid_phone_number'));
    }else if(!sirenValid){
      notification("warning",t('siren_invalid'));
    }else{

      //--------------------------------------
      let fields = [];
      let values = [];
      //--------------------------------------
      for(let elt of textElements){
        fields.push(elt.name);
        values.push(elt.value);
      }
      fields.push('country');
      values.push(country.value);
      fields.push('phone');
      values.push(phoneNumber);
      //--------------------------------------
      let formData = {};
      formData.fields = fields;
      formData.values = values;
      //--------------------------------------

      this.loading();

      let response = await RemoteCall(
        true,
        "users/update-account-x",
        "patch",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          let data = {};
          let welcome = t('welcome').replace("XXX",response.user != null ? response.user.user_name : "");
          data.user = response.user;
          data.token = userCredentials.token;
          data.refreshLimit = userCredentials.refreshLimit;
          await store.remove('userCredentials');
          store.set('userCredentials', data);
          store.set('isConnected', true);
          this.props.history.push('/');
          notification('success',welcome);
        }
      }

    }

  }

  skip(){
    const {t} = this.props;
    let userCredentials = store.get('userCredentials');
    let user = userCredentials.user;
    let welcome = t('welcome').replace("XXX",user != null ? user.user_name : "");
    notification('success',welcome);
    store.set('isConnected', true);
    this.props.history.push('/')
  }

  componentDidMount() {
    let userCredentials = store.get('userCredentials');
    if(userCredentials == null || userCredentials === "" || userCredentials.step3 == null){
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const { t } = this.props;
    let textElements = this.state.textElements;

    return(
      <>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="7">

              <Card className="cardLogin">
                <CardBody>

                  <Row className="justify-content-center mb-2">
                    <Col xs="10" md="8">
                      <h5 className="font-weight-500 text-center mb-1">{t('step_label')} 4 / 4</h5>
                      <Progress animated color="kamixColorGreen" value="100" />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs="11" md="10">

                      {
                        textElements.map((textElt,index)=>(
                          <div key={index} className="mb-3">
                            <FormText className="mb-1">{t(textElt.name)}</FormText>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="fa fa-hashtag" /></InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name={textElt.name}
                                className="text-dark font-weight-500"
                                type={textElt.name === "siren" ? "number" : "text"}
                                value={textElt.value}
                                onChange={this.onchangeTextElement}
                                bsSize="sm"
                                required
                              />
                            </InputGroup>
                          </div>
                        ))
                      }

                      <div className="mb-3">
                        <FormText className="mb-1">{t('phone_number')}</FormText>
                        <div>
                          <PhoneInput
                            country={'fr'}
                            preferredCountries={['fr']}
                            value={this.state.phoneNumber}
                            onChange={(value, data, event, formattedValue) => this.onChangePhoneNumber(value, data, event, formattedValue)}
                            enableSearch={true}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <FormText className="mb-1">{t('country')}</FormText>
                        <Select
                          options={this.state.options}
                          value={this.state.country}
                          onChange={this.onChangeCountry}
                        />
                      </div>

                      <div className="d-flex justify-content-center" style={{marginTop:"35px"}}>
                        {
                          this.state.loading ? (
                            <ScaleLoader
                              sizeUnit={"px"}
                              height={25}
                              width={4}
                              radius={2}
                              margin={2}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          ) : (
                            <>
                              <Button size="sm" className="btn-outline-dark" onClick={this.skip}>{t('skip_this_step')}</Button>
                              <Button color="kamixColorGreen" size="sm" onClick={this.stepFour}>{t('save')}</Button>
                            </>
                          )
                        }
                      </div>

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </>
    );

  }

}

export default withTranslation()(withRouter(Step4));