import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {Container, Row, Col} from "reactstrap";
import {withTranslation} from "react-i18next";

class DefaultFooter extends Component {
  render() {
    const {t} = this.props;
    return (
      <>
        <footer className="myFooter">
          <Container>
            <Row>
              <Col xs="12" sm="6" className="mb-3">
                <h3 className="text-white font-weight-bold">{t('app_name')}</h3>
                <div className="smallText">
                  © {new Date().getFullYear()} {" "}
                  <a href="https://www.kamix.fr" target="_blank" rel="noopener noreferrer" className="kamixLink">Kamix SAS</a>. {" "}
                  {t('all_rights_reserved')}
                </div>
              </Col>
              <Col xs="12" sm="6" className="text-sm-right">
                <h5 className="text-white font-weight-bold">{t('contact_us')}</h5>
                <div className="smallText">
                  <a href="https://twitter.com/KamixApp" target="_blank" rel="noopener noreferrer" className="kamixLink">
                    <i className="fa fa-twitter-square fa-2x myBadge" />
                  </a>
                  <a href="https://www.facebook.com/KamixApp/" target="_blank" rel="noopener noreferrer" className="kamixLink">
                    <i className="fa fa-facebook-square fa-2x myBadge" />
                  </a>
                  <a href="https://www.instagram.com/kamixapp/" target="_blank" rel="noopener noreferrer" className="kamixLink">
                    <i className="fa fa-instagram fa-2x myBadge" />
                  </a>
                  <a href="https://www.linkedin.com/company/kamixapp/" target="_blank" rel="noopener noreferrer" className="kamixLink">
                    <i className="fa fa-linkedin-square fa-2x myBadge mr-0" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default withTranslation()(withRouter(DefaultFooter));
