import React, { Component } from 'react';
import {Row, Button, Modal, ModalBody,} from "reactstrap";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {withTranslation} from "react-i18next";
import RemoteCall, {notification} from "../../../../MyFunctions";

class CampaignFileActions extends Component{

  constructor(props){
    super(props);

    this.state = {
      toggleDangerDelete:false,
      loading:false,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.toggleDangerDelete = this.toggleDangerDelete.bind(this);
    this.delete = this.delete.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  toggleDangerDelete(){
    this.setState({
      toggleDangerDelete: !this.state.toggleDangerDelete,
    });
  }

  async delete(){

    const {t} = this.props;
    let cancelToken = this.state.source.token;

    this.loading();

    let response = await RemoteCall(
      true,
      "campaigns/files/"+this.props.campaign_id,
      "delete",
      {ledger:this.props.row.ledger},
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("success",t('file_deleted'));
        this.toggleDangerDelete();
        this.props.getCampaign(response.campaign);
      }
    }

  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const { t } = this.props;

    return (
      <>

        <Button color="danger" size="sm" onClick={this.toggleDangerDelete}>
          <i className="fa fa-trash" />
        </Button>

        <Modal isOpen={this.state.toggleDangerDelete} toggle={this.toggleDangerDelete}>
          <ModalBody>
            <h6 className="text-center mb-2">{t('warning_delete_file')}</h6>
            <div className="d-flex justify-content-center">
              {
                this.state.loading ? (
                  <PuffLoader
                    sizeUnit={"px"}
                    size={35}
                    color={'#f5365c'}
                    loading={this.state.loading}
                  />
                ) : (
                  <Row>
                    <Button color="default" size="sm" onClick={this.toggleDangerDelete}>{t('cancel')}</Button>
                    <Button size="sm" className="btn-outline-danger" onClick={this.delete}>{t('delete')}</Button>
                  </Row>
                )
              }
            </div>
          </ModalBody>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(CampaignFileActions));
