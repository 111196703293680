import React, { Component } from 'react';
import {Row, Col, ListGroupItem, FormText, Badge, Table} from "reactstrap";
import {withTranslation} from "react-i18next";
import DownloadFile from "../DownloadFile";
import Select from "react-select";
import {getAmountToDisplay} from "../../../../MyFunctions";
import "../../../../index.css";

import { Chart as ChartJS, registerables } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
ChartJS.register(...registerables);

class CampaignFilePresentation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      method:{ value: 'fifo', label: 'FIFO' },
      pieDataAndConfigs:null,
      barDataAndConfigs:null,
      data:[],
      fifo_stock_valuation:0,
      cump_stock_valuation:0,
      fifo_capital_gain:0,
      cump_capital_gain:0,
    };

    this.onChangeMethod = this.onChangeMethod.bind(this);
    this.getPieAndBarDataAndConfigs = this.getPieAndBarDataAndConfigs.bind(this);
  }

  async onChangeMethod(e){
    await this.setState({method:e});
    this.getPieAndBarDataAndConfigs();
  }

  getPieAndBarDataAndConfigs(){
    let recapData = this.props.recapData;
    let cump_stock_valuation = 0;
    let fifo_stock_valuation = 0;
    let fifo_capital_gain = 0;
    let cump_capital_gain = 0;
    let method = this.state.method.value;
    let data = this.props.data;
    let dataToUse = null;
    for(let element of recapData){
      if(element.ledger === data.ledger){
        dataToUse = element.data;
        cump_stock_valuation = element.cump_stock_valuation;
        fifo_stock_valuation = element.fifo_stock_valuation;
        fifo_capital_gain = element.fifo_capital_gain;
        cump_capital_gain = element.cump_capital_gain;
        break;
      }
    }
    //---------------------------------------------------------
    let pieDataAndConfigs = {};
    let barDataAndConfigs = {};
    let labels = [];
    let pie_datasets_data = [];
    let bar_datasets_data = [];
    let datasets_backgroundColor = [];
    let datasets_borderColor = [];
    let pie_datasets = {};
    let bar_datasets = {};
    //---------------------------------------------------------
    for(let element of dataToUse){
      labels.push(element[0]);

      if(method === "fifo"){
        pie_datasets_data.push(element[1].fifo_sdr);
        bar_datasets_data.push(element[1].fifo_spv);
      }else{
        pie_datasets_data.push(element[1].cump_sdr);
        bar_datasets_data.push(element[1].cump_spv);
      }

      let x = Math.floor(Math.random() * 200);
      let y = Math.floor(Math.random() * 200);
      let z = Math.floor(Math.random() * 200);
      datasets_backgroundColor.push('rgba('+x+','+y+','+z+',0.2)');
      datasets_borderColor.push('rgba('+x+','+y+','+z+',1)');
    }
    //---------------------------------------------------------
    pie_datasets.label = '';
    bar_datasets.label = '';
    pie_datasets.data = pie_datasets_data;
    bar_datasets.data = bar_datasets_data;
    pie_datasets.backgroundColor = datasets_backgroundColor;
    bar_datasets.backgroundColor = datasets_backgroundColor;
    pie_datasets.borderColor = datasets_borderColor;
    bar_datasets.borderColor = datasets_borderColor;
    pie_datasets.borderWidth = 1;
    bar_datasets.borderWidth = 1;
    pie_datasets = [pie_datasets];
    bar_datasets = [bar_datasets];
    //---------------------------------------------------------
    pieDataAndConfigs.labels = labels;
    barDataAndConfigs.labels = labels;
    pieDataAndConfigs.datasets = pie_datasets;
    barDataAndConfigs.datasets = bar_datasets;
    //---------------------------------------------------------
    this.setState({
      pieDataAndConfigs:pieDataAndConfigs,
      barDataAndConfigs:barDataAndConfigs,
      data:dataToUse,
      fifo_stock_valuation:fifo_stock_valuation,
      cump_stock_valuation:cump_stock_valuation,
      fifo_capital_gain:fifo_capital_gain,
      cump_capital_gain:cump_capital_gain
    })
  }

  componentDidMount() {
    if(this.props.recapData != null) this.getPieAndBarDataAndConfigs();
  }

  render() {

    const {t} = this.props;
    let data = this.props.data;
    let dataToUse = this.state.data;
    let step = this.props.step;
    let pieDataAndConfigs = this.state.pieDataAndConfigs;
    let barDataAndConfigs = this.state.barDataAndConfigs;
    let fifo_stock_valuation = this.state.fifo_stock_valuation;
    let cump_stock_valuation = this.state.cump_stock_valuation;
    let fifo_capital_gain = this.state.fifo_capital_gain;
    let cump_capital_gain = this.state.cump_capital_gain;
    let method = this.state.method;

    const methodOptions = [
      { value: 'fifo', label: 'FIFO' },
      { value: 'cump', label: 'CUMP' }
    ];

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <div>
        <Row className="justify-content-center my-3">
          <Col xs="12">

            <Row className="justify-content-center">

              <Col xs="12" lg="6" className="mb-3 mb-lg-0">
                <ListGroupItem>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('ledger')}</small>
                    <FormText color="default" className="overflowWrap">
                      {data.ledger}
                    </FormText>
                  </div>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('ignore')}</small>
                    <FormText color="default" className={data.ignore ? "text-danger" : "text-success"}>
                      {data.ignore.toString().toUpperCase()}
                    </FormText>
                  </div>
                  {
                    step > 1 &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('enriched_file')} 1</small>
                      <DownloadFile url={data.es1_dl_url} />
                    </div>
                  }
                  {
                    step > 1 &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('enriched_file')} 2</small>
                      <DownloadFile url={data.es2_dl_url} />
                    </div>
                  }
                  {
                    step === 3 &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('results')} </small>
                      <DownloadFile url={data.result_dl_url} />
                    </div>
                  }
                </ListGroupItem>
              </Col>

              <Col xs="12" lg="6">
                <ListGroupItem>
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('source_type')}</small>
                    <FormText color="default" className="overflowWrap">
                      {t(data.source_type)}
                    </FormText>
                  </div>
                  {
                    data.source_metadata != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('size')}</small>
                      <h5>
                        <Badge color="primary" className="text-dark">
                          {data.source_metadata.file_size.toFixed(4)} Mo
                        </Badge>
                      </h5>
                    </div>
                  }
                  {
                    data.source_metadata != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('nb_lines')}</small>
                      <h5>
                        <Badge color="primary" className="text-dark">
                          {data.source_metadata.nb_lines}
                        </Badge>
                      </h5>
                    </div>
                  }
                  {
                    data.source_metadata != null &&
                    <div className="mb-2">
                      <small className="font-weight-bold">{t('separator')}</small>
                      <h5>
                        <Badge color="primary" className="text-dark">
                          {data.source_metadata.separator}
                        </Badge>
                      </h5>
                    </div>
                  }
                </ListGroupItem>
              </Col>

              {
                this.props.recapData != null && this.props.recapData !== [] &&
                <Col xs="12" className="mt-3">
                  <ListGroupItem>

                    <Row className="mb-4">
                      <Col xs="12" lg="6">
                        <div className="mb-2 mb-lg-0">
                          <div className="d-flex">
                            <FormText className="text-darker font-weight-700 mr-3">{t('valuation_in_stock').replace("XXXX","FIFO")}</FormText>
                            <div className="text-darker font-weight-700">{getAmountToDisplay(fifo_stock_valuation.toFixed(2))}</div>
                          </div>
                          <div className="d-flex d-lg-none">
                            <FormText className="text-darker font-weight-700 mr-3">{t('capital_gain_of')}</FormText>
                            <div className="text-darker font-weight-700">{getAmountToDisplay(fifo_capital_gain.toFixed(2))}</div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <FormText className="text-darker font-weight-700 mr-3">{t('valuation_in_stock').replace("XXXX","CUMP")}</FormText>
                            <div className="text-darker font-weight-700">{getAmountToDisplay(cump_stock_valuation.toFixed(2))}</div>
                          </div>
                          <div className="d-flex d-lg-none">
                            <FormText className="text-darker font-weight-700 mr-3">{t('capital_gain_of')}</FormText>
                            <div className="text-darker font-weight-700">{getAmountToDisplay(cump_capital_gain.toFixed(2))}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" lg="6">
                        <div className="d-none d-lg-flex">
                          <FormText className="text-darker font-weight-700 mr-3">{t('capital_gain_of')}</FormText>
                          <div className="text-darker font-weight-700">{getAmountToDisplay(fifo_capital_gain.toFixed(2))}</div>
                        </div>
                        <div className="d-none d-lg-flex">
                          <FormText className="text-darker font-weight-700 mr-3">{t('capital_gain_of')}</FormText>
                          <div className="text-darker font-weight-700">{getAmountToDisplay(cump_capital_gain.toFixed(2))}</div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col xs="12" lg="6">
                        <div className="d-flex">
                          <FormText className="text-darker font-weight-700 mr-3">{t('list_of_digital_assets')}</FormText>
                          <div className="text-darker font-weight-700">{dataToUse.length}</div>
                        </div>
                      </Col>
                    </Row>

                    <FormText className="text-darker font-weight-700">{t('valuation_method')}</FormText>
                    <Row className="mb-4">
                      <Col xs="6" md="4">
                        <Select
                          value={this.state.method}
                          onChange={this.onChangeMethod}
                          options={methodOptions}
                          styles={customStyles}
                          className="font-weight-500 small"
                        />
                      </Col>
                    </Row>

                    <Row className="justify-content-center">
                      <Col xs="12" md="6" className="mb-3 mb-md-0">
                        <FormText className="text-darker font-weight-700 text-center mb-2">{t('stock_of_digital_assets')}</FormText>
                        {
                          pieDataAndConfigs != null &&
                          <Pie
                            data={pieDataAndConfigs}
                            style={{maxHeight:"165px"}}
                            options={
                              {
                                plugins:{
                                  legend:{
                                    position:'left'
                                  }
                                }
                              }
                            }
                          />
                        }
                      </Col>
                      <Col xs="12" md="6" style={{maxHeight:"250px"}}>
                        <FormText className="text-darker font-weight-700 text-center mb-2">{t('capital_gains')}</FormText>
                        {
                          barDataAndConfigs != null &&
                          <Bar
                            data={barDataAndConfigs}
                            style={{maxHeight:"230px"}}
                            options={
                              {
                                plugins:{
                                  legend:{
                                    display:false,
                                  }
                                }
                              }
                            }
                          />
                        }
                      </Col>
                    </Row>

                    <Row className="justify-content-center mt-5">
                      <Col xs="12" lg="11">
                        <Table striped responsive size="sm" className="recapTable">
                          <thead>
                          <tr>
                            <th>{t('assets')}</th>
                            <th className="text-right">{t('stock') + " (" + method.label + ")"}</th>
                            <th className="text-right">{t('valuation') + " (" + method.label + ")"}</th>
                            <th className="text-right">{t('capital_gain') + " (" + method.label + ")"}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            dataToUse.map((element,index)=>(
                              <tr key={index}>
                                <td className="font-weight-500">{element[0]}</td>
                                <td className="text-right">{getAmountToDisplay((method.value === "fifo" ? element[1].fifo_sdr : element[1].cump_sdr).toFixed(2))}</td>
                                <td className="text-right">{getAmountToDisplay((method.value === "fifo" ? element[1].fifo_svdr : element[1].cump_svdr).toFixed(2))}</td>
                                <td className="text-right">{getAmountToDisplay((method.value === "fifo" ? element[1].fifo_spv : element[1].cump_spv).toFixed(2))}</td>
                              </tr>
                            ))
                          }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>

                  </ListGroupItem>
                </Col>
              }

            </Row>

          </Col>
        </Row>
      </div>
    )

  }

}

export default withTranslation()(CampaignFilePresentation);
