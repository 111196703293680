import React, { Component } from 'react';
import {Row, Col, ListGroupItem, FormText, Badge, Collapse} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getDateToDisplay, getName} from "../../../../MyFunctions";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import countryList from "react-select-country-list";
import FlagIcon from "../../../../FlagIcon";
import "../../../../index.css";

class UserPresentation extends Component {

  constructor(props) {
    super(props);
    this.state ={
      toggleCollapse:false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(){
    this.setState((state)=>({
      toggleCollapse:!state.toggleCollapse
    }))
  }

  render() {

    const {t} = this.props;
    let data = this.props.data;

    return (
      <Row className="justify-content-center my-3">
        <Col xs="11">
          <Row className="justify-content-center">

            <Col xs="12" lg="6" className="mb-3 mb-lg-0">
              <ListGroupItem>
                <div className="mb-2">
                  <small className="font-weight-bold">{t('ID')}</small>
                  <FormText color="default" className="overflowWrap">
                    {data.user_id}
                  </FormText>
                </div>
                <div className="mb-2">
                  <small className="font-weight-bold">{t('username')}</small>
                  <FormText color="default" className="overflowWrap">
                    {data.user_name}
                  </FormText>
                </div>
                <div className="mb-2">
                  <small className="font-weight-bold">{t('complete_name')}</small>
                  <FormText color="default" className="overflowWrap">
                    {getName(data)}
                  </FormText>
                </div>
                {
                  data.email != null &&
                  <div>
                    <small className="font-weight-bold">{t('email')}</small>
                    <h5>
                      <Badge color={(data.email_verified != null && data.email_verified) ? "kamixColorGreen" : "danger"} className="text-lowercase overflowWrap">
                        {data.email}
                      </Badge>
                    </h5>
                  </div>
                }
                {
                  data.phone != null &&
                  <div>
                    <small className="font-weight-bold">{t('email')}</small>
                    <h5>
                      <Badge color={(data.phone_verified != null && data.phone_verified) ? "kamixColorGreen" : "danger"}>
                        {formatPhoneNumberIntl(""+data.phone)}
                      </Badge>
                    </h5>
                  </div>
                }
                <div className="mb-2">
                  <small className="font-weight-bold">{t('role')}</small>
                  <FormText color="default" className="overflowWrap">
                    {data.role}
                  </FormText>
                </div>
                {/*
                <div>
                  <small className="font-weight-bold">{t('deleted_account')}</small>
                  <h5>
                    <Badge color={data.deleted_account ? "danger" : "kamixColorGreen"}>
                      {data.deleted_account.toString().toUpperCase()}
                    </Badge>
                  </h5>
                </div>
                */}
                {
                  data.deleted_account != null && data.deleted_account && data.account_deletion_date != null &&
                  <div>
                    <small className="font-weight-bold">{t('account_deletion_date')}</small>
                    <FormText color="default" className="overflowWrap">
                      {getDateToDisplay(data.account_deletion_date).fullDate}
                    </FormText>
                  </div>
                }
              </ListGroupItem>
            </Col>

            <Col xs="12" lg="6">
              <ListGroupItem>
                {
                  data.addresses != null &&
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('addresses')}</small>
                    <h5>
                      <Badge color="primary">
                        {data.addresses.length}
                      </Badge>
                    </h5>
                  </div>
                }
                {
                  data.account_status != null &&
                  <div className="mb-2">
                    <small className="font-weight-bold">{t('account_status')}</small>
                    <FormText color="default" className="text-capitalize overflowWrap">
                      {t(data.account_status)}
                    </FormText>
                  </div>
                }
                <div className="mb-3">
                  <small className="font-weight-bold">{t('features_allowed')}</small>
                  <h5>
                    <Badge color={(data.features_allowed != null && data.features_allowed) ? "kamixColorGreen" : "danger"}>
                      {(data.features_allowed != null && data.features_allowed) ? t('yes') : t('no')}
                    </Badge>
                  </h5>
                </div>
                {
                  data.role != null && data.role.toLowerCase() === "user" &&
                  <>
                    <div className="d-flex align-items-center mb-1">
                      <h5 className="mr-3">
                        <Badge color="primary" className="text-capitalize">
                          <small className="font-weight-bold">{t('other_information')}</small>
                        </Badge>
                      </h5>
                      <h5 onClick={this.toggleCollapse}>
                        <Badge color="primary" className="pointer">
                          <small className="font-weight-bold">
                            {this.state.toggleCollapse ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
                          </small>
                        </Badge>
                      </h5>
                    </div>
                    <Collapse isOpen={this.state.toggleCollapse}>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('company_name')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.company_name != null ? data.company_name : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('siren')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.siren != null ? data.siren : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('tva')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.tva != null ? data.tva : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('address')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.address != null ? data.address : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('zip_code')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.zip_code != null ? data.zip_code : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('city')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.city != null ? data.city : "---"}
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('country')}</small>
                        <FormText color="default" className="overflowWrap">
                          {
                            data.country != null ? (
                              <>
                                {data.country.length === 2 && <FlagIcon code={data.country.toLowerCase()} className="mr-2" />}
                                {data.country.length === 2 ? countryList().getLabel(data.country) : data.country}
                              </>
                            ) : (
                              <>---</>
                            )
                          }
                        </FormText>
                      </div>
                      <div className="mb-2">
                        <small className="font-weight-bold">{t('registration_date')}</small>
                        <FormText color="default" className="overflowWrap">
                          {data.registration_date != null ? getDateToDisplay(data.registration_date).fullDate : "---"}
                        </FormText>
                      </div>
                    </Collapse>
                  </>
                }
              </ListGroupItem>
            </Col>

          </Row>
        </Col>
      </Row>
    )

  }

}

export default withTranslation()(UserPresentation);
