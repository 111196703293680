import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import {withTranslation} from "react-i18next";

class Page500 extends Component {
  render() {
    const {t} = this.props;
    return (
      <Row className="justify-content-center mt-3">
        <Col>
          <div className="text-center">
            <div className="h1 display-1 mb-2">
              <i className="fa fa-exclamation fa-lg"/>
              <i className="fa fa-exclamation fa-lg"/>
            </div>
            <h6 className="display-4 mb-0">{t('something_went_wrong')}</h6>
            <h6 className="display-4">{t('try_again_later')}</h6>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(Page500);
