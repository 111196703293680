import React, { Component } from 'react';
import {Col, Row, Container, Button, ListGroup, ListGroupItem, Collapse} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, loaderColor} from "../../MyFunctions";
import axios from 'axios';
import {PuffLoader} from "react-spinners";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {withTranslation} from "react-i18next";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import Graph from "../Gadgets/MyGraph";
import "../../index.css";

class Block extends Component {

  constructor(props) {
    super(props);

    this.state = {
      type:this.props.match.params.type,
      heightOrHash:this.props.match.params.heightOrHash,
      toggle:false,
      toggleCollapse:false,
      loadingBlockInfos:false,
      loadingBlockVisualisation:false,
      loadingBlockTransactions:false,
      loadingViewMore:false,
      blockInfos:null,
      blockVisualisation:null,
      blockTransactions:null,
      page:1,
      limit:10,
      data:null,
      crypto: this.props.location.pathname.substring(1,4),
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.loadingBlockInfos = this.loadingBlockInfos.bind(this);
    this.loadingBlockVisualisation = this.loadingBlockVisualisation.bind(this);
    this.loadingBlockTransactions = this.loadingBlockTransactions.bind(this);
    this.loadingViewMore = this.loadingViewMore.bind(this);
    this.getBlockInfos = this.getBlockInfos.bind(this);
    this.getBlockVisualisation = this.getBlockVisualisation.bind(this);
    this.getBlockTransactions = this.getBlockTransactions.bind(this);
    this.viewMore = this.viewMore.bind(this);
  }

  toggle(){
    this.setState((state)=>({
      toggle:!state.toggle
    }))
  }

  toggleCollapse(){
    this.setState((state)=>({
      toggleCollapse:!state.toggleCollapse
    }))
  }

  loadingBlockInfos(){
    this.setState((state)=>({
      loadingBlockInfos:!state.loadingBlockInfos
    }))
  }

  loadingBlockVisualisation(){
    this.setState((state)=>({
      loadingBlockVisualisation:!state.loadingBlockVisualisation
    }))
  }

  loadingBlockTransactions(){
    this.setState((state)=>({
      loadingBlockTransactions:!state.loadingBlockTransactions
    }))
  }

  loadingViewMore(){
    this.setState((state)=>({
      loadingViewMore:!state.loadingViewMore
    }))
  }

  async getBlockInfos(){

    let type = this.state.type;
    let heightOrHash = this.state.heightOrHash;
    let cancelToken = this.state.source.token;

    this.loadingBlockInfos();

    let queryParams = {};
    queryParams[type === "h" ? 'height' : 'blockhash'] = heightOrHash;

    let response = await RemoteCall(
      false,
      "getBlockInfos",
      "get",
      queryParams,
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingBlockInfos();

    if(response != null){
      this.setState({
        blockInfos:response
      })
    }

  }

  async getBlockVisualisation(){

    let type = this.state.type;
    let heightOrHash = this.state.heightOrHash;
    let cancelToken = this.state.source.token;

    this.loadingBlockVisualisation();

    let queryParams = {};
    queryParams[type === "h" ? 'height' : 'blockhash'] = heightOrHash;

    let response = await RemoteCall(
      false,
      "getBlockVisual",
      "get",
      queryParams,
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingBlockVisualisation();

    if(response != null){

      let blocks = response;
      let heightOrHash = this.state.heightOrHash;
      let type = this.state.type;
      let nodes = [];
      let links = [];


      for(let i=0; i<blocks.length-1; i++){

        let block = blocks[i];
        let nextBlock = blocks[i+1];


        if(block != null && block !== 0){

          let nextBlockIsMain = (nextBlock == null || nextBlock === 0) ? false :
            (type === "h" && "" + nextBlock.height === heightOrHash) || (type === "H" && nextBlock.blockhash === heightOrHash);

          if((type === "h" && "" + block.height === heightOrHash) || (type === "H" && block.blockhash === heightOrHash)){

            nodes.push(
              {
                id: "root",
                name: "" + block.height ,
                color: "black",
              }
            );
            if(nextBlock != null && nextBlock !== 0){
              links.push({
                source: "root",
                target: nextBlockIsMain ? "root" : nextBlock.blockhash,
                display:"",
                color:"#1d1d1d"
              })
            }

          }else{

            nodes.push({
              id: block.blockhash,
              address: "" + block.height,
              name: "" + block.height,
              color: "#5e923d"
            });
            if(nextBlock != null && nextBlock !== 0){
              links.push({
                source: block.blockhash,
                target: nextBlockIsMain ? "root" : nextBlock.blockhash,
                display:"",
                color:"#1d1d1d"
              })
            }

          }

        }

      }

      let lastBlock = blocks[blocks.length-1];

      if(lastBlock != null && lastBlock !== 0){

        if((type === "h" && "" + lastBlock.height === heightOrHash) || (type === "H" && lastBlock.blockhash === heightOrHash)){

          nodes.push(
            {
              id: "root",
              name: "" + lastBlock.height ,
              color: "black"
            }
          )

        }else{

          nodes.push({
            id: lastBlock.blockhash,
            address: "" + lastBlock.height,
            name: "" + lastBlock.height,
            color: "#5e923d"
          });

        }

      }

      this.setState({
        blockVisualisation:blocks,
        data:{
          nodes:nodes,
          links:links
        }
      })
    }

  }

  async getBlockTransactions(){

    let type = this.state.type;
    let heightOrHash = this.state.heightOrHash;
    let cancelToken = this.state.source.token;

    this.loadingBlockTransactions();

    let queryParams = {};
    queryParams[type === "h" ? 'height' : 'blockhash'] = heightOrHash;
    queryParams.page = this.state.page;
    queryParams.limit = this.state.limit;

    let response = await RemoteCall(
      false,
      "transactionsByBlock",
      "get",
      queryParams,
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingBlockTransactions();

    if(response != null){
      if(response.completed != null){
        for(let transaction of response.completed){
          transaction.date = getDateToDisplay(transaction.date).fullDate;
        }
      }
      this.setState({
        blockTransactions:response
      })
    }

  }

  async viewMore(){

    await this.setState((state)=>({page:state.page +1}));
    let type = this.state.type;
    let heightOrHash = this.state.heightOrHash;
    let cancelToken = this.state.source.token;

    this.loadingViewMore();

    let queryParams = {};
    queryParams[type === "h" ? 'height' : 'blockhash'] = heightOrHash;
    queryParams.page = this.state.page;
    queryParams.limit = this.state.limit;

    let response = await RemoteCall(
      false,
      "transactionsByBlock",
      "get",
      queryParams,
      null,
      cancelToken,
      this.props,
      this.state.crypto
    );

    this.loadingViewMore();

    if(response != null){
      let oldBlockTransactions = this.state.blockTransactions;
      if(response.completed != null){
        for(let transaction of response.completed){
          transaction.date = getDateToDisplay(transaction.date).fullDate;
        }
        oldBlockTransactions.completed = oldBlockTransactions.completed.concat(response.completed);
      }
      this.setState({
        blockTransactions:oldBlockTransactions
      })
    }

  }

  componentDidMount() {
    this.getBlockInfos();
    this.getBlockVisualisation();
    this.getBlockTransactions();
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let heightOrHash = this.state.heightOrHash;
    let type = this.state.type;
    if(
      nextProps.match != null && nextProps.match.params != null &&
      (nextProps.match.params.heightOrHash !== heightOrHash || nextProps.match.params.type !== type)
    ){
      await this.setState({
        heightOrHash:nextProps.match.params.heightOrHash,
        type:nextProps.match.params.type,
        toggle:false,
        toggleCollapse:false,
        loadingBlockInfos:false,
        loadingBlockVisualisation:false,
        loadingBlockTransactions:false,
        loadingViewMore:false,
        blockInfos:null,
        blockVisualisation:null,
        blockTransactions:null,
        page:1,
        limit:10,
        data:null,
        crypto: this.props.location.pathname.substring(1,4),
      });
      this.getBlockInfos();
      this.getBlockVisualisation();
      this.getBlockTransactions();
    }
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const {t} = this.props;
    let blockInfos = this.state.blockInfos;
    let blockTransactions = this.state.blockTransactions;

    const columns = [
      {
        name:'Tx ID',
        cell: (row) =>
          <>
            <div className="mr-3">
              <CopyToClipboard text={row.txid}>
                <i className="fa fa-copy copyButton" title={t('click_to_copy')}/>
              </CopyToClipboard>
            </div>
            <Link to={"/"+this.state.crypto+"/tx/"+row.txid}>
              <div className="link font-weight-bold">
                <div className="link font-weight-bold d-block d-md-none d-lg-none">
                  {row.txid.substring(0, 2)}...{row.txid.substring(row.txid.length - 2, row.txid.length)}
                </div>
                <div className="link font-weight-bold d-none d-md-block d-lg-none">
                  {row.txid.substring(0, 4)}...{row.txid.substring(row.txid.length - 4, row.txid.length)}
                </div>
                <div className="link font-weight-bold d-none d-lg-block">
                  {row.txid.substring(0, 15)}...{row.txid.substring(row.txid.length - 15, row.txid.length)}
                </div>
              </div>
            </Link>
          </>,
        minWidth: "120px"
      },
      {
        name: `${t('value')}`,
        cell: (row) => <>{(row.credit + row.debit).toFixed(8)} <span className="small font-weight-500 ml-1">BTC</span></>,
        maxWidth:"155px"
      },
      {
        name: `${t('fees')}`,
        cell: (row) => <>{row.fee.toFixed(8)} <span className="small font-weight-500 ml-1">BTC</span></>,
        maxWidth:"155px"
      },
      {
        name: t('date'),
        selector: 'date',
        width:"145px",
        hide:"md"
      }
    ];

    return(
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" sm="11">
            {
              this.state.loadingBlockInfos ? (
                <div className="d-flex justify-content-center mt-3">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loadingBlockInfos}
                  />
                </div>
              ) : (
                <div>
                  {
                    (blockInfos != null && blockInfos.block != null) ? (
                      <>
                        <div className="display-4 mb-4">
                          <h2 className="d-flex align-items-center mb-0">
                            <span>{t('block')} {blockInfos.block.height}</span>
                            {
                              blockInfos.block != null && blockInfos.block.status != null &&
                              <div className={classnames("dot3", "bg-"+blockInfos.block.status.toLowerCase())} title={t(blockInfos.block.status)}>
                                <i className="fa fa-check invisible"/>
                              </div>
                            }
                          </h2>
                          <div className="d-flex align-items-center">
                            <div className="smallText2 mr-2">{blockInfos.block.blockhash}</div>
                            <CopyToClipboard text={blockInfos.block.blockhash} onCopy={() => {/*notification("info",t('blockhash_copied'))*/}}>
                              <Button color="primary" size="sm" title={t('click_to_copy')}><i className="fa fa-copy"/></Button>
                            </CopyToClipboard>
                          </div>
                        </div>

                        {
                          this.state.data != null &&
                          <Graph data={this.state.data} height={200} block={true} label={false} />
                        }

                        <div className="mb-3">
                          <ListGroup flush>
                            {
                              blockInfos.block.time != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('date')}</Col>
                                  <Col xs="8" className="text-right">{getDateToDisplay(blockInfos.block.time).fullDate}</Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              blockInfos.block.tx_count != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('nb_tx')}</Col>
                                  <Col xs="8" className="text-right">{getAmountToDisplay(blockInfos.block.tx_count)}</Col>
                                </Row>
                              </ListGroupItem>
                            }
                            {
                              blockInfos.block.confirmations != null &&
                              <ListGroupItem className="p-2">
                                <Row>
                                  <Col xs="4" className="font-weight-bold">{t('Confirmations')}</Col>
                                  <Col xs="8" className="text-right">{blockInfos.block.confirmations}</Col>
                                </Row>
                              </ListGroupItem>
                            }
                            <Collapse isOpen={this.state.toggleCollapse}>
                              {
                                blockInfos.block.height != null &&
                                <ListGroupItem className="border-top p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('height')}</Col>
                                    <Col xs="8" className="text-right">{blockInfos.block.height}</Col>
                                  </Row>
                                </ListGroupItem>
                              }

                              {
                                blockInfos.block.merkle_root != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('merkle_root')}</Col>
                                    <Col xs="8" className="smallText3">{blockInfos.block.merkle_root}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.block.weight != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('weight_units')}</Col>
                                    <Col xs="8" className="text-right">{blockInfos.block.weight} WU</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.block.size != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('size')}</Col>
                                    <Col xs="8" className="text-right">{getAmountToDisplay(blockInfos.block.size)} {t('bytes')}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.block.nonce != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('nonce')}</Col>
                                    <Col xs="8" className="text-right">{getAmountToDisplay(blockInfos.block.nonce)}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.volume != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('transaction_volume')}</Col>
                                    <Col xs="8" className="text-right">{blockInfos.volume.toFixed(8) +" BTC"}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.reward != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('block_reward')}</Col>
                                    <Col xs="8" className="text-right">{blockInfos.reward.toFixed(8) +" BTC"}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                              {
                                blockInfos.fee != null &&
                                <ListGroupItem className="p-2">
                                  <Row>
                                    <Col xs="4" className="font-weight-bold">{t('fees')}</Col>
                                    <Col xs="8" className="text-right">{blockInfos.fee.toFixed(8) +" BTC"}</Col>
                                  </Row>
                                </ListGroupItem>
                              }
                            </Collapse>
                            <div className="d-flex justify-content-end mt-3">
                              <Button color="dark" size="sm" onClick={this.toggleCollapse}>
                                {t(this.state.toggleCollapse ? 'see_less' : 'see_more')}
                              </Button>
                            </div>
                          </ListGroup>
                        </div>

                        {
                          blockTransactions != null && blockTransactions.completed != null &&
                          <div className="mb-3">
                            <h6 className="font-weight-500 mb-3">{t('block_transactions')}</h6>
                            <DataTable
                              className="mb-3"
                              keyField={'id'}
                              noHeader={true}
                              columns={columns}
                              data={blockTransactions.completed}
                              dense
                              striped
                              responsive
                              paginationPerPage={blockTransactions.completed.length}
                              paginationRowsPerPageOptions={[blockTransactions.completed.length]}
                              noDataComponent={<h6 className="text-center my-4">{t('no_transaction_to_display')}</h6>}
                            />
                            <div className="d-flex justify-content-center my-3">
                              {
                                this.state.loadingViewMore ? (
                                  <PuffLoader
                                    sizeUnit={"px"}
                                    size={30}
                                    color={loaderColor}
                                    loading={this.state.loadingViewMore}
                                  />
                                ) : (
                                  <>
                                    {
                                      blockTransactions.pages != null && this.state.page < blockTransactions.pages &&
                                      <Button color="kamixColorGreen" size="sm" onClick={this.viewMore}>{t('view_more')}</Button>
                                    }
                                  </>
                                )
                              }
                            </div>
                          </div>
                        }
                      </>
                    ) : (
                      <h6 className="text-center display-4 mt-4">
                        {t('no_result_found')}
                      </h6>
                    )
                  }
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    );

  }

}

export default withTranslation()(withRouter(Block));