import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ForceGraph from "3d-force-graph";
import {CSS2DRenderer, CSS2DObject} from 'three-css2drender';
import SpriteText from 'three-spritetext';
import {getRandomInt} from "../../MyFunctions";
import "../../index.css";

class MyGraph extends Component {

  constructor(props) {
    super(props);

    this.state = {
      labelOn:false,
      displayText:true
    };

    this.onNodeClick = this.onNodeClick.bind(this);
    this.onBlockClick = this.onBlockClick.bind(this);
    this.displayText = this.displayText.bind(this);
    this.labelOn = this.labelOn.bind(this);
    this.getGraph = this.getGraph.bind(this);
  }

  onNodeClick(node,e) {
    e.preventDefault();
    if(!node.coinbase && node.id !== "root") this.props.history.push('/btc/address/'+node.address);
  }

  onBlockClick(node,e) {
    e.preventDefault();
    if(!node.coinbase && node.id !== "root") this.props.history.push('/btc/block/h/'+node.address);
  }

  async displayText(e){
    await this.setState({displayText: e.target.checked});
    this.getGraph();
  }

  async labelOn(e){
    await this.setState({labelOn: e.target.checked});
    this.getGraph();
  }

  getGraph() {

    let data = this.props.data;
    let total = data.nodes.length;

    this.graph = ForceGraph({
      extraRenderers: [new CSS2DRenderer()]
    })(this.chartRef)

      .width(this.chartRef.clientWidth)
      .height(this.props.height != null ? this.props.height : 350)
      .cameraPosition({ z: (total != null && total < 20) ? 170 : 400 })
      .showNavInfo(false)
      .backgroundColor("#ffffff")

      .nodeLabel("name")
      .nodeThreeObject(node => {
        const nodeEl = document.createElement('div');
        nodeEl.textContent = node.name;
        nodeEl.className = 'node-label';
        return this.state.displayText ? new CSS2DObject(nodeEl) : null;
      })
      .nodeThreeObjectExtend(true)

      .linkDirectionalArrowLength(5)
      .linkDirectionalArrowRelPos(1)
      .linkThreeObjectExtend(true)
      .linkThreeObject(link => {
        // extend link with text sprite
        const sprite = new SpriteText(link.display);
        sprite.color = 'black';
        sprite.textHeight = 1.8;
        return this.state.labelOn ? sprite : null;
      })
      .linkPositionUpdate((sprite, { start, end }) => {
        const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
          [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
        })));
        // Position sprite
        Object.assign(sprite != null && sprite.position, middlePos);
      })

      .onNodeClick((node,event)=>this.props.block != null ? this.onBlockClick(node,event) : this.onNodeClick(node,event))
      .graphData(data)
      .d3Force('link').distance(getRandomInt(40,50))
    ;

  }

  async componentDidMount(){
    let data = this.props.data;
    let total = data.nodes.length;
    if(total>20) await this.setState({displayText:false});
    this.getGraph();
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let data = this.props.data;
    let total = data.nodes.length;
    if(total>20) await this.setState({displayText:false});
    this.getGraph();
  }

  render(){

    const {t} = this.props;

    return(
      <div>
        {
          this.props.data != null &&
          <>
            {
              this.props.label == null &&
              <div className="box">
                <div className="d-flex">
                  <div className="mr-2">{t('text')}</div>
                  <label className="customToggle">
                    <input type="checkbox" checked={this.state.displayText} onChange={this.displayText} />
                    <span className="customToggleSlider" />
                  </label>
                </div>
                <div className="d-flex mx-3">
                  <div className="mr-2">{t('value_btc')}</div>
                  <label className="customToggle">
                    <input type="checkbox" checked={this.state.labelOn} onChange={this.labelOn} />
                    <span className="customToggleSlider" />
                  </label>
                </div>
                <div className="text-primary">
                  <i className="fa fa-refresh fa-lg pointer" onClick={this.getGraph} />
                </div>
              </div>
            }
            <div className="graphArea" ref={r => (this.chartRef = r)} />
          </>
        }
      </div>
    );

  }

}

export default withTranslation()(withRouter(MyGraph));