import React, { Component } from 'react';
import {withRouter} from "react-router";
import {Row, Col, Card, CardHeader, Collapse, CardBody, Button, Modal, Badge, FormText, Label} from "reactstrap";
import {withTranslation} from "react-i18next";
import {PuffLoader} from "react-spinners";
import RemoteCall, {getError, loaderColor, notification} from "../../../../MyFunctions";
import axios from "axios";
import {store} from "../../../../Security";
import DataTable from "react-data-table-component";
import CampaignFilePresentation from "../Files/CampaignFilePresentation";
import DownloadFile from "../DownloadFile";
import "../../../../index.css";

class Step2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle:false,
      loading:false,
      loading2:false,
      loadingCalculations:false,
      ledgerSelected:"",
      file:null,
      source:axios.CancelToken.source()
    };

    this.toggle = this.toggle.bind(this);
    this.loading = this.loading.bind(this);
    this.loading2 = this.loading2.bind(this);
    this.loadingCalculations = this.loadingCalculations.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.saveFile = this.saveFile.bind(this);
    this.saveFile2 = this.saveFile2.bind(this);
    this.launchCalculations = this.launchCalculations.bind(this);
    this.isPrerequisitesOk = this.isPrerequisitesOk.bind(this);
  }

  toggle(ledger){
    this.setState((state)=>({
      toggle:!state.toggle,
      ledgerSelected: ledger,
      file:null,
    }))
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loading2(){
    this.setState((state)=>({
      loading2:!state.loading2
    }))
  }

  loadingCalculations(){
    this.setState((state)=>({
      loadingCalculations:!state.loadingCalculations
    }))
  }

  onChangeFile(e){
    let file = e.target.files[0];
    this.setState({
      file:file
    })
  }

  deleteFile(){
    this.setState({
      file:null
    })
  }

  async saveFile(){

    const {t} = this.props;

    let campaign = this.props.campaign;
    let file = this.state.file;
    let ledgerSelected = this.state.ledgerSelected;
    const formData = new FormData();
    formData.append('campaign',file,file.name);

    this.loading();

    //--------------------------------------------------------------------
    let headers = {};
    headers['x-access-token'] = store.get('userCredentials').token;
    headers['Content-Type'] = 'multipart/form-data;';
    let config = {
      url: process.env.REACT_APP_URL_ACC + "campaigns/step2/"+campaign.campaign_id,
      method:"post",
      headers:headers,
      cancelToken:this.state.source.token
    };
    config.responseType = "blob";
    config.data = formData;
    config.params = {ledger:ledgerSelected};
    //--------------------------------------------------------------------
    return axios.request(config)
      .then(response =>{
        this.loading();
        let blob = response.data;
        blob != null && blob.text().then(text => {
          let data = JSON.parse(text);
          getError(data,this.props);
          let error = data.error;
          if(error != null && !error){
            this.toggle();
            notification('success',t('successful_add_on'));
            this.props.getCampaign(data.campaign);
          }
        });
        blob.text();
      }).catch(err =>{
        this.loading();
        if(axios.isCancel(err)) {
        }else{
          let blob = err.response.data;
          blob != null && blob.text().then(text => {
            notification("warning",text);
          });
          blob.text();
        }
      });
    //--------------------------------------------------------------------

  }

  async saveFile2(){

    const {t} = this.props;

    let campaign = this.props.campaign;
    let ledgerSelected = this.state.ledgerSelected;
    let cancelToken = this.state.source.token;

    this.loading2();

    let response = await RemoteCall(
      true,
      "campaigns/step2/"+campaign.campaign_id,
      "get",
      {ledger:ledgerSelected},
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading2();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.toggle();
        notification('success',t('successful_add_on'));
        this.props.getCampaign(response.campaign);
      }
    }

  }

  async launchCalculations(){

    const { t } = this.props;
    let campaign = this.props.campaign;
    let cancelToken = this.state.source.token;

    this.loadingCalculations();

    let response = await RemoteCall(
      true,
      "campaigns/step3/"+campaign.campaign_id,
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingCalculations();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("success",t('operation_done'));
        this.props.getCampaign(response.campaign);
      }
    }

  }

  isPrerequisitesOk(campaign){
    let result = true;
    if(campaign.files != null){
      for (let file of campaign.files){
        if(file.es2_dl_url == null){
          result = false;
          break;
        }
      }
    }
    return result;
  }

  render() {

    const {t} = this.props;
    let campaign = this.props.campaign;

    const columns = [
      {
        name: t('ledger'),
        selector: 'ledger',
        cell: row => <div>{t(row.ledger)}</div>,
      },
      {
        name: t('enriched_file') + " 1",
        selector: 'es1_dl_url',
        cell: row => <DownloadFile url={row.es1_dl_url} /> ,
        maxWidth:"100px"
      },
      {
        name: t('enriched_file') + " 2",
        selector: 'es2_dl_url',
        cell: row => <div className="font-weight-700">
          {
            row.es2_dl_url != null ? (
              <DownloadFile url={row.es2_dl_url} />
            ) : (
              <Button color="warning" size="sm" title={t('upload')} onClick={(e)=>this.toggle(row.ledger,e)}>
                <i className="fa fa-upload" />
              </Button>
            )
          }
        </div>,
        maxWidth:"100px",
        omit : campaign.step === 1
      }
    ];

    return (
      <>

        <Card className="mb-1">
          <CardHeader id="headingTwo" className="pointer" onClick={() => this.props.toggleAccordion(1)}>
            <div className="d-flex align-items-center font-weight-bold text-darker">
              <div className="mr-auto">{t('pre_processing')}</div>
              <div onClick={() => this.props.toggleAccordion(1)} aria-expanded={this.props.accordion} aria-controls="collapseTwo">
                {this.props.accordion ? <i className="fa fa-chevron-down" /> : <i className="fa fa-chevron-up" />}
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={this.props.accordion} data-parent="#accordion" id="collapseTwo" aria-labelledby="headingTwo">
            <CardBody>

              {
                campaign.step === 1 &&
                <div className="d-flex mb-2">
                  <div className="mr-3">
                    <i className="fa fa-ellipsis-h text-warning" />
                  </div>
                  <div>{t('warning_step_1')}</div>
                </div>
              }

              {
                campaign.step === 2 &&
                <div className="d-flex mb-2">
                  <div className="mr-3">
                    <i className="fa fa-check-circle text-kamixColorGreen" />
                  </div>
                  <div>{t('success_step_1')}</div>
                </div>
              }

              {
                this.isPrerequisitesOk(campaign) ? (
                  <div className="d-flex mb-2">
                    <div className="mr-3">
                      <i className="fa fa-check-circle text-kamixColorGreen" />
                    </div>
                    <div>{t('success_step_2')}</div>
                  </div>
                ) : (
                  <div className="d-flex mb-2">
                    <div className="mr-3">
                      <i className="fa fa-ellipsis-h text-warning" />
                    </div>
                    <div>{t('warning_step_2')}</div>
                  </div>
                )
              }

              {
                campaign.files != null && campaign.files.length > 0 &&
                <DataTable
                  className="mb-2"
                  noHeader={true}
                  columns={columns}
                  data={campaign.files}
                  pagination
                  striped
                  responsive
                  expandableRows
                  expandOnRowClicked
                  expandableRowsComponent={<CampaignFilePresentation step={2} />}
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5,10]}
                />
              }

              {
                this.state.loadingCalculations ? (
                  <div style={{height:"27px"}}>
                    <PuffLoader
                      sizeUnit={"px"}
                      size={30}
                      color={loaderColor}
                      loading={this.state.loadingCalculations}
                    />
                  </div>
                ) : (
                  <div className="d-flex">
                    {
                      campaign.step === 2 && this.isPrerequisitesOk(campaign) &&
                      <Button color="kamixColorGreen" size="sm" onClick={this.launchCalculations} className="mt-3">
                        {t('launch_calculations')}
                      </Button>
                    }
                  </div>
                )
              }

            </CardBody>
          </Collapse>
        </Card>

        <Modal size="md" isOpen={this.state.toggle} toggle={this.toggle}>
          <Card>
            <CardBody>

              <Row className="justify-content-center">
                <Col xs="12" md="11">

                  <h5 className="text-center mb-3">
                    <Badge color="kamixColorGreen" className="px-3">
                      {t('upload_file')}
                    </Badge>
                  </h5>

                  <div>
                    <FormText className="font-weight-bold font-italic mb-0">{t('use_file1_as_file2')}</FormText>
                    <FormText className="font-italic mb-2">({t('no_tag_and_com')})</FormText>
                    <div className="d-flex mt-3">
                      {
                        this.state.loading2 ? (
                          <div style={{height:"27px"}}>
                            <PuffLoader
                              sizeUnit={"px"}
                              size={30}
                              color={loaderColor}
                              loading={this.state.loading2}
                            />
                          </div>
                        ) : (
                          <Button color="kamixColorGreen" size="sm" onClick={this.saveFile2} disabled={this.state.loading}>
                            {t('use')}
                          </Button>
                        )
                      }
                    </div>
                  </div>

                  <FormText className="font-weight-bold text-darker my-3">{t('or')}</FormText>

                  <div>
                    <>
                      <FormText className="text-darker font-weight-bold mb-1">
                        {t('upload_xlsx_file')}
                      </FormText>
                      {
                        this.state.file != null ? (
                          <div>
                            <div className="d-flex mb-2">
                              <span className="myFile mr-2">{this.state.file.name}</span>
                              {
                                !this.state.loading &&
                                <span className="myFile pointer px-2" onClick={this.deleteFile}>
                                <i className="fa fa-close" />
                              </span>
                              }
                            </div>
                          </div>
                        ) : (
                          <div>
                            <input
                              type="file" name="file1" id="file1" className="inputfile" onChange={this.onChangeFile}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={this.state.loading2}
                            />
                            <Label htmlFor="file1">
                              <i className="fa fa-upload" />
                            </Label>
                          </div>
                        )
                      }
                    </>
                  </div>

                  {
                    this.state.file != null &&
                    <div className="d-flex mt-3">
                      {
                        this.state.loading ? (
                          <div style={{height:"27px"}}>
                            <PuffLoader
                              sizeUnit={"px"}
                              size={30}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          </div>
                        ) : (
                          <Button color="kamixColorGreen" size="sm" onClick={this.saveFile} disabled={this.state.loading2}>
                            {t('add_to_campaign')}
                          </Button>
                        )
                      }
                    </div>
                  }

                </Col>
              </Row>

            </CardBody>
          </Card>
        </Modal>

      </>
    )

  }

}

export default withTranslation()(withRouter(Step2));
