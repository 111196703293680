import React from "react";
import {withRouter} from "react-router-dom";
import {
  Row, Col, Button, Modal, Card, CardBody, Form, FormText,
  Input, InputGroupAddon, InputGroupText, InputGroup, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {loaderColor, notification} from "../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import classnames from "classnames";
import Express from "./Express";
import CampaignPresentation from "./CampaignPresentation";
import CampaignActions from "./CampaignActions";
import axios from "axios";
import DataTable from "react-data-table-component";
import "../../../index.css";

class Campaigns extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingCreateCampaign:false,
      toggleCreateCampaign:false,
      toggleInfos:false,
      campaigns:[],

      name:"",
      description:"",

      plainTabs: 1,
      source:axios.CancelToken.source()
    };

    this.toggleNavs = this.toggleNavs.bind(this);

    this.loading = this.loading.bind(this);
    this.loadingCreateCampaign = this.loadingCreateCampaign.bind(this);
    this.toggleCreateCampaign = this.toggleCreateCampaign.bind(this);
    this.toggleInfos = this.toggleInfos.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);

    this.getCampaigns = this.getCampaigns.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingCreateCampaign(){
    this.setState((state)=>({
      loadingCreateCampaign: !state.loadingCreateCampaign
    }))
  }

  toggleCreateCampaign(){
    this.setState((state)=>({
      toggleCreateCampaign: !state.toggleCreateCampaign,
      name:"",
      description:"",
    }))
  }

  toggleInfos(){
    this.setState((state)=>({
      toggleInfos: !state.toggleInfos
    }))
  }

  onChangeName(e){
    this.setState({
      name:e.target.value
    })
  }

  onChangeDescription(e){
    this.setState({
      description:e.target.value
    })
  }

  async getCampaigns(){

    let cancelToken = this.state.source.token;
    this.loading();

    let response = await RemoteCall(
      true,
      "campaigns",
      "get",
      null,
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          campaigns:response.campaigns
        })
      }
    }

  }

  async saveCampaign(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;

    let name = this.state.name;
    let description = this.state.description;

    if(name === ""){
      notification("warning",t('add_campaign_name'));
    }else{

      let formData = {};
      formData.name = name;
      formData.description = description;

      this.loadingCreateCampaign();

      let response = await RemoteCall(
        true,
        "campaigns",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loadingCreateCampaign();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          notification("success",t('campaign_created'));
          this.toggleCreateCampaign();
          this.getCampaigns();
        }
      }

    }

  }

  componentDidMount() {
    this.getCampaigns();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let campaigns = this.state.campaigns;

    const columns = [
      {
        name: t('name'),
        selector: 'name'
      },
      {
        name: t('step'),
        selector: 'step',
        maxWidth:"100px",
        hide:"lg"
      },
      {
        name: t('status'),
        selector: 'status',
        cell: row => <div className={"font-weight-700 text-"+row.status.toLowerCase()}>{row.status}</div>,
        maxWidth:"150px",
        hide:"lg"
      },
      {
        name:t('actions'),
        cell: (row) => <CampaignActions row={row} getCampaigns={this.getCampaigns} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    return (
      <>

        <Row className="justify-content-center">
          <Col xs="11">

            <div className="nav-wrapper">
              <Nav className="nav-fill d-inline-flex flex-wrap" id="tabs-icons-text" pills role="tablist">
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 1})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 1 ? "text-white font-weight-500" : "font-weight-500"}>{t('campaigns')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-2 mx-1 mx-md-0", {active: this.state.plainTabs === 2})}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    <span className={this.state.plainTabs === 2 ? "text-white font-weight-500" : "font-weight-500"}>{t('express')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <Card className="shadow-sm mb-4">
              <CardBody>
                {
                  this.state.loading ? (
                    <Row className="justify-content-center my-5">
                      <PuffLoader
                        sizeUnit={"px"}
                        size={50}
                        color={loaderColor}
                        loading={this.state.loading }
                      />
                    </Row>
                  ) : (
                    <TabContent activeTab={"plainTabs" + this.state.plainTabs}>

                      <TabPane tabId="plainTabs1">
                        <>
                          {
                            campaigns.length === 0 ? (
                              <div className="d-flex justify-content-center my-5">
                                <Button color="kamixColorGreen" size="sm" onClick={this.toggleCreateCampaign}>
                                  {t('create_a_campaign')}
                                </Button>
                              </div>
                            ) : (
                              <Row className="justify-content-center">
                                <Col xs="11">

                                  <div className="d-flex justify-content-end mb-2">
                                    <Button color="primary" size="sm" onClick={this.toggleInfos} className="mr-2">
                                      <i className="fa fa-info fa-lg" />
                                    </Button>
                                    <Button color="kamixColorGreen" size="sm" onClick={this.getCampaigns} className="mr-2">
                                      <i className="fa fa-refresh fa-lg" />
                                    </Button>
                                    <Button color="kamixColorGreen" size="sm" className="rounded-circle" onClick={this.toggleCreateCampaign}>
                                      <i className="fa fa-plus fa-lg" />
                                    </Button>
                                  </div>

                                  <DataTable
                                    className="mt-3"
                                    noHeader={true}
                                    columns={columns}
                                    data={campaigns}
                                    pagination
                                    striped
                                    responsive
                                    expandableRows
                                    expandOnRowClicked
                                    expandableRowsComponent={<CampaignPresentation />}
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[5,10,20]}
                                  />

                                </Col>
                              </Row>
                            )
                          }
                        </>
                      </TabPane>

                      <TabPane tabId="plainTabs2">
                        <Express  />
                      </TabPane>

                    </TabContent>
                  )
                }
              </CardBody>
            </Card>

          </Col>
        </Row>

        {/* Creeation d'une campagne */}

        <Modal size="md" isOpen={this.state.toggleCreateCampaign} toggle={this.toggleCreateCampaign}>
          <Card className="bg-secondary">
            <CardBody>
              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('create_a_campaign')}</h6>
              </div>
              <div>
                <Form>
                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('name')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><span className="font-weight-bold">#</span></InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" value={this.state.name} onChange={this.onChangeName}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-4">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('description')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><span className="font-weight-bold">#</span></InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" value={this.state.description} onChange={this.onChangeDescription}/>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="d-flex justify-content-center">
                {
                  this.state.loadingCreateCampaign ? (
                    <PuffLoader
                      sizeUnit={"px"}
                      size={35}
                      color={loaderColor}
                      loading={this.state.loadingCreateCampaign}
                    />
                  ) : (
                    <Row>
                      <Button color="danger" size="sm" className="mr-2" onClick={this.toggleCreateCampaign}>{t('cancel')}</Button>
                      <Button color="kamixColorGreen" size="sm" onClick={this.saveCampaign}>{t('save')}</Button>
                    </Row>
                  )
                }
              </div>
            </CardBody>
          </Card>
        </Modal>

        {/* Informations statut Campagne */}

        <Modal size="lg" isOpen={this.state.toggleInfos} toggle={this.toggleInfos}>
          <Card>
            <CardBody>
              <div className="mb-4">
                <div className="text-kamixColorGreen text-center font-weight-bold mb-2">
                  <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('status_description')}</h6>
                </div>
                <Row className="justify-content-center small2">
                  <Col xs="12" lg="11">
                    <Row className="justify-content-center mb-1">
                      <Col xs="3" lg="2" className="text-created">CREATED</Col>
                      <Col>{t('created_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="3" lg="2" className="text-pending">PENDING</Col>
                      <Col>{t('pending_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="3" lg="2" className="text-stand_by">STAND_BY</Col>
                      <Col>{t('stand_by_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="3" lg="2" className="text-success">DONE</Col>
                      <Col>{t('done_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="3" lg="2" className="text-error">ERROR</Col>
                      <Col>{t('error_infos')}</Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <div className="text-kamixColorGreen text-center font-weight-bold mb-2">
                  <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('step_description')}</h6>
                </div>
                <Row className="justify-content-center small2">
                  <Col xs="12" lg="11">
                    <Row className="justify-content-center mb-1">
                      <Col xs="1" className="text-dark font-weight-700">0</Col>
                      <Col>{t('step_0_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="1" className="text-dark font-weight-700">1</Col>
                      <Col>{t('step_1_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="1" className="text-dark font-weight-700">2</Col>
                      <Col>{t('step_2_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="1" className="text-dark font-weight-700">3</Col>
                      <Col>{t('step_3_infos')}</Col>
                    </Row>
                    <Row className="justify-content-center mb-1">
                      <Col xs="1" className="text-dark font-weight-700">4</Col>
                      <Col>{t('step_4_infos')}</Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-center">
                <Button color="default" size="sm" onClick={this.toggleInfos}>
                  <i className="fa fa-close fa-lg" />
                </Button>
              </div>
            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(Campaigns));
