import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {Container, Row, Col, Form, FormGroup, InputGroup, InputGroupAddon, Input, InputGroupText, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import Select from 'react-select';
import {notification} from "../MyFunctions";
import "../index.css";

class SearchBar extends Component {

  constructor(props) {
    super(props);
    this.state={
      inputValue:"",
      type:null,
      crypto:null
    };
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeCrypto = this.onChangeCrypto.bind(this);
    this.onInputValueChange = this.onInputValueChange.bind(this);
    this.clearInputValue = this.clearInputValue.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  onChangeType(e){
    this.setState({
      type:e
    })
  }

  onChangeCrypto(e){
    this.setState({
      crypto:e
    })
  }

  onInputValueChange(e){
    this.setState({
      inputValue:e.target.value
    })
  }

  clearInputValue(){
    this.setState({
      inputValue:""
    })
  }

  goTo(e){
    e.preventDefault();
    const {t} = this.props;
    let inputValue = this.state.inputValue;
    let crypto = this.state.crypto;
    let type = this.state.type;
    if(inputValue !== ""){
      this.props.getId(inputValue);
      this.props.history.push(`/${crypto.value}/${type.value}/${inputValue}`);
    }else{
      notification("warning",t('add_search_value'));
    }
  }

  componentDidMount() {
    const {t} = this.props;
    this.setState({
      crypto:{ value: 'btc', label: 'BTC' },
      type:{ value: 'tx', label: t('transaction') },
    })
  }

  render() {

    const {t} = this.props;

    const typeOptions = [
      { value: 'tx', label: t('transaction') },
      { value: 'address', label: t('address') },
      { value: 'block/H', label: t('block_hash') },
      { value: 'block/h', label: t('block_height') },
    ];

    const cryptoOptions = [
      { value: 'btc', label: 'BTC' },
      { value: 'eth', label: 'ETH' },
    ];

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <>
        <Container className="searchBar">
          <Row className="justify-content-center">
            <Col xs="12" sm="8">
              <Form onSubmit={(e)=>this.goTo(e)}>
                <div className="d-flex mb-2">
                  <div className="mr-2" style={{width:"150px"}}>
                    <Select
                      value={this.state.type}
                      onChange={this.onChangeType}
                      options={typeOptions}
                      styles={customStyles}
                      className="font-weight-500 small"
                    />
                  </div>
                    <div style={{width:"80px"}}>
                    <Select
                      value={this.state.crypto}
                      onChange={this.onChangeCrypto}
                      options={cryptoOptions}
                      styles={customStyles}
                      className="font-weight-500 small"
                    />
                  </div>
                </div>
                <FormGroup className="d-flex">
                  <InputGroup className="input-group-alternative mr-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      value={this.state.inputValue}
                      onChange={this.onInputValueChange}
                      placeholder={t('search_for_address_or_tx')}
                    />
                    {
                      this.state.inputValue !== "" &&
                      <InputGroupAddon addonType="append" onClick={this.clearInputValue} style={{cursor:"pointer"}}>
                        <InputGroupText>
                          <i className="fa fa-close"/>
                        </InputGroupText>
                      </InputGroupAddon>
                    }
                  </InputGroup>
                  <Button color="kamixColorGreen" size="sm" type="submit">{t('search')}</Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );

  }

}

export default withTranslation()(withRouter(SearchBar));
