import React from "react";
import {withRouter} from "react-router-dom";
import {
  Row, Col, Button, Modal, Card, CardBody, Form, FormText,
  Input, InputGroupAddon, InputGroupText, InputGroup, FormGroup
} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {loaderColor, notification, getName} from "../../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from "axios";
import DataTable from "react-data-table-component";
import UserActions from "./UserActions";
import UserPresentation from "./UserPresentation";
import Select from "react-select";
import "../../../../index.css";

class Users extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingCreateAdmin:false,
      toggleCreateAdmin:false,
      users:[],
      searchResults:[],
      user_name:"",
      password:"",
      type:null,
      searchValue:"",
      onSearch:false,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingCreateAdmin = this.loadingCreateAdmin.bind(this);
    this.toggleCreateAdmin = this.toggleCreateAdmin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.saveAdmin = this.saveAdmin.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.clearSearchBar = this.clearSearchBar.bind(this);
    this.search = this.search.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingCreateAdmin(){
    this.setState((state)=>({
      loadingCreateAdmin: !state.loadingCreateAdmin
    }))
  }

  toggleCreateAdmin(){
    this.setState((state)=>({
      toggleCreateAdmin: !state.toggleCreateAdmin,
      user_name:"",
      password:""
    }))
  }

  onChangeUsername(e){
    this.setState({
      user_name:e.target.value
    })
  }

  onChangePassword(e){
    this.setState({
      password:e.target.value
    })
  }

  onChangeType(e){
    this.setState({
      type:e,
      searchValue:"",
      onSearch:false
    });
    this.getUsers(e.value);
  }

  getUser(user,id){
    let users = this.state.users;
    user.id = id;
    users[id] = user;
    this.setState({
      users:users
    })
  }

  async getUsers(type){

    let cancelToken = this.state.source.token;
    this.loading();

    let response = await RemoteCall(
      true,
      "admins/users",
      "get",
      {ad:true},
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        let users = response.users;
        switch (type) {
          case 'active':
            users = users != null && users.filter(user => !user.deleted_account);
            break;
          case 'deleted':
            users = users != null && users.filter(user => user.deleted_account);
            break;
          default:
            break;
        }
        if(users != null) for(let i=0; i<users.length; i++) users[i].id = i;
        this.setState({
          users:users,
          onSearch:false,
          searchValue:""
        })
      }
    }

  }

  async saveAdmin(){

    const { t } = this.props;
    let cancelToken = this.state.source.token;

    let user_name = this.state.user_name;
    let password = this.state.password;

    if(user_name === "" || password === ""){
      notification("warning",t('fill_fields_form_label'));
    }else{

      let formData = {};
      formData.user_name = user_name;
      formData.password = password;

      this.loadingCreateAdmin();

      let response = await RemoteCall(
        true,
        "admins/create-admin",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loadingCreateAdmin();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          notification("info",t('user_created'));
          this.toggleCreateAdmin();
          this.getUsers('active');
        }
      }

    }

  }

  refresh(){
    const {t} = this.props;
    this.setState({
      type:{ value: 'active', label: t('active') },
      searchValue:"",
      onSearch:false
    });
    this.getUsers('active');
  }

  onChangeSearchValue(e){
    this.setState({
      searchValue:e.target.value
    })
  }

  clearSearchBar(){
    this.setState({
      searchValue:"",
      onSearch:false
    })
  }

  search(e){
    e.preventDefault();
    let users = this.state.users;
    let searchResults = this.state.searchResults;
    let searchValue = this.state.searchValue;
    searchResults = users.filter(
      user =>
        (user.username != null && user.username.toLowerCase().includes(searchValue)) ||
        (user.first_name != null && user.first_name.toLowerCase().includes(searchValue)) ||
        (user.last_name != null && user.last_name.toLowerCase().includes(searchValue)) ||
        (user.full_name != null && user.full_name.toLowerCase().includes(searchValue))
    );
    this.setState({
      searchResults:searchResults,
      onSearch:true
    })
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let dataToDisplay = this.state.onSearch ? this.state.searchResults : this.state.users;

    const columns = [
      {
        name: t('username'),
        selector: 'user_name',
        maxWidth:"150px",
        hide:"md"
      },
      {
        name: t('complete_name'),
        cell: row => <div>{getName(row)}</div>,
        fontWeight: "bold"
      },
      {
        name: t('features_allowed'),
        selector: 'features_allowed',
        cell: row => <div className={('font-weight-700')+ ((row.features_allowed != null && row.features_allowed) ? ' text-kamixColorGreen' : ' text-danger')}>
          {(row.features_allowed != null && row.features_allowed) ? t('yes'): t('no')}
        </div>,
        maxWidth:"100px",
        hide:"lg",
      },
      {
        name: t('role'),
        selector: 'role',
        cell: row => <div>{t(row.role.toLowerCase())}</div>,
        maxWidth:"100px",
        hide:"lg"
      },
      {
        name:t('actions'),
        cell: (row) => <UserActions row={row} getUser={this.getUser} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const typeOptions = [
      { value: 'all', label: t('all') },
      { value: 'active', label: t('active') },
      { value: 'deleted', label: t('deleted') },
    ];

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <>

        <Row className="justify-content-center">
          <Col xs="11">

            <div className="d-flex flex-wrap mb-4">
              <div className="mr-auto">
                <small className="font-weight-bold">{t('account_deletion_status')}</small>
                <Select
                  value={this.state.type}
                  onChange={this.onChangeType}
                  options={typeOptions}
                  styles={customStyles}
                  className="font-weight-500 small"
                />
              </div>
              <div>
                <Button color="kamixColorGreen" size="sm" onClick={this.refresh} className="mr-2">
                  <i className="fa fa-refresh fa-lg" />
                </Button>
                <Button color="kamixColorGreen" size="sm" className="rounded-circle" onClick={this.toggleCreateAdmin}>
                  <i className="fa fa-plus fa-lg" />
                </Button>
              </div>
            </div>

            <Form onSubmit={(e)=>this.search(e)}>
              <Row className="justify-content-center mb-2">
                <Col xs="8" sm="6">
                  <FormGroup>
                    <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-hashtag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t('search_by_name_or_username')}
                        type="text"
                        value={this.state.searchValue}
                        onChange={this.onChangeSearchValue}
                        className="text-dark font-weight-500"
                      />
                      {
                        this.state.searchValue !== "" &&
                        <InputGroupAddon addonType="append" onClick={this.clearSearchBar} className="pointer">
                          <InputGroupText>
                            <i className="fa fa-close border-left-0"/>
                          </InputGroupText>
                        </InputGroupAddon>
                      }
                    </InputGroup>
                  </FormGroup>
                </Col>
                <div>
                  <Button color="dark" size="sm" className="mr-2" type="submit"><i className="fa fa-search" /></Button>
                  {/*<Button color="kamixColorGreen" size="sm" onClick={this.refresh}><i className="fa fa-refresh"/></Button>*/}
                </div>
              </Row>
            </Form>


            {
              this.state.loading ? (
                <div className="d-flex justify-content-center my-5">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loading }
                  />
                </div>
              ) : (
                <>
                  {
                    dataToDisplay.length === 0 ? (
                      <div className="d-flex justify-content-center my-5">
                        <h6 className="text-dark font-weight-500">
                          {t('no_user_to_present')}
                        </h6>
                      </div>
                    ) : (
                      <>
                        <DataTable
                          className="mt-3"
                          noHeader={true}
                          columns={columns}
                          data={dataToDisplay}
                          pagination
                          striped
                          responsive
                          expandableRows
                          expandOnRowClicked
                          expandableRowsComponent={<UserPresentation />}
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10,20]}
                        />
                      </>
                    )
                  }
                </>
              )
            }

          </Col>
        </Row>

        {/* Creeation d'un administrateur */}

        <Modal size="md" isOpen={this.state.toggleCreateAdmin} toggle={this.toggleCreateAdmin}>
          <Card className="bg-secondary">
            <CardBody>
              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('create_an_admin')}</h6>
              </div>
              <div>
                <Form>
                  <Row className="justify-content-center mb-2">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('username')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><span className="font-weight-bold">#</span></InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" value={this.state.user_name} onChange={this.onChangeUsername}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-4">
                    <Col xs="12" sm="10">
                      <FormText color="muted">{t('password')}</FormText>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><span className="font-weight-bold">#</span></InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" value={this.state.password} onChange={this.onChangePassword} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="d-flex justify-content-center">
                {
                  this.state.loadingCreateAdmin ? (
                    <PuffLoader
                      sizeUnit={"px"}
                      size={35}
                      color={loaderColor}
                      loading={this.state.loadingCreateAdmin}
                    />
                  ) : (
                    <Row>
                      <Button color="danger" size="sm" className="mr-2" onClick={this.toggleCreateAdmin}>{t('cancel')}</Button>
                      <Button color="kamixColorGreen" size="sm" onClick={this.saveAdmin}>{t('save')}</Button>
                    </Row>
                  )
                }
              </div>
            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(Users));
