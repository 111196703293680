import React from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Button,} from "reactstrap";
import {withTranslation} from "react-i18next";
import RemoteCall, {getAmountToDisplay, getDateToDisplay, getName, loaderColor} from "../../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import axios from "axios";
import DataTable from "react-data-table-component";
import PaymentPresentation from "./PaymentPresentation";
import ManagePage from "../../../Gadgets/ManagePage";
import "../../../../index.css";

class Payments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      loadingNavigation:false,
      payments:[],
      totalResult:"",
      page:1,
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingNavigation = this.loadingNavigation.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getPayments = this.getPayments.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading,
      loadingNavigation:false
    }))
  }

  loadingNavigation(){
    this.setState((state)=>({
      loadingNavigation:!state.loadingNavigation
    }))
  }

  getPage(page){
    this.setState({
      page:page
    })
  }

  async getPayments(page){

    let cancelToken = this.state.source.token;
    this.loadingNavigation();

    let response = await RemoteCall(
      true,
      "admins/payments",
      "get",
      {page:page},
      null,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingNavigation();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        this.setState({
          payments:response.payments,
          totalResult:response.count,
          page:page
        })
      }
    }

  }

  async refresh(){
    await this.setState({page:1});
    await this.getPayments(1);
  }

  async componentDidMount() {
    this.loading();
    await this.getPayments(1);
    this.loading();
  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;
    let payments = this.state.payments;

    const columns = [
      {
        name: t('complete_name'),
        cell: row => <div>{getName(row.user)}</div>,
        fontWeight: "bold"
      },
      {
        name: t('amount'),
        selector: 'amount',
        cell: row => <div className="font-weight-600">{getAmountToDisplay(row.amount)} <small className="ml-1">EUR</small></div>,
        maxWidth:"100px",
        hide:"lg"
      },
      {
        name: t('status'),
        selector: 'status',
        cell: row => <div className={"text-"+row.status.toLowerCase()}>{t(row.status)}</div>,
        maxWidth:"100px"
      },
      {
        name: t('initialization_date'),
        selector: 'initialization_date',
        cell: row => <div>{row.initialization_date != null ? getDateToDisplay(row.initialization_date).fullDate : "---"}</div>,
        width:"145px",
        hide:"md"
      }
    ];

    return (
      <>

        <div className={this.state.loading ? 'delete2' : 'd-flex justify-content-center'}>
          <div className={this.state.loadingNavigation ? 'myLoader' : 'delete2'}>
            Loading ...
          </div>
        </div>

        <Row>
          <Col>
            {
              this.state.loading ? (
                <Row className="justify-content-center my-5">
                  <PuffLoader
                    sizeUnit={"px"}
                    size={50}
                    color={loaderColor}
                    loading={this.state.loading }
                  />
                </Row>
              ) : (
                <>
                  {
                    payments != null &&
                    <>
                      {
                        payments.length === 0 ? (
                          <div className="d-flex flex-column justify-content-center align-items-center my-5">
                            <h6 className="text-dark font-weight-500">{t('no_payment_to_date')}</h6>
                          </div>
                        ) : (
                          <Row className="justify-content-center">
                            <Col xs="11">

                              <div className="d-flex justify-content-end mb-3">
                                <Button color="primary" size="sm" onClick={this.refresh}>
                                  <i className="fa fa-refresh fa-lg" />
                                </Button>
                              </div>

                              <ManagePage
                                getDatas={this.getPayments}
                                total={this.state.totalResult}
                                page={this.state.page}
                                getPage={this.getPage}
                                size={50}
                              />

                              <DataTable
                                className="my-3"
                                noHeader={true}
                                columns={columns}
                                data={payments}
                                striped
                                responsive
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={<PaymentPresentation />}
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50]}
                              />

                            </Col>
                          </Row>
                        )
                      }
                    </>
                  }
                </>
              )
            }
          </Col>
        </Row>

      </>
    );

  }

}

export default withTranslation()(withRouter(Payments));
