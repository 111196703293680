import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Row, Col, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import {loaderColor, notification} from "../../../MyFunctions";
import {PuffLoader} from "react-spinners";
import FilesSelectorByLedger from "./FilesSelectorByLedger";
import axios from "axios";
import fileDownload from "js-file-download";
import jszip from "jszip";
import {store} from "../../../Security";
import "../../../index.css";

class Express extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingCalculation:false,
      platform:null,
      year:new Date().getFullYear(),
      file:null,
      files:[],
      data:[],
      option:"1",
      addresses:[],
      errorMessage:null,
      source:axios.CancelToken.source()
    };

    this.loadingCalculation = this.loadingCalculation.bind(this);
    this.getPlatform = this.getPlatform.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getFile = this.getFile.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getData = this.getData.bind(this);
    this.getEtherscanOption = this.getEtherscanOption.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.isDataValid = this.isDataValid.bind(this);
    this.containingFile = this.containingFile.bind(this);
    this.eraseErrorMessage = this.eraseErrorMessage.bind(this);
    this.launchCalculation = this.launchCalculation.bind(this);
  }

  loadingCalculation(){
    this.setState((state)=>({
      loadingCalculation:!state.loadingCalculation
    }))
  }

  getPlatform(platform){
    this.setState({
      platform:platform,
      file:null,
      files:[],
      data:[],
      errorMessage:null,
    })
  }

  getYear(year){
    this.setState({
      year:year
    })
  }

  getFile(file){
    this.setState({
      file:file
    })
  }

  getFiles(files){
    this.setState({
      files:files
    })
  }

  getData(data){
    this.setState({
      data:data
    })
  }

  getEtherscanOption(option){
    this.setState({
      option:option
    })
  }

  getAddresses(addresses){
    this.setState({
      addresses:addresses
    })
  }

  eraseErrorMessage(){
    this.setState({
      errorMessage:null
    })
  }

  isDataValid(){
    let response = true;
    let data = this.state.data;
    for(let element of data){
      if(!element.isValid){
        response = false;
        break;
      }
    }
    return response;
  }

  containingFile(name){
    let response = false;
    let files = this.state.files;
    for(let file of Array.from(files)){
      if(file.name.includes(name)){
        response = true;
        break;
      }
    }
    return response;
  }

  async launchCalculation(){

    const {t} = this.props;
    let userCredentials = store.get('userCredentials');
    let user = userCredentials.user;

    if(user.features_allowed != null && !user.features_allowed){
      notification("warning",t('error_message_966'));
    }else{

      this.eraseErrorMessage();

      let fileToSend = "";
      let file = this.state.file;
      let files = this.state.files;
      let data = this.state.data;
      let addresses = this.state.addresses;
      let platform = this.state.platform;
      let year = this.state.year;
      let option = this.state.option;
      let formData = new FormData();

      if(platform.type === "csv"){

        fileToSend = file;
        formData.append('campaign',fileToSend,fileToSend.name);

      }else{

        let zip = new jszip();
        //--------------------------------------

        if(platform.value === "etherscan"){

          if(option === "1"){
            for(let element of data){
              let folder = zip.folder("data_" + element.id);
              for(let file of element.content){
                folder.file(file.name,file);
              }
            }
            await zip.generateAsync({type: "blob"}).then(content => {
              fileToSend = content;
            });
            formData.append('campaign',fileToSend, "data.zip");
          }else{
            formData = {addresses:addresses}
          }

        }

        //--------------------------------------

        if(platform.value === "zebitex"){
          for(let file of files){
            zip.file(file.name,file);
          }
          await zip.generateAsync({type: "blob"}).then(content => {
            fileToSend = content;
          });
          formData.append('campaign',fileToSend, "data.zip");
        }

        //--------------------------------------

      }

      this.loadingCalculation();

      //--------------------------------------------------------------------
      let headers = {};
      headers['x-access-token'] = store.get('userCredentials').token;
      headers['Content-Type'] = (platform.value === "etherscan" && option === "2") ? 'application/json' : 'multipart/form-data;';
      let config = {
        url: process.env.REACT_APP_URL_ACC + "campaigns/express-calculus",
        method:"post",
        headers:headers,
        cancelToken:this.state.source.token
      };
      config.responseType = "blob";
      config.params = { ledger:platform.value , year:year };
      config.data = formData;
      //--------------------------------------------------------------------
      return axios.request(config)
        .then(response =>{
          this.loadingCalculation();
          let fileName = "result_"+platform.value+".zip";
          fileDownload(response.data,fileName);
        }).catch(err =>{
          this.loadingCalculation();
          if(axios.isCancel(err)) {
          }else{
            let blob = err.response.data;
            blob.text().then(text => {
              let content = JSON.parse(text);
              this.setState({
                errorMessage:content.error_code != null ? t('error_message_'+content.error_code) : content.error_message
              })
            });
            blob.text();
          }
        });
      //--------------------------------------------------------------------

    }

  }

  componentDidMount(){}

  componentWillUnmount(){
    this.state.source.cancel();
  }

  render() {

    const {t} = this.props;

    return (
      <>

        <Row>
          <Col>
            <Row className="mb-3">
              <Col>
                <h6 className="text-default font-weight-bold">{t('calculate_stock_with_fifo_method')}</h6>
              </Col>
            </Row>
            <Row>
              <Col>

                <FilesSelectorByLedger
                  getPlatform={this.getPlatform}
                  getYear={this.getYear}
                  getFile={this.getFile}
                  getFiles={this.getFiles}
                  getData={this.getData}
                  getEtherscanOption={this.getEtherscanOption}
                  getAddresses={this.getAddresses}
                />

                {
                  (
                    (this.state.file != null && this.state.platform != null && this.state.platform.type === "csv") ||
                    (this.state.files.length === 2 && this.state.platform != null && this.state.platform.value === "zebitex" && this.containingFile("trades") && this.containingFile("wd")) ||
                    (this.state.data.length !== 0 && this.state.platform != null && this.state.platform.value === "etherscan" && this.isDataValid() && this.state.option === "1") ||
                    (this.state.addresses.length !== 0 && this.state.platform != null && this.state.platform.value === "etherscan" && this.state.option === "2")
                  ) &&
                  <div className="d-flex mt-3">
                    {
                      this.state.loadingCalculation ? (
                        <div className="mb-4">
                          <PuffLoader
                            sizeUnit={"px"}
                            size={30}
                            color={loaderColor}
                            loading={this.state.loadingCalculation}
                          />
                        </div>
                      ) : (
                        <Button color="kamixColorGreen" size="sm" onClick={this.launchCalculation}>
                          {t('launch_calculation')}
                        </Button>
                      )
                    }
                  </div>
                }

                {
                  this.state.errorMessage &&
                  <Row className="mt-4">
                    <Col>
                      <div className="myWarningAlert">
                        <Row>
                          <Col>
                            {this.state.errorMessage}
                          </Col>
                          <div className="d-flex justify-content-center mx-3 pointer">
                            <i className="fa fa-close" onClick={this.eraseErrorMessage}/>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                }

              </Col>
            </Row>
          </Col>
        </Row>

      </>
    );

  }

}

export default withTranslation()(withRouter(Express));
