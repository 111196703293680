import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import {Row, Col, Container, Card, CardBody, FormText, Button, Progress, InputGroup, InputGroupAddon, InputGroupText, Input} from "reactstrap";
import EmailValidator from "email-validator";
import axios from "axios";
import {ScaleLoader} from "react-spinners";
import RemoteCall, {notification, loaderColor} from "../../../MyFunctions"
import {withTranslation} from "react-i18next";
import {store} from "../../../Security";
import "../../../index.css"

class Step1 extends Component{

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      email:"",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.stepOne = this.stepOne.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  onChangeEmail(e){
    this.setState({
      email:e.target.value
    })
  }

  async stepOne(){

    const { t } = this.props;
    let email = this.state.email;

    if(email === ""){
      notification("warning",t('fill_fields_form_label'));
    }else if(!EmailValidator.validate(email)){
      notification("warning",t('invalid_email'));
    }else{

      let formData = {};
      formData.email = email;

      let cancelToken = this.state.source.token;

      this.loading();

      let response = await RemoteCall(
        false,
        "users/register/step1",
        "post",
        null,
        formData,
        cancelToken,
        this.props,
        'acc'
      );

      this.loading();

      if(response != null){
        let error = response.error;
        if(error != null && !error){
          //-------------------------------------------------------------------------------------------
          let data={};
          data.user = response.user;
          data.otp = response.otp;
          data.otp_type = response.otp_type;
          data.otp_reference = response.otp_reference;
          data.skip_email_verified = false;
          await store.set('registerInformation',data);
          //-------------------------------------------------------------------------------------------
          if((response.otp_type != null && response.otp_type.toLowerCase() === "email") && (response.otp != null && response.otp)){
            this.props.history.push('/register/step2');
          }else{
            this.props.history.push('/register/step3');
          }
          //-------------------------------------------------------------------------------------------
        }
      }

    }

  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render(){

    const { t } = this.props;

    return(
      <>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="7">

              <Card className="cardLogin">
                <CardBody>

                  <Row className="justify-content-center mb-2">
                    <Col xs="10" md="8">
                      <h5 className="font-weight-500 text-center mb-1">{t('step_label')} 1 / 4</h5>
                      <Progress animated color="kamixColorGreen" value="25" />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs="11" md="10">

                      <div className="mb-4">
                        <FormText className="mb-1">{t('email')}</FormText>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="fa fa-envelope" /></InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="text-dark font-weight-500"
                            type="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            bsSize="sm"
                            required
                          />
                        </InputGroup>
                      </div>

                      <div className="d-flex justify-content-center" style={{marginTop:"35px"}}>
                        {
                          this.state.loading ? (
                            <ScaleLoader
                              sizeUnit={"px"}
                              height={25}
                              width={4}
                              radius={2}
                              margin={2}
                              color={loaderColor}
                              loading={this.state.loading}
                            />
                          ) : (
                            <Row>
                              <Link to="/login">
                                <Button size="sm" color="dark" className="btn-outline-dark mr-2">{t('cancel')}</Button>
                              </Link>
                              <Button color="kamixColorGreen" size="sm" onClick={this.stepOne}>{t('continue')}</Button>
                            </Row>
                          )
                        }
                      </div>

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </>
    );

  }

}

export default withTranslation()(withRouter(Step1));