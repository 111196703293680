import React, { Component } from 'react';
import {
  Row, Col, Button, Card, CardBody, Form,
  FormText, Modal, InputGroup, InputGroupAddon, InputGroupText, Input
} from "reactstrap";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {withTranslation} from "react-i18next";
import RemoteCall, {loaderColor, notification} from "../../../../MyFunctions";
import Select from "react-select";

class UserActions extends Component{

  constructor(props){
    super(props);

    this.state = {
      toggleEdit:false,
      toggleDangerDeleteOrRestore:false,
      loading:false,
      loadingEdit:false,
      password:null,
      hidden:true,
      features_allowed_choice:null,
      switch:"main",
      source:axios.CancelToken.source()
    };

    this.loading = this.loading.bind(this);
    this.loadingEdit = this.loadingEdit.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDangerDeleteOrRestore = this.toggleDangerDeleteOrRestore.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.switch = this.switch.bind(this);
    this.onChangeFeaturesAllowedChoice = this.onChangeFeaturesAllowedChoice.bind(this);
    this.deleteOrRestore = this.deleteOrRestore.bind(this);
    this.editUserPassword = this.editUserPassword.bind(this);
    this.editUserFeatures = this.editUserFeatures.bind(this);
  }

  loading(){
    this.setState((state)=>({
      loading:!state.loading
    }))
  }

  loadingEdit(){
    this.setState((state)=>({
      loadingEdit:!state.loadingEdit
    }))
  }

  toggleEdit(){
    const {t} = this.props;
    let toggleEdit = this.state.toggleEdit;
    if(toggleEdit){
      this.setState({
        toggleEdit: !toggleEdit
      })
    }else{
      let row = this.props.row;
      let features_allowed_choice = { value: false, label: t('no') };
      if(row.features_allowed != null && row.features_allowed) features_allowed_choice = { value: true, label: t('yes') };
      this.setState({
        toggleEdit: !toggleEdit,
        switch:"main",
        features_allowed_choice:features_allowed_choice
      })
    }
  }

  toggleDangerDeleteOrRestore(){
    this.setState({
      toggleDangerDeleteOrRestore: !this.state.toggleDangerDeleteOrRestore,
    })
  }

  onChangePassword(e){
    this.setState({
      password:e.target.value
    })
  }

  hidePassword(){
    this.setState({
      hidden: !this.state.hidden
    })
  }

  switch(decision){
    this.setState({
      switch:decision,
      password:"",
    })
  }

  onChangeFeaturesAllowedChoice(e){
    this.setState({
      features_allowed_choice:e
    })
  }


  async deleteOrRestore(){

    const {t} = this.props;
    let row = this.props.row;
    let cancelToken = this.state.source.token;

    this.loading();

    let response = await RemoteCall(
      true,
      "admins/delete-restore/"+row.user_id,
      "patch",
      null,
      {action:row.deleted_account ? "restore" : "delete"},
      cancelToken,
      this.props,
      'acc'
    );

    this.loading();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("info",t(row.deleted_account ? 'user_restored' : 'user_deleted'));
        this.toggleDangerDeleteOrRestore();
        this.props.getUser(response.user,row.id);
      }
    }

  }

  async editUserPassword(){

    const {t} = this.props;
    let row = this.props.row;
    let password = this.state.password;
    let cancelToken = this.state.source.token;

    this.loadingEdit();

    let response = await RemoteCall(
      true,
      "admins/change-password/"+row.user_id,
      "patch",
      null,
      {password:password},
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingEdit();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("info",t('successful_modification'));
        this.toggleEdit();
        this.props.getUser(response.user,row.id);
      }
    }

  }

  async editUserFeatures(){

    const {t} = this.props;
    let row = this.props.row;
    let features_allowed_choice = this.state.features_allowed_choice;
    let cancelToken = this.state.source.token;

    let formData = {};
    formData['features_allowed'] = features_allowed_choice.value;

    this.loadingEdit();

    let response = await RemoteCall(
      true,
      "admins/change-features-allowed/"+row.user_id,
      "patch",
      null,
      formData,
      cancelToken,
      this.props,
      'acc'
    );

    this.loadingEdit();

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        notification("info",t('successful_modification'));
        this.toggleEdit();
        this.props.getUser(response.user,row.id);
      }
    }

  }

  componentWillUnmount() {
    this.state.source.cancel();
  }

  render() {

    const { t } = this.props;
    let row = this.props.row;

    const featuresAllowedChoices = [
      { value: false, label: t('no') },
      { value: true, label: t('yes') },
    ];

    return (
      <>

        <div className="ml-auto mr-2">
          {
            !row.deleted_account &&
            <Button color="primary" size="sm" onClick={this.toggleEdit} title={t('edit')} className="mr-2">
              <i className="fa fa-pencil" />
            </Button>
          }
          <Button
            color={row.deleted_account ? "kamixColorGreen" : "danger"} size="sm" onClick={this.toggleDangerDeleteOrRestore}
            title={row.deleted_account ? t('restore') : t('delete')}
          >
            <i className={row.deleted_account ? "fa fa-refresh" : "fa fa-trash"} />
          </Button>
        </div>

        <Modal size="md" isOpen={this.state.toggleEdit} toggle={this.toggleEdit}>
          <Card className="bg-secondary">
            <CardBody>

              <div className="text-kamixColorGreen text-center font-weight-bold mb-3">
                <h6 className="text-kamixColorGreen font-weight-bold mb-2">{t('edition')}</h6>
              </div>

              {
                this.state.switch === "main" &&
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Button size="sm" className="btn-outline-default mb-2" onClick={(e)=>this.switch('password',e)}>
                    {t('change_password_2')}
                  </Button>
                  <Button size="sm" className="btn-outline-default mb-4" onClick={(e)=>this.switch('features',e)}>
                    {t('change_authorization_features')}
                  </Button>
                  <Button size="sm" color="dark" onClick={this.toggleEdit}>
                    <i className="fa fa-close fa-lg" />
                  </Button>
                </div>
              }

              {
                this.state.switch === "password" &&
                <>
                  <div>
                    <Form>
                      <Row className="justify-content-center mb-4">
                        <Col xs="12" sm="10">
                          <FormText className="mb-1">{t('password')}</FormText>
                          <div className="d-flex">
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type={this.state.hidden ? "password" : "text"}
                                className="text-dark font-weight-500"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                              />
                            </InputGroup>
                            <Button color="white" size="sm" onClick={this.hidePassword} className="ml-1">
                              {this.state.hidden ? (<i className="fa fa-eye-slash fa-lg"/>) : (<i className="fa fa-eye fa-lg"/>)}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="d-flex justify-content-center">
                    {
                      this.state.loadingEdit ? (
                        <PuffLoader
                          sizeUnit={"px"}
                          size={35}
                          color={loaderColor}
                          loading={this.state.loadingEdit}
                        />
                      ) : (
                        <Row>
                          <Button size="sm" className="btn-outline-dark mr-2" onClick={(e)=>this.switch("main",e)}>{t('back')}</Button>
                          <Button size="sm" color="kamixColorGreen" onClick={this.editUserPassword}>{t('edit')}</Button>
                        </Row>
                      )
                    }
                  </div>
                </>
              }

              {
                this.state.switch === "features" &&
                <>
                  <div>
                    <Form>
                      <Row className="justify-content-center mb-4">
                        <Col xs="12" sm="10">
                          <FormText color="muted">{t('features_allowed')}</FormText>
                          <Select
                            options={featuresAllowedChoices}
                            value={this.state.features_allowed_choice}
                            onChange={this.onChangeFeaturesAllowedChoice}
                            className="font-weight-500 small"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="d-flex justify-content-center">
                    {
                      this.state.loadingEdit ? (
                        <PuffLoader
                          sizeUnit={"px"}
                          size={35}
                          color={loaderColor}
                          loading={this.state.loadingEdit}
                        />
                      ) : (
                        <Row>
                          <Button size="sm" className="btn-outline-dark mr-2" onClick={(e)=>this.switch("main",e)}>{t('back')}</Button>
                          <Button size="sm" color="kamixColorGreen" onClick={this.editUserFeatures}>{t('edit')}</Button>
                        </Row>
                      )
                    }
                  </div>
                </>
              }

            </CardBody>
          </Card>
        </Modal>

        <Modal isOpen={this.state.toggleDangerDeleteOrRestore} toggle={this.toggleDangerDeleteOrRestore}>
          <Card className="bg-secondary">
            <CardBody>

              <h6 className="text-center mb-2">{t(row.deleted_account ? 'warning_restore_user' : 'warning_delete_user')}</h6>
              <div className="d-flex justify-content-center">
                {
                  this.state.loading ? (
                    <PuffLoader
                      sizeUnit={"px"}
                      size={35}
                      color={row.deleted_account ? loaderColor : '#f5365c'}
                      loading={this.state.loading}
                    />
                  ) : (
                    <Row>
                      <Button color="default" size="sm" onClick={this.toggleDangerDeleteOrRestore}>{t('cancel')}</Button>
                      <Button size="sm" className={row.deleted_account ? "btn-outline-kamixColorGreen" : "btn-outline-danger"} onClick={this.deleteOrRestore}>
                        {t(row.deleted_account ? 'restore' : 'delete')}
                      </Button>
                    </Row>
                  )
                }
              </div>

            </CardBody>
          </Card>
        </Modal>

      </>
    );

  }

}

export default withTranslation()(withRouter(UserActions));
